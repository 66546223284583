<style>

.table-responsive {
  overflow-y: hidden !important;
}
</style>
<script>
import {computed} from 'vue';

import store from '../../store';
import {calculateAge, exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";
import Swal from 'sweetalert2';

export default {
  components: {TableComponent},
  data() {
    return {
      changePage:'particular',
      moment:moment,
      particular: [],
      company: [],
      invited: [],
      particular_search:'',
      searchBy:'NAME',
      length_particular:0,
      company_search_by:'NAME',
      company_tiping:'',
      invited_search_by:'NUM',
      invited_tiping:'',
      length_company:0,
      stParticular:[],
      stCompany:[],
      elt:"",
      state_choice:'p',
    };
  },
  mounted() {
    window.onscroll = () => {
      let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
      if (scrollBottom < 1 || scrollBottom <= 0 ) {
        if ((this.changePage==="particular" &&  store.state.particular.next_page !==null)) {
          this.getUsers(2)
        }else  if ((this.changePage==="company" &&  store.state.company.next_page !==null)) {
          this.getUsers(1)
        }else  if ((this.changePage==="invited" &&  store.state.user_invited.next_page !==null)) {
          this.getUsers(3)
        }
      }
    }
  },
  beforeMount() {
    this.getUsers(2);
    setTimeout(()=>{
      this.getUsers(1);
    },200)
  },
  computed:{
    particulierFilter(){
      if (this.particular_search.trim().length>0){
        if (this.searchBy=="NAME"){
          return (this.particular.data.filter((item)=>item.last_name.toLowerCase().includes(this.particular_search.toLowerCase())));
        }else if(this.searchBy=="LAST_NAME"){
          return (this.calls.data.filter((item)=>item.first_name.toLowerCase().includes(this.particular_search.toLowerCase())));
        }
      }
      return this.particular.data;
    },
    companyFilter(){
      console.log(this.company_tiping);
      if (this.company_tiping.trim().length>0){
        if (this.company_search_by=="NAME"){
          return (this.company.data.filter((item)=>item.enterprise_name.toLowerCase().includes(this.company_tiping.toLowerCase())));
        }else if(this.company_search_by=="ACR"){
          return (this.company.data.filter((item)=>item.acronym.toLowerCase().includes(this.company_tiping.toLowerCase())));
        }
      }
      return this.company.data;
    },
  },
  methods: {

    calculateAge,

    formateNumber,

    dateFormat(date){
      return moment(date);
    },

    getUsers(id,action=false) {
      let self = this;
      let page = 0;
      if (!action) {
        if (id ===2 ) {
          page =store.state.particular.next_page;
        } else if (id === 1) {
          page = store.state.company.next_page;
        }
      }else{
        if (id ===2 ) {
          page = store.state.particular.current_page;
        } else if (id === 1) {
          page = store.state.company.current_page;
        }
      }
      store.dispatch("getDoubleAcounteUser", [id,page]);
      if(id===2){
        self.particular = computed(() => store.state.particular);
      }else if(id===1){
        self.company = computed(() => store.state.company);
      }
    },

    putAllToUntreaty(){
      store.dispatch("putAllToUntreaty")
          .then((response) => {
            this.getUsers(2,true);
            this.getUsers(1,true);
            Swal.fire({
              icon: "success",
              title:  this.$t('alert.success_title'),
              text: this.$t('alert.success_text'),
              customClass: {
                confirmButton: "btn btn-success"
              }
            });
            return response
          })
          .catch((error) => {
            Swal.fire({
              title:  this.$t('alert.error_title'),
              text: this.$t('alert.error_text'),
              icon: "error",
              customClass: {confirmButton: "btn btn-success"}
            });
            return error
          });
    },

    switchBetween(id){
      if (id===1){
        this.changePage = 'company';
        this.getUsers(id);
      }else if (id===2){
        this.changePage = 'particular';
        this.getUsers(id);
      }else if(id===3){
        this.changePage ="invited";
        this.getUsers(id);
      }
    },

    checkvalue(text){
      if (text ==="" || text===null || text==="null" || text ===undefined ){
        return '';
      }else {
        return text;
      }
    },

    editUserVipState(id,action){
      store.dispatch("updateUserState", [parseInt(id),action])
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Modification",
            text: "Modification effectué avec succès!",
            customClass: {
              confirmButton: "btn btn-success"
            }

          });

          if (this.changePage==='particular'){

            this.getUsers(2,true);

          }else{
            this.getUsers(1,true);
          }

          return response;
        })
        .catch((error) => {
          Swal.fire({
            title: "Erreur",
            text: "Une erreur est survenue",
            icon: "error",
            customClass: {confirmButton: "btn btn-success"}
          });
          return error;
        });
    },

    confrimation(id,action){
      Swal.fire({
        title: 'Confirmation',
        text: `Voullez-vous vraiment effectué cette opération cet utilisateur ?`,
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText: `Oui, éffectuer`,
        customClass: { confirmButton: "btn btn-warning", cancelButton: "btn btn-outline-danger ms-1" },
        buttonsStyling: !1,
      }).then((result) => {
        if (result.value) {
          this.editUserVipState(id,action);
        }
      })
    },

    exportexls_auth(type,name,fn,dl){
      if (name==="company_d") {
        this.elt = this.$refs.company_d;
      }else if (name==="particular_d"){
        this.elt = this.$refs.particular_d;
      }else if (name==="guess_d"){
        this.elt = this.$refs.guess_d;
      }
      return exportexls_auth(type,name,fn,dl,this.elt)
    },

  },

}

</script>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{$t("dashboard.users.all_users")}}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">Activés
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" :class="{active:this.changePage==='particular'}" href="#" v-on:click="switchBetween(2)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg><span class="align-middle m-lg-1">{{$t("dashboard.particular")}}</span></a>
                <a class="dropdown-item" :class="{active:this.changePage==='company'}" href="#" v-on:click="switchBetween(1)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  <span class="align-middle  m-lg-1">{{$t("dashboard.company")}}</span></a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-lg-6 col-sm-6 col-12" v-if="this.changePage!=='invited'">
              <div class="card">
                <div class="card-header  align-items-start pb-0" style="margin-bottom: 10%">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(particular.totals)}}</h2>
                  <p class="card-text">Particulars</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Greetings Card ends -->




            <!-- Subscribers Chart Card starts -->
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-info p-50 m-0">
                    <div class="avatar-content">

                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(company.totals)}}</h2>
                  <p class="card-text">Companies</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Subscribers Chart Card ends -->
          </div>
        </section>
        <!-- Multilingual -->


        <!-- Justified Pills Start -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div role="tabpanel" v-if="this.changePage==='particular'" class="tab-pane active" id="particulier" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("dashboard.particular") }}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','particular_d')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="searchBy" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">{{$t("users.table_header.surname")}}</option>
                        <option value="LAST_NAME">{{$t("users.table_header.first_name")}}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="particular_search" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="particular_d">
                          <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th scope="col">PHOTO</th>
                            <th scope="col">TERMINAL</th>
                            <th scope="col">AGE</th>
                            <th scope="col">GENDER</th>
                            <th scope="col">{{$t("users.table_header.surname")}}</th>
                            <th scope="col">{{$t("users.table_header.first_name")}}</th>
                            <th scope="col">PSEUDO</th>
                            <th scope="col">{{$t("users.table_header.active")}}</th>
                            <th scope="col">{{$t("users.table_header.blocked")}}</th>
                          </tr>
                          </thead>
                          <tbody v-if="particular.totals!==0">
                          <tr v-for="pa in particulierFilter" :key="pa.id">
                            <td scope="row" >{{pa.id}}</td>
                            <td>  {{dateFormat(pa.created_at).format('LLLL')}} </td>
                            <td> {{dateFormat(pa.updated_at).format('LLLL')}} </td>
                            <td>
                              <img :src="pa.profile_image_link" width="32" class="rounded-circle" height="32">
                            </td>
                            <td> {{this.checkvalue(pa.terminal)}}</td>
                            <td> {{calculateAge((pa.birth_date)) +" "+ $t("home.year_old")}}</td>
                            <td> {{this.checkvalue(pa.gender)}}</td>
                            <td>{{this.checkvalue(pa.last_name)}}</td>
                            <td>{{this.checkvalue(pa.first_name)}}</td>
                            <td>{{this.checkvalue(pa.pseudo)}}</td>
                            <td>
                              <span v-if="!pa.activate" class="badge bg-danger" >{{$t("users.table_content.unverified")}}</span>
                              <span v-if="pa.activate" class="badge bg-success" >{{$t("users.table_content.verified")}}</span>
                            </td>
                            <td>
                              <span v-if="!pa.blocked" class="badge bg-success" >{{$t("users.table_content.no")}}</span>
                              <span v-if="pa.blocked" class="badge bg-danger" >{{$t("users.table_content.yes")}}</span>
                            </td>


                          </tr>

                          </tbody >
                          <tbody v-else>
                          <tr >
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="particular.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
                <div role="tabpanel" v-if="this.changePage==='company'" class="tab-pane active" id="company" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("dashboard.company") }}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','company_d')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="company_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">COMAPNY NAME</option>
                        <option value="ACR">ACRONYM</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="company_tiping" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="company_d">
                          <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th scope="col">PHOTO</th>
                            <th scope="col">TERMINAL</th>
                            <th scope="col">COMPANY NAME</th>
                            <th scope="col">ACRONYM</th>
                            <th scope="col">{{$t("users.table_header.active")}}</th>
                            <th scope="col">{{$t("users.table_header.blocked")}}</th>

                          </tr>
                          </thead>
                          <tbody v-if="company.totals!==0">
                          <tr v-for="co in companyFilter" :key="co.id">
                            <td scope="row" >{{co.id}}</td>
                            <td>  {{dateFormat(co.created_at).format('LLLL')}} </td>
                            <td> {{dateFormat(co.updated_at).format('LLLL')}} </td>
                            <td>
                              <img :src="co.profile_image_link" width="32" class="rounded-circle" height="32">
                            </td>
                            <td> {{this.checkvalue(co.terminal)}}</td>
                            <td>{{this.checkvalue(co.enterprise_name)}}</td>
                            <td>{{this.checkvalue(co.acronym)}}</td>
                            <td>
                              <span v-if="!co.activate" class="badge bg-danger" >{{$t("users.table_content.unverified")}}</span>
                              <span v-if="co.activate" class="badge bg-success" >{{$t("users.table_content.verified")}}</span>
                            </td>
                            <td>
                              <span v-if="!co.blocked" class="badge bg-success" >{{$t("users.table_content.no")}}</span>
                              <span v-if="co.blocked" class="badge bg-danger" >{{$t("users.table_content.yes")}}</span>
                            </td>


                          </tr>

                          </tbody >
                          <tbody v-else>
                          <tr >
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="company.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

