import en from './en.json';
import fr from './fr.json';

var userLang = navigator.language || navigator.userLanguage;

if (localStorage.getItem('lidark')===undefined || localStorage.getItem('lidark')===null || localStorage.getItem('lidark')==="null"){
    localStorage.setItem('lidark',"light");
}

if (localStorage.getItem('langue')===undefined || localStorage.getItem('langue')===null || localStorage.getItem('langue') === "null") {
    if(userLang=="fr-FR" || userLang=="fr"){
        localStorage.setItem('langue','fr')
    }else{
        localStorage.setItem('langue','en')
    }
}



export const defaultLocale = localStorage.getItem('langue');

export const languages = {
    en, fr
}
