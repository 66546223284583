<template>
    <section id="row-grouping-datatable">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header border-bottom p-1">
                        <div class="head-label">
                            <slot name="left"></slot>
                        </div>
                      <div class="center-layout m-lg-1">
                        <slot name="numberLinite"></slot>
                      </div>
                        <div class="dt-action-buttons text-end">
                            <div class="dt-buttons d-inline-flex">
                                <slot name="export"></slot>
                            </div>
                        </div>
                    </div>
                    <div class="card-header border-bottom p-1">
                        <div class="row">
                            <div class="d-flex">
                                <div class="">
                                    <label class="p-1">{{$t("global_texte.search_by")}} </label>
                                </div>

                                <div>
                                    <slot name="show"></slot>
                                </div>
                            </div>
                        </div>
                      <div class="center-layout  m-lg-1">
                        <slot name="action_option"></slot>
                      </div>
                      <div class="center-layout  m-lg-1">
                        <slot name="datesInput"></slot>
                      </div>
                        <div class="dt-action-buttons text-end">
                            <slot name="search"></slot>
                        </div>
                        <div class="table-responsive col-12">
                            <slot name="table">
                                
                            </slot>


                        </div>
                    </div>
                   
                    <slot name="pagination"></slot>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    name: "TableComponent"
}
</script>

<style scoped>

</style>
