<style>

.table-responsive {
  overflow-y: hidden !important;
}
.title_color{
  color: #BC4030;
  margin: revert;
}
div:where(.swal2-container) .swal2-select{
  border-radius: revert !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  background-color:#BC4030 !important; ;
}
</style>
<script>
import {
  computed
} from 'vue';

import store from '../../store';
import {exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";
import Swal from 'sweetalert2';


export default {
  components: {TableComponent},
  data() {
    return {
      globale:"bank",
      changePage:'pending',
      pending: [],
      moment:moment,
      pending_search_by:'MONTANT',
      pending_search_tiping:'',
      traitement: [],
      traitement_search_by:'MONTANT',
      traitement_search_tiping:'',
      sent:[],
      sent_search_by:'MONTANT',
      sent_search_tiping:'',
      failed_data:[],
      failed_search_by:'MONTANT',
      failed_search_tiping:'',
      canceled:[],
      canceled_search_by:'MONTANT',
      canceled_search_tiping:'',
      legal_reason_id:"",
      formeData:{
        status:'',
      },
      edit_id:'',
      elt:"",
      raisons:[],
      amountByStatus:[],
      state_choice:'p',
    };
  },
  mounted() {

    window.onscroll = () => {
      // let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
      let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
      if (scrollBottom < 1 || scrollBottom <= 0 ) {
        if ((this.changePage==="pending" && store.state.pending.next_page !== null)) {
          this.getPayouts(this.globale,'pending');
        }else  if ((this.changePage==="canceled" && store.state.canceled.next_page !==null)) {
          this.getPayouts(this.globale,'canceled');
        }else  if ((this.changePage==="failed" && store.state.failed.next_page !==null)) {
          this.getPayouts(this.globale,'failed');
        }else  if ((this.changePage==="sent" && store.state.sent.next_page !==null)) {
          this.getPayouts(this.globale,'sent');
        }else  if ((this.changePage==="treatment" && store.state.traitement.next_page !==null)) {
          this.getPayouts(this.globale,'treatment');
        }

      }
    }

  },
  beforeMount() {
    this.getPayouts(this.globale,'pending');
    this.getPayouts(this.globale,"sent");
    this.getPayouts(this.globale,'failed');
    this.getPayouts(this.globale,'treatment');
    this.getPayouts(this.globale,'canceled');

    this.getTransferOrderTotalAmountByStatus(this.globale);
    this.getMotifs();

  },
  computed:{
    pendingFilter(){
      if (this.pending_search_tiping.trim().length>0){
        if (this.pending_search_by=="MONTANT"){
          return this.pending.data.filter((item)=>item.amount ==(this.pending_search_tiping));
        }else if(this.pending_search_by=="ETOILS"){
          return this.pending.data.filter((item)=>item.Payouts_number == this.pending_search_tiping);
        }else if(this.pending_search_by=="POINT"){
          return this.pending.data.filter((item)=>item.point == (this.pending_search_tiping));
        }
      }
      return this.pending.data;
    },
    traitementFilter(){
      if (this.traitement_search_tiping.trim().length>0){
        if (this.traitement_search_by=="MONTANT"){
          return this.traitement.data.filter((item)=>item.amount ==(this.traitement_search_tiping));
        }else if(this.traitement_search_by=="ETOILS"){
          return this.traitement.data.filter((item)=>item.Payouts_number == this.traitement_search_tiping);
        }else if(this.traitement_search_by=="POINT"){
          return this.traitement.data.filter((item)=>item.point == (this.traitement_search_tiping));
        }
      }
      return this.traitement.data;
    },
    sentFilter(){
      if (this.sent_search_tiping.trim().length>0){
        if (this.sent_search_by=="MONTANT"){
          return this.sent.data.filter((item)=>item.amount ==(this.failed_search_tiping));
        }else if(this.sent_search_by=="NOM"){
          // return this.sent.data.filter((item)=>item.transfer_methods.full_name.toLowerCase().includes(this.failed_search_tiping));
        }else if(this.sent_search_by=="EMAIL"){
          return this.sent.data.filter((item)=>item.user_email.toLowerCase().includes(this.failed_search_tiping));
        }
      }
      return this.sent.data;
    },
    failedFilter(){
      if (this.failed_search_tiping.trim().length>0){
        if (this.failed_search_by=="MONTANT"){
          return this.failed_data.data.filter((item)=>item.amount ==(this.failed_search_tiping));
        }else if(this.failed_search_by=="NOM"){
          // return this.failed_data.data.filter((item)=>item.transfer_methods.full_name.toLowerCase().includes(this.failed_search_tiping));
        }else if(this.failed_search_by=="EMAIL"){
          return this.failed_data.data.filter((item)=>item.user_email.toLowerCase().includes(this.failed_search_tiping));
        }
      }
      return this.failed_data.data;
    },
    canceledFilter(){
      if (this.canceled_search_tiping.trim().length>0){
        if (this.canceled_search_by=="MONTANT"){
          return this.canceled.data.filter((item)=>item.amount ==(this.canceled_search_tiping));
        }else if(this.canceled=="NOM"){
          // return this.canceled.data.filter((item)=>item.transfer_methods.full_name.toLowerCase().includes(this.canceled_search_tiping));
        }else if(this.canceled_search_by=="EMAIL"){
          return this.canceled.data.filter((item)=>item.user_email.toLowerCase().includes(this.canceled_search_tiping));
        }
      }
      return this.canceled.data;
    }
  },
  methods: {
    formateNumber,
    dateFormat(date){
      return moment(date);
    },

    getUsers(id,action=false) {
      let self = this;
      let page = 0;
      if (!action ) {
        if (id ===1 ) {
          page = store.state.particular_co.next_page;
        } else if (id === 2) {
          page = store.state.company_co.next_page;
        }else if (id === 3) {
          page = store.state.invited_co.next_page;
        }
        else if (id === 4) {
          page = store.state.invited_co.next_page;
        }
        else if (id === 5) {
          page = store.state.invited_co.next_page;
        }
      }else{
        if (id ===1 ) {
          page = store.state.particular_co.current_page;
        } else if (id === 2) {
          page = store.state.company_co.current_page;
        }else if(id===3){
          page = store.state.invited_co.current_page;
        }else if(id===4){
          page = store.state.invited_co.current_page;
        }else if(id===5){
          page = store.state.invited_co.current_page;
        }
      }
      store.dispatch("getUsersControllers", [id,page]);
      if(id===2){
        self.particular = computed(() => store.state.particular_co);
      }else if(id===1){
        self.company = computed(() => store.state.company_co);
      }else if(id===3){
        self.invited = computed(() => store.state.invited_co);
      }
    },
    getUserStatistics(account_id){
      store.dispatch("getUserStatistics",account_id);
      if (account_id===1){
        this.stCompany = computed(() => store.state.statistics_co);
      }else if(account_id===2){
        this.stParticular = computed(() => store.state.statistics_pa);
      }

    },

    updateData(id=0){
      if (this.changePage==="pending"){
        if (id!==0){
          store.state.pending.data =  store.state.pending.data.filter(obj => obj.id !== id);
        }
        this.getPayouts(this.globale,'pending',true);

      }else if (this.changePage==="treatment"){
        if (id!==0){
          store.state.traitement.data =  store.state.traitement.data.filter(obj => obj.id !== id);
        }
        this.getPayouts(this.globale,'treatment',true);

      }else if (this.changePage==="sent"){
        if (id!==0){
          store.state.sent.data =  store.state.sent.data.filter(obj => obj.id !== id);
        }
        this.getPayouts(this.globale,'sent',true);

      }else if (this.changePage==="failed"){
        if (id!==0){
          store.state.failed.data =  store.state.failed.data.filter(obj => obj.id !== id);
        }
        this.getPayouts(this.globale,'failed',true);

      }else if (this.changePage==="blacklist"){
        if (id!==0){
          store.state.canceled.data =  store.state.canceled.data.filter(obj => obj.id !== id);
        }
        this.getPayouts(this.globale,'canceled',true);

      }
    },


    switchBetween(id){
      this.getTransferOrderTotalAmountByStatus('bank');

      if (id===1){
        this.changePage = 'pending';
        this.getPayouts(this.globale,'pending');
      }else if (id===2){
        this.changePage = 'treatment';
        this.getPayouts(this.globale,'treatment');

        this.getUsers(id);
      }else if(id===3){
        this.changePage ="sent";
        this.getPayouts(this.globale,'sent');

        this.getUsers(id);
      }else if(id===4){
        this.changePage ="failed";
        this.getPayouts(this.globale,'failed');


        this.getUsers(id);
      }else if(id===5){
        this.changePage ="blacklist";
        this.getPayouts(this.globale,'canceled');

        this.getUsers(id);
      }
    },

    // eslint-disable-next-line no-unused-vars
    getTransferOrderTotalAmountByStatus(mode){
      this.getPayouts(this.globale,'pending');
      this.getPayouts(this.globale,"sent");
      this.getPayouts(this.globale,'failed');
      this.getPayouts(this.globale,'treatment');
      this.getPayouts(this.globale,'canceled');
     /* store.dispatch("getTransferOrderTotalAmountByStatus",mode);
      this.amountByStatus = computed(() => store.state.amountByStatus);*/
    },
    getMotifs(){
      store.dispatch("getMotifsJuridique");
      this.raisons = computed(() => store.state.raisons);
    },
    getPayouts(mode,type,action=false) {
      let page = 0
      if (!action ) {
        if (type ==="pending" ) {
          page = store.state.pending.next_page;
        } else if (type ==="treatment") {
          page = store.state.traitement.next_page;
        }else if (type ==="sent") {
          page = store.state.sent.next_page;
        }else if (type ==="failed") {
          page = store.state.failed.next_page;
        }else if (type ==="canceled") {
          page = store.state.canceled.next_page;
        }
      }else{
        if (type ==="pending" ) {
          page = store.state.pending.current_page;
        } else if (type ==="treatment") {
          page = store.state.traitement.current_page;
        }else if (type ==="sent") {
          page = store.state.sent.current_page;
        }else if (type ==="failed") {
          page = store.state.failed.current_page;
        }else if (type ==="canceled") {
          page = store.state.canceled.current_page;
        }
      }
      store.dispatch("getAllWidrawRequest", [mode,type,page]);
      if (type==="pending") {
        this.pending = computed(() => store.state.pending);
      }else if(type==="treatment"){
        this.traitement = computed(() => store.state.traitement);
      }else if(type==="sent"){
        this.sent = computed(() => store.state.sent);
      }else if(type==="failed"){
        this.failed_data = computed(() => store.state.failed);
      }else if(type==="canceled"){
        this.canceled = computed(() => store.state.canceled);
      }
    },

    checkvalue(text){
      if (text ==="" || text===null || text==="null" || text ===undefined ){
        return '';
      }else {
        return text;
      }
    },

    editPayoutsState(id,formData){

      store.dispatch("updatePaidStatus", [id,formData])
          .then((response) => {
            this.$toast.success(this.$t('alert.success_text'));

           this.formeData.status = '';
           setTimeout(()=>{
             this.updateData(id);
             this.getTransferOrderTotalAmountByStatus(this.globale);
           },500)

            return response

          })
          .catch((error) => {
            this.$toast.error(this.$t('alert.error_text'));

            return error
          });
    },
    confrimation(id,reason=null){
      if (reason!=null){
        this.formeData.legal_reason_id=reason;
      }
      Swal.fire({
        title: this.$t('confirm.title'),
        text: this.$t('confirm.text'),
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText: `Oui`,
        customClass: { confirmButton: "btn btn-warning", cancelButton: "btn btn-outline-danger ms-1" },
        buttonsStyling: !1,
      }).then((result) => {
        if (result.value) {
          this.editPayoutsState(id,this.formeData);
        }
      })
    },
    canceledConfirmation(id_edit){
      this.edit_id = id_edit;
      if (this.formeData.status == "permanently_cancel") {
        let raison=[];
        this.raisons.data.forEach((option) => {
          raison[option.id ] = "("+option.code+")"+option.description;
        });

        Swal.fire({
          title: 'Select the reason ',
          input: 'select',
          inputOptions: raison,
          inputPlaceholder: '',
          showCancelButton: false,
          customClass: {
            title: 'title_color',
          },
          inputValidator: (value) => {
            // Validate the selected option
            if (!value) {
              return 'Please select an reason';
            }
          },

        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(()=>{
              // Handle the selected option
              // Do something with the selectedOption
              this.formeData.legal_reason_id = result.value;

            },300);
            this.confrimation(id_edit,result.value)

          }
        })
      }else{
        this.confrimation(id_edit)
      }
    },

    exportexls_auth(type,name,fn,dl){
      if (name==="treatment") {
        this.elt = this.$refs.treatment;
      }else if (name==="pending"){
        this.elt = this.$refs.pending;
      }else if (name==="sent"){
        this.elt = this.$refs.sent;
      }else if (name==="failed"){
        this.elt = this.$refs.failed;
      }else if (name==="blacklist"){
        this.elt = this.$refs.blacklist;
      }
      return exportexls_auth(type,name,fn,dl,this.elt)
    }

  },

}

</script>

<template>
  <modal name="addMotif" v-show="true">
    <div class="modal fade text-start" id="addMotif" tabindex="-1" aria-labelledby="myModalLabel1" aria-hidden="true">
      <div class="modal-dialog" id="wrapped">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel1">{{ $t("payout.form.title") }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form class="needs-validation" novalidate @submit.prevent="confrimation(this.edit_id,'canceled')">
              <div class="mb-1 col-md-12">
                <label class="form-label">{{ $t("payout.form.option") }}</label>
                <select class="form-control w-100" v-model="this.legal_reason_id">
                  <option label=" "></option>
                  <option v-for="ra in raisons.data" v-bind:key="ra.id" :value="ra.id">{{ra.code}}: {{ra.description}}</option>
                </select>
              </div>
              <div class="col-lg-12">
                <button type="submit" id="btn_soumettre_motif" class="btn btn-primary float-rigth offset-9 waves-effect waves-float waves-light"> {{ $t("raisons.form.btn") }}</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </modal>

  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{$t("dashboard.bkn_op.title")}}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">{{$t("dashboard.bkn_op.sub_title")}}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" :class="{active:this.changePage==='pending'}" href="#" v-on:click="switchBetween(1)">

                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>                  <span class="align-middle m-lg-1">{{ $t("payout.card.pending") }}</span></a>
                <a class="dropdown-item" :class="{active:this.changePage==='treatment'}" href="#" v-on:click="switchBetween(2)">

                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                  <span class="align-middle  m-lg-1">{{ $t("payout.card.treatment") }}</span></a>
                <a class="dropdown-item" :class="{active:this.changePage==='sent'}" href="#" v-on:click="switchBetween(3)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>                  <span class="align-middle  m-lg-1">{{ $t("payout.card.sent") }}</span></a>
                <a class="dropdown-item" :class="{active:this.changePage==='failed'}" href="#" v-on:click="switchBetween(4)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>                  <span class="align-middle  m-lg-1">{{ $t("payout.card.failed") }}</span></a>
                <a class="dropdown-item" :class="{active:this.changePage==='blacklist'}" href="#" v-on:click="switchBetween(5)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>                  <span class="align-middle  m-lg-1">{{ $t("payout.card.blacklist") }}</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">

        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="card">
                <div class="card-header  align-items-start pb-0" style="margin-bottom: 10%">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">

                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{ formateNumber(this.pending.total_pending_amount) }} $</h2>
                  <p class="card-text">{{ $t("payout.card.pending") }}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Greetings Card ends -->




            <!-- Subscribers Chart Card starts -->
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-info p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(traitement.total_treatment_amount)}} $</h2>
                  <p class="card-text">Treatment</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Subscribers Chart Card ends -->

            <!-- Orders Chart Card starts -->
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-warning p-50 m-0">
                    <div class="avatar-content">

                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(canceled.total_canceled_amount)}} $</h2>
                  <p class="card-text">Canceled</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-danger p-50 m-0">
                    <div class="avatar-content">

                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(failed_data.total_failed_amount)}} $</h2>
                  <p class="card-text">Failed</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-success p-50 m-0">
                    <div class="avatar-content">

                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(sent.total_sent_amount)}} $</h2>
                  <p class="card-text">Sent</p>
                </div>
              </div>
            </div>

          </div>
        </section>
        <!-- Multilingual -->



        <!-- Justified Pills Start -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div role="tabpanel" v-if="this.changePage==='pending'" class="tab-pane active" id="pending" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <a href="" class="btn btn-outline-primary sm  waves-effect" style="margin-right: 4px"  aria-expanded="false">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                          </span>
                      </a>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("payout.card.pending") + " ( "+ formateNumber(pending.totals) + " )"}}
                          </span>
                      </button>

                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','pending')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="searchBy" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">{{$t("users.table_header.surname")}}</option>
                        <option value="LAST_NAME">{{$t("users.table_header.first_name")}}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="particular_search" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="pending">
                          <thead>
                            <tr>
                              <th class="coll">ID</th>
                              <th scope="col">{{ $t("report_user.table_crested") }}</th>
                              <th scope="col">{{ $t("report_user.table_updated") }}</th>
                              <th class="coll">Amount</th>
                              <th class="coll">Status</th>
                              <th class="coll">MODE</th>
                              <th class="coll">ACCOUNT</th>
                              <th class="coll">TOTAL AMOUNT</th>
                              <th class="coll">FIXED COST</th>
                              <th class="coll">VP COST</th>
                              <th class="coll">NAME</th>
                              <th scope="col">{{ $t("payout.table.change_status") }}</th>
                            </tr>
                          </thead>
                          <tbody v-if="pending.totals!==0">
                          <tr v-for="go in pendingFilter" :key="go.id">
                            <td scope="row">{{ go.id }}</td>
                            <td> {{ dateFormat(go.created_at).format('LLLL') }}</td>
                            <td> {{ dateFormat(go.updated_at).format('LLLL') }}</td>
                            <td scope="row">{{ go.amount }} $</td>
                            <td>
                                                    <span class="badge bg-info"
                                                          v-if="go.status==='treatment'">{{ go.status }}</span>
                              <span class="badge bg-warning"
                                    v-if="go.status==='pending'">{{ go.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="go.status==='failed'">{{ go.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="go.status==='canceled'">{{ go.status }}</span>
                              <span class="badge bg-success"
                                    v-if="go.status==='sent'">{{ go.status }}</span>
                            </td>
                            <td scope="row">{{ go.method_data.withdraw_mode }}</td>
                            <td scope="row" v-if="go.mode==='bank'">{{ go.method_data.data.bank_account }}</td>
                            <td scope="row" v-if="go.mode==='mobile'">{{ go.method_data.account_id }}</td>
                            <!--                                                    <td scope="row">{{ part.dlocal_cashout_id }}</td>
                                                                                <td scope="row">{{ part.exchange_rate }}</td>-->
                            <td scope="row">{{ go.total_amount }} $</td>
                            <td scope="row">{{ go.fixed_cost }}</td>
                            <td scope="row">{{ go.variable_percent_cost }}</td>
                            <td scope="row">
                              {{ go.method_data.last_name + " " + go.method_data.first_name }}
                            </td>


                            <td>
                              <div class="d-flex">
                                <div class="mb-1">
                                  <select class="form-select" v-model="this.formeData.status" v-on:change="canceledConfirmation(go.id)">
                                    <option value="treatment">TREATMENT</option>
                                    <option value="canceled" >CANCELED</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                          </tr>

                          </tbody >
                          <tbody v-else>
                          <tr >
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="pending.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
                <div role="tabpanel" v-if="this.changePage==='treatment'" class="tab-pane active" id="treatment" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <a href="" class="btn btn-outline-primary sm  waves-effect" style="margin-right: 4px"  aria-expanded="false">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                          </span>
                      </a>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("payout.card.treatment") + " ( "+ formateNumber(traitement.totals) + " )"}}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','treatment')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="traitement_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">{{$t("users.table_header.surname")}}</option>
                        <option value="LAST_NAME">{{$t("users.table_header.first_name")}}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="traitement_search_tiping" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="treatment">
                          <thead>
                          <tr>
                            <th class="coll">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th class="coll">Amount</th>
                            <th class="coll">Status</th>
                            <th class="coll">MODE</th>
                            <th class="coll">ACCOUNT</th>
                            <th class="coll">TOTAL AMOUNT</th>
                            <th class="coll">FIXED COST</th>
                            <th class="coll">VP COST</th>
                            <th class="coll">NAME</th>
                          </tr>
                          </thead>
                          <tbody v-if="traitement.totals!==0">
                          <tr v-for="dia in traitementFilter" :key="dia.id">
                            <td scope="row">{{ dia.id }}</td>
                            <td> {{ dateFormat(dia.created_at).format('LLLL') }}</td>
                            <td> {{ dateFormat(dia.updated_at).format('LLLL') }}</td>
                            <td scope="row">{{ dia.amount }} $</td>
                            <td>
                                                    <span class="badge bg-info"
                                                          v-if="dia.status==='treatment'">{{ dia.status }}</span>
                              <span class="badge bg-warning"
                                    v-if="dia.status==='pending'">{{ dia.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="dia.status==='failed'">{{ dia.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="dia.status==='canceled'">{{ dia.status }}</span>
                              <span class="badge bg-success"
                                    v-if="dia.status==='sent'">{{ dia.status }}</span>
                            </td>
                            <td scope="row">{{ dia.method_data.withdraw_mode }}</td>
                            <td scope="row" v-if="dia.mode==='bank'">{{ dia.method_data.data.bank_account }}</td>
                            <td scope="row" v-if="dia.mode==='mobile'">{{ dia.method_data.account_id }}</td>
                            <!--                                                    <td scope="row">{{ part.dlocal_cashout_id }}</td>
                                                                                <td scope="row">{{ part.exchange_rate }}</td>-->
                            <td scope="row">{{ dia.total_amount }} $</td>
                            <td scope="row">{{ dia.fixed_cost }}</td>
                            <td scope="row">{{ dia.variable_percent_cost }}</td>
                            <td scope="row">
                              {{ dia.method_data.last_name + " " + dia.method_data.first_name }}
                            </td>

                          </tr>

                          </tbody >
                          <tbody v-else>
                          <tr>
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="traitement.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
                <div role="tabpanel" v-if="this.changePage==='sent'" class="tab-pane active" id="sent" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <a href="" class="btn btn-outline-primary sm  waves-effect" style="margin-right: 4px"  aria-expanded="false">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                          </span>
                      </a>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("payout.card.sent") + " ( "+ formateNumber(sent.totals) + " )"}}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','sent')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="sent_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">{{$t("users.table_header.surname")}}</option>
                        <option value="LAST_NAME">{{$t("users.table_header.first_name")}}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="sent_search_tiping" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="sent">
                          <thead>
                          <tr>
                            <th class="coll">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th class="coll">Amount</th>
                            <th class="coll">Status</th>
                            <th class="coll">MODE</th>
                            <th class="coll">ACCOUNT</th>
                            <th class="coll">TOTAL AMOUNT</th>
                            <th class="coll">FIXED COST</th>
                            <th class="coll">VP COST</th>
                            <th class="coll">NAME</th>
                          </tr>
                          </thead>
                          <tbody v-if="sent.totals!==0">
                          <tr v-for="pa in sentFilter" :key="pa.id">
                            <td scope="row">{{ pa.id }}</td>
                            <td> {{ dateFormat(pa.created_at).format('LLLL') }}</td>
                            <td> {{ dateFormat(pa.updated_at).format('LLLL') }}</td>
                            <td scope="row">{{ pa.amount }} $</td>
                            <td>
                                                    <span class="badge bg-info"
                                                          v-if="pa.status==='treatment'">{{ pa.status }}</span>
                              <span class="badge bg-warning"
                                    v-if="pa.status==='pending'">{{ pa.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="pa.status==='failed'">{{ pa.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="pa.status==='canceled'">{{ pa.status }}</span>
                              <span class="badge bg-success"
                                    v-if="pa.status==='sent'">{{ pa.status }}</span>
                            </td>
                            <td scope="row">{{ pa.method_data.withdraw_mode }}</td>
                            <td scope="row" v-if="pa.mode==='bank'">{{ pa.method_data.data.bank_account }}</td>
                            <td scope="row" v-if="pa.mode==='mobile'">{{ pa.method_data.account_id }}</td>
                            <!--                                                    <td scope="row">{{ part.dlocal_cashout_id }}</td>
                                                                                <td scope="row">{{ part.exchange_rate }}</td>-->
                            <td scope="row">{{ pa.total_amount }} $</td>
                            <td scope="row">{{ pa.fixed_cost }}</td>
                            <td scope="row">{{ pa.variable_percent_cost }}</td>
                            <td scope="row">
                              {{ pa.method_data.last_name + " " + pa.method_data.first_name }}
                            </td>
                          </tr>

                          </tbody >
                          <tbody v-else>
                          <tr>
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="sent.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
                <div role="tabpanel" v-if="this.changePage==='failed'" class="tab-pane active" id="failed" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <a href="" class="btn btn-outline-primary sm  waves-effect" style="margin-right: 4px"  aria-expanded="false">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                          </span>
                      </a>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("payout.card.failed") + " ( "+ formateNumber(failed_data.totals) + " )"}}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','failed')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="failed_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">{{$t("users.table_header.surname")}}</option>
                        <option value="LAST_NAME">{{$t("users.table_header.first_name")}}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="failed_search_tiping" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="failed">
                          <thead>
                          <tr>
                            <th class="coll">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th class="coll">Amount</th>
                            <th class="coll">Status</th>
                            <th class="coll">MODE</th>
                            <th class="coll">ACCOUNT</th>
                            <th class="coll">TOTAL AMOUNT</th>
                            <th class="coll">FIXED COST</th>
                            <th class="coll">VP COST</th>
                            <th class="coll">NAME</th>
                            <th class="coll">REASON</th>
                          </tr>
                          </thead>
                          <tbody v-if="failed_data.totals!==0">
                          <tr v-for="si in failedFilter" :key="si.id">
                            <td scope="row">{{ si.id }}</td>
                            <td> {{ dateFormat(si.created_at).format('LLLL') }}</td>
                            <td> {{ dateFormat(si.updated_at).format('LLLL') }}</td>
                            <td scope="row">{{ si.amount }} $</td>
                            <td>
                                                    <span class="badge bg-info"
                                                          v-if="si.status==='treatment'">{{ si.status }}</span>
                              <span class="badge bg-warning"
                                    v-if="si.status==='pending'">{{ si.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="si.status==='failed'">{{ si.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="si.status==='canceled'">{{ si.status }}</span>
                              <span class="badge bg-success"
                                    v-if="si.status==='sent'">{{ si.status }}</span>
                            </td>
                            <td scope="row">{{ si.method_data.withdraw_mode }}</td>
                            <td scope="row" v-if="si.mode==='bank'">{{ si.method_data.data.bank_account }}</td>
                            <td scope="row" v-if="si.mode==='mobile'">{{ si.method_data.account_id }}</td>
                            <!--                                                    <td scope="row">{{ part.dlocal_cashout_id }}</td>
                                                                                <td scope="row">{{ part.exchange_rate }}</td>-->
                            <td scope="row">{{ si.total_amount }} $</td>
                            <td scope="row">{{ si.fixed_cost }}</td>
                            <td scope="row">{{ si.variable_percent_cost }}</td>
                            <td scope="row">
                              {{ si.method_data.last_name + " " + si.method_data.first_name }}
                            </td>
                            <td>
                                <span>
                                   {{ si.legal_reason?si.legal_reason.description:"" }}
                                </span>
                            </td>
                          </tr>

                          </tbody >
                          <tbody v-else>
                          <tr>
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="failed_data.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
                <div role="tabpanel" v-if="this.changePage==='blacklist'" class="tab-pane active" id="rejected" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <a href="" class="btn btn-outline-primary sm  waves-effect" style="margin-right: 4px"  aria-expanded="false">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                          </span>
                      </a>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("payout.card.blacklist") + " ( "+ formateNumber(canceled.totals) + " )"}}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','blacklist')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="canceled_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">{{$t("users.table_header.surname")}}</option>
                        <option value="LAST_NAME">{{$t("users.table_header.first_name")}}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="canceled_search_tiping" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="blacklist">
                          <thead>
                          <tr>
                            <th class="coll">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th class="coll">Amount</th>
                            <th class="coll">Status</th>
                            <th class="coll">MODE</th>
                            <th class="coll">ACCOUNT</th>
                            <th class="coll">TOTAL AMOUNT</th>
                            <th class="coll">FIXED COST</th>
                            <th class="coll">VP COST</th>
                            <th class="coll">NAME</th>
                            <th scope="col">{{ $t("payout.table.change_status") }}</th>
                          </tr>
                          </thead>
                          <tbody v-if="canceled.totals!==0">
                          <tr v-for="ca in canceledFilter" :key="ca.id">
                            <td scope="row">{{ ca.id }}</td>
                            <td> {{ dateFormat(ca.created_at).format('LLLL') }}</td>
                            <td> {{ dateFormat(ca.updated_at).format('LLLL') }}</td>
                            <td scope="row">{{ ca.amount }} $</td>
                            <td>
                              <span class="badge bg-info"
                                    v-if="ca.status==='treatment'">{{ ca.status }}</span>
                              <span class="badge bg-warning"
                                    v-if="ca.status==='pending'">{{ ca.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="ca.status==='failed'">{{ ca.status }}</span>
                              <span class="badge bg-danger"
                                    v-if="ca.status==='canceled'">{{ ca.status }}</span>
                              <span class="badge bg-success"
                                    v-if="ca.status==='sent'">{{ ca.status }}</span>
                            </td>
                            <td scope="row">{{ ca.method_data.withdraw_mode }}</td>
                            <td scope="row" v-if="ca.mode==='bank'">{{ ca.method_data.data.bank_account }}</td>
                            <td scope="row" v-if="ca.mode==='mobile'">{{ ca.method_data.account_id }}</td>
                            <!--                                                    <td scope="row">{{ part.dlocal_cashout_id }}</td>
                                                                                <td scope="row">{{ part.exchange_rate }}</td>-->
                            <td scope="row">{{ ca.total_amount }} $</td>
                            <td scope="row">{{ ca.fixed_cost }}</td>
                            <td scope="row">{{ ca.variable_percent_cost }}</td>
                            <td scope="row">
                              {{ ca.method_data.last_name + " " + ca.method_data.first_name }}
                            </td>

                            <td>
                              <div class="d-flex">
                                <div class="mb-1">
                                  <select class="form-select" v-model="this.formeData.status" v-on:change="canceledConfirmation(ca.id)">
                                    <option value="pending">PENDING</option>
                                    <option value="permanently_cancel">PERMENENTLY CANCELED</option>
                                  </select>
                                </div>
                              </div>
                            </td>
                          </tr>

                          </tbody >
                          <tbody v-else>
                          <tr>
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="canceled.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

