<script>
import {
  computed,
} from 'vue';

import store from '../../store';
import {exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";
import Swal from 'sweetalert2';


export default {
  components: {TableComponent},
  data() {
    return {
      changePage:"bank",
      created: [],
      created_search_by:'MONTANT',
      created_search_tiping:'',
      created_mobile: [],
      created_mobile_search_by:'MONTANT',
      created_mobile_search_tiping:'',
      formeData:{
        status:'',
      },
      edit_id:'',
      elt:"",
      raisons:[],
      amountByStatus:[],
      next_pageb:0,
      next_pagem:0,
      state_choice:'b',

    };
  },
  mounted() {
    this.getTransferOrderTotalAmountByStatus('created')
    this.getPayouts('bank','created');
    this.getPayouts('mobile','created');
  },

  computed:{
    createdFilter(){
      if (this.created_search_tiping.trim().length>0){
        if (this.created_search_by=="MONTANT"){
          return this.created.data.filter((item)=>item.amount ==(this.created_search_tiping));
        }else if(this.created_search_by=="ETOILS"){
          return this.created.data.filter((item)=>item.Payouts_number == this.created_search_tiping);
        }else if(this.created_search_by=="POINT"){
          return this.created.data.filter((item)=>item.point == (this.created_search_tiping));
        }
      }
      return this.created.data;
    },
    createdMobileFilter(){
      if (this.created_mobile_search_tiping.trim().length>0){
        if (this.created_mobile_search_by=="MONTANT"){
          return this.created_mobile.data.filter((item)=>item.amount ==(this.created_search_tiping));
        }else if(this.created_mobile_search_by=="ETOILS"){
          return this.created_mobile.data.filter((item)=>item.Payouts_number == this.created_search_tiping);
        }else if(this.created_mobile_search_by=="POINT"){
          return this.created_mobile.data.filter((item)=>item.point == (this.created_search_tiping));
        }
      }
      return this.created_mobile.data;
    },
  },
  methods: {
    formateNumber,
    dateFormat(date){
      return moment(date);
    },
    switchBetween(id){
      if (id===1){
        this.changePage = 'bank';
        this.getPayouts('bank','created',true);

      }else if (id===2){
        this.changePage = 'mobile';
        this.getPayouts('mobile','created',true);

      }
    },

    changeSlide(option){
      if(option==="b"){
        this.state_choice = option;
      }else if(option==="m"){
        this.state_choice = option;
      }
    },
    getTransferOrderTotalAmountByStatus(mode){
      store.dispatch("getTransferOrderTotalAmountByStatus",mode);
      this.amountByStatus = computed(() => store.state.amountByStatus);
    },

    getPayouts(mode,type,action=false) {
      let page = 0;
      if (!action ) {
        if (mode ==="bank" ) {
          page = store.state.created.next_page;
        } else if (mode ==="mobile") {
          page = store.state.created_mobile.next_page;
        }
      }else{
        if (mode ==="bank") {
          page = store.state.created.current_page;
        } else if (mode ==="mobile") {
          page = store.state.created_mobile.current_page;
        }
      }
      store.dispatch("getPayouts", [mode,type,page]);
      if (mode==="bank") {
        this.created = computed(() => store.state.created);
      }else if(mode==="mobile"){
        this.created_mobile = computed(() => store.state.created_mobile);
      }
    },
    editPayoutsState(id){
      store.dispatch("updatePayouts", [id,this.formeData])
          .then((response) => {

            if (this.changePage==="mobile"){
              this.getPayouts('mobile','created');
              store.state.created_mobile.data =  store.state.created_mobile.data.filter(obj => obj.id !== id);
            }else if (this.changePage==="bank"){
              this.getPayouts('bank','created');
              store.state.created.data =  store.state.created.data.filter(obj => obj.id !== id);
            }

            this.$toast.success(this.$t('alert.success_text'));


           this.formeData.status = '';
            this.getPayouts('bank','created',true);
            this.getPayouts('mobile','created',true);
            return response
          })
          .catch((error) => {
            this.$toast.error(this.$t('alert.error_text'));
            return error
          });
    },
    confrimation(id){
      Swal.fire({
        title: 'Confirmation',
        text: `Voullez-vous vraiment continer cette operation ?`,
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText: `Oui`,
        customClass: { confirmButton: "btn btn-warning", cancelButton: "btn btn-outline-danger ms-1" },
        buttonsStyling: !1,
      }).then((result) => {
        if (result.value) {
          this.editPayoutsState(id,this.formeData.status);
        }
      })
    },

    payoutMethod(data){
      console.log(data);
      var methodP = [];
      if(data.transfer_type=='mobile'){
        methodP=[data.network,data.account_id,data.country_code];
      }else if(data.transfer_type=='bank'){
        methodP=[data.bank_name,data.account_id];
      }else{
        methodP=[data.fr_country,data.account_id];
      }

      return methodP;
    },
    exportexls_auth(type,name,fn,dl){
      if (name ==="payement_created") {
        this.elt = this.$refs.payement_traitement;
      }
      return exportexls_auth(type,name,fn,dl,this.elt)
    }

  },
}

</script>

<template>

  <!-- Modal -->
  <div class="modal fade text-start" id="addMotif" tabindex="-1" aria-labelledby="myModalLabel1" aria-hidden="true">
    <div class="modal-dialog" id="wrapped">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel1">{{ $t("payout.form.title") }}</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="needs-validation" novalidate @submit.prevent="confrimation(this.edit_id,'canceled')">
            <div class="mb-1 col-md-12">
              <label class="form-label">{{ $t("payout.form.option") }}</label>
              <select class="form-control w-100" v-model="this.motif">
                <option label=" "></option>
                <option v-for="ra in raisons.data" v-bind:key="ra.id" :value="ra.id">{{ra.code}}: {{ra.description}}</option>
              </select>
            </div>
            <div class="col-lg-12">
              <button type="submit" id="btn_soumettre_motif" class="btn btn-primary float-rigth offset-9 waves-effect waves-float waves-light"> {{ $t("raisons.form.btn") }}</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <!-- Basic trigger modal end -->
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{$t("dashboard.created.title")}}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">{{$t("dashboard.created.sub_title")}}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" :class="{active:this.changePage==='bank'}" href="#" v-on:click="switchBetween(1)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg><span class="align-middle m-lg-1">{{ $t("payout.card.bank") }}</span></a>
                <a class="dropdown-item" :class="{active:this.changePage==='mobile'}" href="#" v-on:click="switchBetween(2)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  <span class="align-middle  m-lg-1">{{ $t("payout.card.mobile") }}</span></a>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <!-- Dashboard Ecommerce Payoutsts -->
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <!-- Medal Card -->
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="card">
                <div class="card-header  align-items-start pb-0" style="margin-bottom: 2%">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">

                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(created.totals) }}</h2>
                  <p class="card-text">{{ $t("payout.card.bank") }}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="card">
                <div class="card-header  align-items-start pb-0" style="margin-bottom: 2%">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smartphone"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(created_mobile.totals) }}</h2>
                  <p class="card-text">{{ $t("payout.card.mobile") }}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>

          </div>
        </section>
        <!-- Multilingual -->
        <!-- Justified Pills Payoutst -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div role="tabpanel" v-if="this.changePage==='bank'" >
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <a href="" class="btn btn-outline-primary sm  waves-effect" style="margin-right: 4px"  aria-expanded="false">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                          </span>
                      </a>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ this.changePage }}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">

                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','payement_created')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}</span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="created_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="MONTANT">{{ $t("payout.table.amount") }}</option>
                        <option value="NOM">{{ $t("payout.table.name") }}</option>
                        <option value="EMAIL">{{ $t("payout.table.email") }}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input  type="search" v-model="created_search_tiping" class="form-control" placeholder="Recherchez" aria-controls="DataTables_Table_0"></label>
                      </div>

                    </template>
                    <template v-slot:table>

                      <table class="table table-responsive mt-2 table-striped table-bordered" ref="payement_created">
                        <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">{{ $t("report_user.table_crested") }}</th>
                          <th scope="col">{{ $t("report_user.table_updated") }}</th>
                          <th scope="col">{{ $t("payout.table.amount") }}</th>
                          <th scope="col">{{ $t("payout.table.name") }}</th>
                          <th scope="col">{{ $t("payout.table.email") }}</th>
                          <th scope="col">{{ $t("payout.table.status") }}</th>
                          <th scope="col">{{ $t("payout.table.method") }}</th>
                          <th scope="col">{{ $t("payout.table.country_net") }}</th>
                          <th scope="col">{{ $t("payout.table.change_status") }}</th>
                        </tr>
                        </thead>
                        <tbody v-if="created.totals != 0">
                        <tr  v-for="go in createdFilter" v-bind:key="go.id">
                          <td scope="row">{{go.id}}</td>
                          <td> {{dateFormat(go.created_at).format('LLLL')}} </td>
                          <td> {{dateFormat(go.updated_at).format('LLLL')}} </td>
                          <td>
                            {{go.amount}}
                          </td>
                          <td>
                            {{ go.method_data.full_name +" "+go.method_data.full_last_name }}
                          </td>
                          <td>
                            {{go.user.email}}
                          </td>
                          <td>
                            <span class="badge bg-warning" >{{go.status}}</span>
                          </td>
                          <td>
                            {{ go.method_data.transfer_type}}
                          </td>

                          <td>
                              <span>
                                  {{go.method_data.data?go.method_data.data.bank_account:"" }}
                              </span>
                          </td>

                          <td>
                            <div class="d-flex">
                              <div class="mb-1">
                                <select class="form-select" v-model="this.formeData.status" v-on:change="confrimation(go.id)">
                                  <option value=""></option>
                                  <option  value="pending">PENDING</option>
                                </select>
                              </div>
                            </div>
                          </td>
                        </tr>


                        </tbody >
                        <tbody v-else>
                        <tr>
                          <td colspan="10" class="text-center">{{ $t("global_texte.empty_list") }}</td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr>
                          <td colspan="10" class="text-center" v-if="this.created.loading">
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
                <div role="tabpanel" v-if="this.changePage==='mobile'">
                  <!-- Row grouping -->
                  <table-component>
                    <!-- <template v-slot:left>
                    </template> -->

                    <template v-slot:left>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ this.changePage }}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">

                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','payement_created')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                                                            <span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                                                {{ $t("global_texte.export") }}</span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="created_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="MONTANT">{{ $t("payout.table.amount") }}</option>
                        <option value="NOM">{{ $t("payout.table.name") }}</option>
                        <option value="EMAIL">{{ $t("payout.table.email") }}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input  type="search" v-model="created_search_tiping" class="form-control" placeholder="Recherchez" aria-controls="DataTables_Table_0"></label>
                      </div>

                    </template>
                    <template v-slot:table>

                      <table class="table table-responsive mt-2 table-striped table-bordered" ref="payement_created">
                        <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">{{ $t("report_user.table_crested") }}</th>
                          <th scope="col">{{ $t("report_user.table_updated") }}</th>
                          <th scope="col">{{ $t("payout.table.amount") }}</th>
                          <th scope="col">{{ $t("payout.table.name") }}</th>
                          <th scope="col">{{ $t("payout.table.email") }}</th>
                          <th scope="col">{{ $t("payout.table.status") }}</th>
                          <th scope="col">{{ $t("payout.table.method") }}</th>
                          <th scope="col">{{ $t("payout.table.change_status") }}</th>
                        </tr>
                        </thead>
                        <tbody  v-if="created_mobile.totals != 0">
                        <tr v-for="mo in createdMobileFilter" v-bind:key="mo.id">
                          <td scope="row">{{mo.id}}</td>
                          <td> {{dateFormat(mo.created_at).format('LLLL')}} </td>
                          <td> {{dateFormat(mo.updated_at).format('LLLL')}} </td>
                          <td>
                            {{mo.amount}}
                          </td>
                          <td>
                            {{ mo.method_data.full_name +" "+mo.method_data.full_last_name }}
                          </td>
                          <td>
                            {{mo.user.email}}
                          </td>
                          <td>
                            <span class="badge bg-warning" >{{mo.status}}</span>
                          </td>
                          <td>
                            {{ mo.method_data.transfer_type}}
                          </td>

                          <td>
                            <div class="d-flex">
                              <div class="mb-1">
                                <select class="form-select" v-model="this.formeData.status" v-on:change="confrimation(mo.id)">
                                  <option value=""></option>
                                  <option  value="pending">PENDING</option>
                                </select>
                              </div>
                            </div>
                          </td>
                        </tr>


                        </tbody>
                        <tbody v-else>
                        <tr>
                          <td colspan="10" class="text-center">{{ $t("global_texte.empty_list") }}</td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr>
                          <td colspan="10" class="text-center" v-if="this.created_mobile.loading">
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

