
<script>
import store from '../../store/index';
import {checkRole} from "@/components/authentication/firstCaptchat";
import firebase from '../../config/firebase/firebase';
import 'firebase/auth';
import {ref} from "vue";


export default {
  data() {
    return {
      otp_submit : false,
      container:ref(),
      otpAray:[""],
      time:500,
      isDisabled: true,
      loadin_otp:true,
      showLogForm: true,
      showOtpForm:false,
      showCaptchat:true,

      number_1: 4,
      number_2: 4,
      sign: '+',
      result: '',
      sign_table: ['-', '+', '*'],
      email_user:'',
      password_user:'',
      loading:false,
      errorMsg:"",
      verificationCode: '',
      appVerifier:null,
      user_details:[],
    };
  },

  mounted() {
    this.number_1 = Math.floor(Math.random() * 10) + 1;
    this.number_2 = Math.floor(Math.random() * 10) + 1;
    this.sign = this.sign_table[Math.floor(Math.random() * 3)];


    this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');


  },

  beforeMount() {

  },
  methods: {
    captcha() {
      if ((parseInt(this.operated(this.sign, this.number_1, this.number_2))) !== parseInt(this.result)) {
          this.isDisabled = true;
        return false;
      } else {
        this.isDisabled = false;
        return true;
      }
    },

    toggleForm() {
      this.showOtpForm = !this.showOtpForm;
    },
    checkkField(text){
      if (text.toString().replace(/\s/g, '') === '') {
        return true;
      }
    },
    handleEnter(e,i){
      let children = this.$refs.container.children
      const keypressed = e.key;
      let otplength = Object.keys(this.otpAray).length;
      if (i > 0 && (keypressed==="Backspace" || keypressed==="Delete")){
        delete  this.otpAray[i];
        setTimeout(()=>{
          children[i-1].focus()
        },100)
      }else {
        const mached = keypressed.match(/^[0-9]/)
        if (!mached){
          delete  this.otpAray[i];
          //this.otpAray[i]=null
        }
        else if(i < otplength && otplength<6 ){
            setTimeout(()=>{
              children[i+1].focus()
            })
        }
      }
    },
    operated(sig, numbre_1, numbre_2) {
      if (sig === "+") {
        return numbre_1 + numbre_2;
      } else if (sig === "-") {
        return numbre_1 - numbre_2;
      } else if (sig === "*") {
        return numbre_1 * numbre_2;
      }
    },

    async login() {
      this.loading = true,
          store.dispatch('login', {email:this.email_user,password:this.password_user})
              .then((response) => {
                if (response.data){
                  let req = checkRole(response.data.role.name);
                  setTimeout(()=>{
                    if (req){
                      this.user_details = response.data;
                      let formatNumber = "+"+this.user_details.phone_country_code +""+this.user_details.phone_number;
                      this.showLogForm = !this.showLogForm;
                      this.showCaptchat = !this.showCaptchat;
                      this.sendOTP(formatNumber);

                    }else{
                      this.loading = false;
                      this.errorMsg = 'Identifiants incorrecte';
                    }
                  },this.time)
                }
              })
              // eslint-disable-next-line no-unused-vars
              .catch(({response}) => {

                this.errorMsg = 'Veuillez revoir votre adresse mail et le mot de passe';
                this.loading = false;

              })
    },

    async sendOTP(number) {
        try {
          const confirmationResult = await firebase.auth().signInWithPhoneNumber(number, this.appVerifier);
          this.verificationId = confirmationResult.verificationId;
          this.showCaptchat = !this.showCaptchat;
          this.showOtpForm = !this.showOtpForm;

          //  console.log(this.verificationId);

        } catch (error) {
          this.loadin_otp=true;
        }
    },
    otpValuJoin(){
      const valuesArray = Object.values(this.otpAray);
      return valuesArray.join('');

    },
    verifyOTP() {
      this.otp_submit=true;
      let joinValue = this.otpValuJoin()
      if (Object.keys(this.otpAray).length==6){
        try {
          console.log("");
          const credential = firebase.auth.PhoneAuthProvider.credential(this.verificationId, joinValue);
          firebase.auth().signInWithCredential(credential)
              // eslint-disable-next-line no-unused-vars
              .then(userCredential => {
                store.dispatch('session_vux',this.user_details )
                    .then((response) => {

                      if (response){

                        console.log("");
                        window.location.href = '/app/dashboard';
                      }else{
                        window.location.href = '/';
                      }
                    })
                    .catch(({error}) => {
                      return error;
                    })
              })
              .catch(error => {
                this.otp_submit=!this.otp_submit;
                // Sign-in failed
                return error
              });

          console.log('');
        } catch (error) {
          this.otp_submit = !this.otp_submit;
          return error;
        }
      }else{
        this.otp_submit=false;

        console.log("")
      }

    },



  },
}
</script>

<template>


  <div class="auth-wrapper auth-basic px-2">
    <div class="auth-inner my-2">
      <!-- Login basic -->
      <div class="card mb-0" v-if="this.showLogForm">
        <div class="card-body">
          <a href="#" class="brand-logo">
            <img class="w-50" src="../../../public/app-assets/images/logo/logo.png" alt="">
          </a>

          <h4 class="card-title mb-1">{{ $t("login.title") }}! 👋</h4>
          <p class="card-text mb-2">{{ $t("login.subtitle") }}</p>
            <span class="text-danger" v-if="errorMsg">{{ errorMsg }}</span>
          <form class="auth-login-form mt-2" @submit.prevent="login">
            <div class="mb-1">
              <label for="login-email" class="form-label">{{ $t("login.email") }}</label>
                <input type="email" v-model="email_user" class="form-control" placeholder="john@example.com"  tabindex="1" autofocus />
            </div>
            <div class="mb-1">
              <div class="d-flex justify-content-between">
                <label class="form-label" for="login-password">{{ $t("login.password") }}</label>
              </div>
              <div class="input-group input-group-merge form-password-toggle">
                  <input  v-model="password_user" type="password" class="form-control form-control-merge" tabindex="2" placeholder="*******"/>
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="login-password">{{ $t("login.calculetitle") }}</label>

              <div class="d-flex">
                <div class="col-8 d-flex">
                  <p id='nombre1' class="m-2">{{ number_1 }}</p>
                  <p id='signe' class="m-2">{{ sign }}</p>
                  <p id='nombre2' class="m-2">{{ number_2 }}</p>
                  <p id='nombre' class="m-2">=</p>
                </div>
                <div class="col-4 float-rigth">
                  <input class="col-12 form-control form-control-lg" v-model="result" v-on:keyup="captcha" type="number">
                </div>
              </div>
            </div>

            <button class="btn btn-outline-primary w-100" type="button" v-if="loading" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="ms-25 align-middle">Loading...</span>
            </button>
            <button class="btn btn-primary w-100" :disabled='isDisabled' v-if="!loading" tabindex="4">
              {{ $t("login.button") }}
            </button>
          </form>
          <!-- /Login basic -->
        </div>
      </div >

      <!-- Login basic -->
      <div class="card mb-0 mb-3" :class="{ 'hidden': this.showCaptchat }">
        <div class="card-body">
          <h4 class="card-title mb-1">{{ $t("login.title_otp") }}! 👋</h4>
          <p class="card-text mb-2">{{ $t("login.otp") }} </p>

          <div id="recaptcha-container"></div>

          <!-- /Login basic -->
        </div>
      </div>
      <div class="card mb-0 mt-3" v-if="this.showOtpForm">
        <div class="card-body">
          <a href="#" class="brand-logo">
            <img class="w-50" src="../../../public/app-assets/images/logo/logo.png" alt="">
          </a>
          <h2 class="card-title fw-bolder mb-1">Two Step Verification 💬</h2>
          <p class="card-text mb-75">
            We sent a verification code to your mobile.
          </p>
          <form class="mt-2" v-on:submit.prevent="verifyOTP()">
            <h6>Type your 6 digit security code</h6>
            <div ref="container" class="auth-input-wrapper d-flex align-items-center justify-content-between">
              <input v-for="n in 6" required :key="n" v-on:keyup="(e)=>handleEnter(e,n-1)" v-model="otpAray[n-1]" type="text" maxlength="1" class="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1" autofocus="" />
            </div>
            <button type="submit" class="btn btn-primary w-100" v-if="!this.otp_submit" tabindex="4"> {{ $t("login.button_otp") }}</button>
            <button class="btn btn-outline-primary w-100" type="button" v-if="this.otp_submit" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="ms-25 align-middle">Loading...</span>
            </button>
          </form>

<!--          <form class="auth-login-form mt-2" @submit.prevent="verifyOTP()">
            <div class="mb-1">
              <div class="d-flex justify-content-between">
                <label class="form-label" for="login-otp">{{ $t("login.otp_code") }}</label>
              </div>
              <div class="input-group input-group-merge form-password-toggle">
                <input  v-model="this.verificationCode" type="number" class="form-control form-control-merge" tabindex="2" placeholder=" "/>
              </div>
            </div>

            <button class="btn btn-outline-primary w-100" type="button" v-if="this.loadin_otp" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="ms-25 align-middle">Loading...</span>
            </button>
            <button class="btn btn-primary w-100" :disabled='isDisabled' v-if="!this.loadin_otp" tabindex="4">
              {{ $t("login.button_otp") }}
            </button>
          </form>-->
          <!-- /Login basic -->
        </div>
      </div>

    </div>
  </div>
</template>


<style scoped>
@import "../../../public/app-assets/css/plugins/forms/form-validation.css";
@import "../../../public/app-assets/css/pages/authentication.css";
</style>