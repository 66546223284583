<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
      </div>
      <div class="content-body">
        <!-- Dashboard Ecommerce Starts -->
        <section id="dashboard-ecommerce">
          <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
              <div class="row breadcrumbs-top">
                <div class="col-12">
                  <h2 class="content-header-title float-start mb-0">{{$t("dashboard.home.title")}}</h2>
                  <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                      <li class="breadcrumb-item active">{{$t("dashboard.home.sub_title")}}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
              <div class="mb-1 breadcrumb-right">
                <div class="dropdown">
                  <button class="btn-icon btn btn-primary btn-round btn-sm disabled" type="button" aria-haspopup="true" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>

                </div>
              </div>
            </div>
          </div>

          <div class="content-body">
            <!-- Dashboard Analytics Start -->
            <section id="dashboard-analytics">
              <div class="row match-height">
                <!-- Greetings Card starts -->
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="card">
                    <div class="card-header  align-items-start pb-0" style="margin-bottom: 10%">
                      <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-x"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="18" y1="8" x2="23" y2="13"></line><line x1="23" y1="8" x2="18" y2="13"></line></svg>
                        </div>
                      </div>
                      <h2 class="fw-bolder mt-1">{{ formateNumber(this.st_pa.total_element) }}</h2>
                      <p class="card-text">{{$t("dashboard.home.particular")}}</p>
                    </div>
                    <div id="gained-chart"></div>
                  </div>
                </div>
                <!-- Greetings Card ends -->

                <!-- Subscribers Chart Card starts -->
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="card border-2" style="border-block-color: #D05007">
                    <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                      <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>                        </div>
                      </div>
                      <h2 class="fw-bolder mt-1">{{formateNumber(this.st_pa.activate)}}</h2>
                      <p class="card-text">{{$t("dashboard.home.particular")}} Activate</p>
                    </div>
                    <div id="gained-chart"></div>
                  </div>
                </div>
                <!-- Subscribers Chart Card ends -->

                <!-- Orders Chart Card starts -->
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="card">
                    <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                      <div class="avatar bg-light-warning p-50 m-0">
                        <div class="avatar-content">
                          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                        </div>
                      </div>
                      <h2 class="fw-bolder mt-1">{{formateNumber(this.st_pa.confirmed)}}</h2>
                      <p class="card-text">{{$t("dashboard.home.particular")}} Confirm</p>
                    </div>
                  </div>
                </div>
                <!-- Orders Chart Card ends -->
              </div>
              <div class="row match-height">
                <!-- Greetings Card starts -->
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="card">
                    <div class="card-header  align-items-start pb-0" style="margin-bottom: 10%">
                      <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                        </div>
                      </div>
                      <h2 class="fw-bolder mt-1">{{ formateNumber(this.st_co.total_element) }}</h2>
                      <p class="card-text">{{$t("dashboard.home.company")}}</p>
                    </div>
                    <div id="gained-chart"></div>
                  </div>
                </div>
                <!-- Greetings Card ends -->

                <!-- Subscribers Chart Card starts -->
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="card border-2" style="border-block-color: #D05007">
                    <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                      <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                        </div>
                      </div>
                      <h2 class="fw-bolder mt-1">{{formateNumber(this.st_co.activate)}}</h2>
                      <p class="card-text">{{$t("dashboard.home.company")}} Activate</p>
                    </div>
                    <div id="gained-chart"></div>
                  </div>
                </div>
                <!-- Subscribers Chart Card ends -->

                <!-- Orders Chart Card starts -->
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="card">
                    <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                      <div class="avatar bg-light-warning p-50 m-0">
                        <div class="avatar-content">

                          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>                        </div>
                      </div>
                      <h2 class="fw-bolder mt-1">{{formateNumber(this.st_co.confirmed)}}</h2>
                      <p class="card-text">{{$t("dashboard.home.company")}} Confirm</p>
                    </div>
                  </div>
                </div>
                <!-- Orders Chart Card ends -->
              </div>
            </section>
          </div>

          <div class="d-flex row">
            <!-- Support Tracker Chart Card starts -->
            <div class="columns col-lg-6">
                <div v-if="this.company" class="card">
                  <div>
                    <div class="row float-end m-1">
                      <div class="dropdown chart-dropdown" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical font-medium-3 cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                          <a class="dropdown-item" href="#" v-on:click="changeCompany">Not Activated</a>
                        </div>
                      </div>
                    </div>
                    <bar-chart/>
                  </div>
                </div >

                <div v-if="!this.company" class="card">
                  <div>
                    <div class="row float-end m-1">
                      <div class="dropdown chart-dropdown" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical font-medium-3 cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                          <a class="dropdown-item" href="#" v-on:click="changeCompany">Activated</a>
                        </div>
                      </div>
                    </div>
                    <bar-chartnoactive/>
                  </div>
                </div>
            </div>


            <div class="columns col-lg-6">
                <div v-if="this.particular" class="card">
                  <div>
                    <div class="row float-end m-1">
                      <div class="dropdown chart-dropdown" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical font-medium-3 cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                          <a class="dropdown-item" href="#" v-on:click="changeParticular">Not Activated</a>
                        </div>
                      </div>
                    </div>
                    <chart-particular/>
                  </div>
                </div>

                <div v-if="!this.particular" class="card">
                  <div>
                    <div class="row float-end m-1">
                      <div class="dropdown chart-dropdown" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical font-medium-3 cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                          <a class="dropdown-item" href="#" v-on:click="changeParticular">Activated</a>
                        </div>
                      </div>
                    </div>
                    <chart-particularfalse/>
                  </div>
              </div>
            </div>
          </div>
        </section>


        <!-- Dashboard Ecommerce ends -->

      </div>
    </div>
  </div>

</template>
<script >
import BarChart from "@/components/charts/BarChart.vue";
import BarChartnoactive from "@/components/charts/BarChartnoactive.vue";
import ChartParticular from "@/components/charts/ChartParticular.vue";
import ChartParticularfalse from "@/components/charts/ChartParticularfalse.vue";
import store from "@/store";
import {formateNumber} from "@/store/helpers/helper";
import {computed} from "vue";

export default {
  components: {ChartParticular, BarChart,BarChartnoactive,ChartParticularfalse},
  props: {
    msg: String, // Define the 'msg' prop with its type
  },
  data(){
    return{
      st_pa:[],
      st_co:[],
      invited_user:[],
      company:true,
      particular:true
    }
  },

  mounted() {
    this.getStatistique(1);
    this.getStatistique(2);
/*
    this.getInvitedUser() ;
*/
  },

  methods:{
    changeCompany(){
      this.company = !this.company
    },
    changeParticular(){
      this.particular = !this.particular
    },
    formateNumber,
      getStatistique(id){
          store.dispatch('getStatistiqueUsers',id)
        setTimeout(()=>{
          if (id===1){
            this.st_co = computed(() =>  store.state.statistics_co);
          }else{
            this.st_pa = computed(() =>  store.state.statistics_pa);
          }
        },200)
      },

    /*getInvitedUser(page=1){
      store.dispatch("invitedUser", page);

      setTimeout(()=>{
        this.invited_user = computed(() => store.state.user_invited)
      },200)
    },*/

  },
};
</script>