<script>
import {
  computed,
} from 'vue';

import store from '../../store';
import {exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";

export default {
  components: {TableComponent},
  data() {
    return {
      canceled:[],
      canceled_search_by:'MONTANT',
      canceled_search_tiping:'',
      motif:"",
      formeData:{
        status:'',
      },
      next_page:0,
    };
  },
  mounted() {
    this.getNextPermanentlyCanceled();
  },

  beforeMount(){
    this.getPayouts('all','permanently_cancel');
  },

  computed:{
    canceledFilter(){
      if (this.canceled_search_tiping.trim().length>0){
        if (this.canceled_search_by=="MONTANT"){
          return this.canceled.data.filter((item)=>item.amount ==(this.canceled_search_tiping));
        }else if(this.canceled=="NOM"){
          // return this.canceled.data.filter((item)=>item.transfer_methods.full_name.toLowerCase().includes(this.canceled_search_tiping));
        }else if(this.canceled_search_by=="EMAIL"){
          return this.canceled.data.filter((item)=>item.user_email.toLowerCase().includes(this.canceled_search_tiping));
        }
      }
      return this.canceled.data;
    }
  },
  methods: {
    formateNumber,
    dateFormat(date){
      return moment(date);
    },
    getNextPermanentlyCanceled() {
      window.onscroll = () => {
        // let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
        let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
        if (scrollBottom < 1 || scrollBottom <= 0) {
          if (store.state.canceled.next_page !== null) {
            this.getPayouts('all','permanently_cancel');
          }
        }
      }
    },
    getPayouts(mode,type,action=false) {
      let page = 0;
      if (!action) {
        if (type==="permanently_cancel") {
          page = store.state.canceled.next_page;
        }
      }else{
        page = store.state.canceled.current_page;
      }

      store.dispatch("getPayouts", [mode,type,page]);
      if(type==="permanently_cancel"){
        this.canceled = computed(() => store.state.permanently_cancel);
      }
    },

    exportexls_auth(type,name,fn,dl){
      if(name==="payement_canceled"){
        this.elt = this.$refs.payement_canceled;
      }
      return exportexls_auth(type,name,fn,dl,this.elt)
    }

  },
}

</script>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
      <div class="content-header-left col-md-9 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-start mb-0">{{$t("dashboard.bkn_mno_user.title")}}</h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                <li class="breadcrumb-item active">{{$t("dashboard.bkn_mno_user.sub_title")}}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
        <div class="mb-1 breadcrumb-right">
          <div class="dropdown">
            <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle disabled" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
          </div>
        </div>
      </div>
    </div>


      <div class="content-body">
        <!-- Dashboard Ecommerce Payoutsts -->
        <section id="dashboard-ecommerce">
          <div class="col-lg-12 col-sm-6 col-12">
            <div class="card">
              <div class="card-header  align-items-start pb-0" style="margin-bottom: 2%">
                <div class="avatar bg-light-primary p-50 m-0">
                  <div class="avatar-content">

                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>                  </div>
                </div>
                <h2 class="fw-bolder mt-1">{{ formateNumber(this.canceled.totals)}}</h2>
                <p class="card-text">{{ $t("payout.card.blacklist_canceled") }}</p>
              </div>
              <div id="gained-chart"></div>
            </div>
          </div>
        </section>
        <!-- Multilingual -->
        <!-- Justified Pills Payoutst -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div class="tab-pane active" role="tabpanel" aria-labelledby="profile-tab-justified" aria-expanded="false">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','payement_canceled')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}</span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="canceled_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="MONTANT">MONTANT</option>
                        <option value="NOM">NOM</option>
                        <option value="EMAIL">EMAIL</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input type="search" v-model="canceled_search_tiping" class="form-control" placeholder="Recherchez" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <table class="table table-responsive mt-2 table-bordered" ref="payement_canceled">
                        <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">{{ $t("report_user.table_crested") }}</th>
                          <th scope="col">{{ $t("report_user.table_updated") }}</th>
                          <th scope="col">{{ $t("payout.table.amount") }}</th>
                          <th scope="col">{{ $t("payout.table.name") }}</th>
                          <th scope="col">{{ $t("payout.table.email") }}</th>
                          <th scope="col">{{ $t("payout.table.method") }}</th>
                          <th scope="col">{{ $t("payout.table.country_net") }}</th>
                          <th scope="col">{{ $t("payout.table.reasons") }}</th>
                        </tr>
                        </thead>
                        <tbody v-if="canceled.totals != 0">
                        <tr v-for="ca in canceledFilter" v-bind:key="ca.id">
                          <th scope="row">{{ca.id}}</th>
                          <th> {{dateFormat(ca.created_at).format('LLLL')}} </th>
                          <th> {{dateFormat(ca.updated_at).format('LLLL')}} </th>
                          <td>
                            {{ca.amount}}
                          </td>
                          <td>
                                                        <span >
                                                            {{ ca.user?ca.user.acronym!==null?ca.user.acronym:ca.user.last_name+" "+ca.user.first_name:"" }}
                                                        </span>
                          </td>
                          <td>
                            {{ca.user.email}}
                          </td>
                          <td>
                            {{ ca.method_data.data?(ca.mode==="bank")?"Banque":"Mobile":"" }}
                          </td>

                          <td>
                            {{ ca.method_data.data?(ca.mode==="bank")?ca.method_data.data.bank_account:"mobile":"" }}
                          </td>

                          <td>
                            <span>
                                {{ ca.legal_reason?ca.legal_reason.description:"" }}
                            </span>
                          </td>
                        </tr>

                        </tbody>
                        <tbody v-else>
                        <tr >
                          <td colspan="9" class="text-center">La liste est vide</td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr>
                          <td colspan="9" class="text-center" v-if="this.canceled.loading">
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>
