/**
 * Created by bii-group on ../../2022.
 */
import axios from 'axios';

import store from '../../store';
import {setLogoutSession} from "@/store/helpers/utils";

const axiosClient = axios.create({
    baseURL: `https://api.telofib.com/`
})
axiosClient.defaults.headers.common['Accept'] = 'application/json';
axiosClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${store.state.user.token?(store.state.user.token):store.state.user.token}`
    return config;
})

axiosClient.interceptors.response.use(response => {
    return response;
}, error => {
    setLogoutSession();
    return error;
    //console.log(error);
    /*if (error.response.status === 401 ) {

   }else if(error.response.status === 500){

    }else if(error.response.status === 400) {

    }else{

    }*/

})

export default axiosClient;
