import {formateNumber} from "@/store/helpers/helper";

function concatData(array1,array2){
    /*return array1.concat(array2).reduce((accumulator, currentObj) => {
        const existingObj = accumulator.find(obj => obj.id === currentObj.id);
        if (existingObj) {
            Object.assign(existingObj, currentObj);
        } else {
            accumulator.push(currentObj);
        }
        return accumulator;
    }, []);*/

    array2.forEach(newItem => {
        // Trouver la ligne correspondante dans l'ancien tableau
        const matchingOldItemIndex = array1.findIndex(oldItem => oldItem.id === newItem.id);
        if (matchingOldItemIndex !== -1) {
            // S'il y a une correspondance, comparer les dates de mise à jour
            const oldItem = array1[matchingOldItemIndex];
            if (new Date(newItem.updated_at) > new Date(oldItem.updated_at)) {
                console.log("")
                console.log("")
                // Remplacer la ligne correspondante dans oldData par la nouvelle ligne
                array1[matchingOldItemIndex] = newItem;
            }
        } else {
            // Si aucune correspondance n'est trouvée, ajouter la nouvelle ligne à oldData
            array1.push(newItem);
        }
    });

    return array1;
}
export function setUser(state, user) {
    try {
        localStorage.setItem("USER",user.first_name+" "+user.last_name);
        localStorage.setItem("ROLE",user.role);
        localStorage.setItem("CURRENCY_ID",user.c_id);
        localStorage.setItem("CURRENCY_NAME",user.c_name);
    }catch (e) {
        return e
    }
}

export function setToken(state, [token,exp]) {
   try {
       if (token) {
           localStorage.setItem('EXP', exp);
           localStorage.setItem('TOKEN', (token));
       } else {
           localStorage.removeItem('EXP');
           localStorage.removeItem('TOKEN')
       }
   }catch (e) {
       return e
   }
}

export function setOTP(state, [token,otp]) {
   try {
       if (token) {
           localStorage.setItem('OTP', otp);
       } else {
           localStorage.removeItem('OTP');
       }
   }catch (e) {
       return e;
   }
}

export function setStParticulier(state,[loading,data=null]){

    if (loading===false && data.User){
        state.statistics_pa.activate = data.User[0].activate;
        state.statistics_pa.status = data.User[1].status;
        state.statistics_pa.visible_status = data.User[2].visible_status;
        state.statistics_pa.vip = data.User[3].vip;
        state.statistics_pa.blocked = data.User[4].blocked;
        state.statistics_pa.blocked_instantly = data.User[5].blocked_instantly;
        state.statistics_pa.banished = data.User[6].banished;
        state.statistics_pa.can_receive_profit = data.User[7].can_receive_profit;
        state.statistics_pa.confirmed = data.User[8].confirmed;
        state.statistics_pa.total_element = data.User[9].total_element;
    }

    state.statistics_pa.loading = loading;
}

export function setStCompany(state,[loading,data=null]){
    if (loading===false && data.User){
        state.statistics_co.activate = data.User[0].activate;
        state.statistics_co.status = data.User[1].status;
        state.statistics_co.visible_status = data.User[2].visible_status;
        state.statistics_co.vip = data.User[3].vip;
        state.statistics_co.blocked = data.User[4].blocked;
        state.statistics_co.blocked_instantly = data.User[5].blocked_instantly;
        state.statistics_co.banished = data.User[6].banished;
        state.statistics_co.can_receive_profit = data.User[7].can_receive_profit;
        state.statistics_co.confirmed = data.User[8].confirmed;
        state.statistics_co.total_element = data.User[9].total_element;
    }
    state.statistics_co.loading = loading;
}

export function setInvitedUser(state,[loading,dat=null]){
    if (dat && dat.users){
        state.user_invited.data = concatData(state.user_invited.data,dat.users) ;
        state.user_invited.totals = dat.meta.total_count;
        state.user_invited.number_page = dat.meta.total_pages;
        state.user_invited.current_page = dat.meta.current_page;
        state.user_invited.next_page = dat.meta.next_page;
        state.user_invited.prev_page = dat.meta.prev_page;
    }
    state.user_invited.loading = loading;

}


export function setParticular(state, [loading,data]) {
    if (loading===false && data.users ){
       state.particular.data =  concatData(state.particular.data,data.users),
       state.particular.totals = data.meta.total_count;
       state.particular.number_page =data.meta.total_pages;
       state.particular.current_page =data.meta.current_page;
       state.particular.next_page =data.meta.next_page;
       state.particular.prev_page =data.meta.prev_page;
    }
    state.particular.loading = loading;
}

export function setParticular_filter(state, [loading,data]) {
    if (loading===false && data.users ){
        state.particular={}
        state.particular.number_page =4;
        state.particular.current_page =4;
        state.particular.next_page =null;
        state.particular.prev_page =4;
       state.particular.data = data.users,
       state.particular.totals = data.users.length;

    }
    state.particular.loading = loading;
}
export function setParticularCanNotReceive(state, [loading,data]) {
    if (loading===false && data.users ){
       state.particularcnr.data =  concatData(state.particularcnr.data,data.users),
       state.particularcnr.totals = data.meta.total_count;
       state.particularcnr.number_page =data.meta.total_pages;
       state.particularcnr.current_page =data.meta.current_page;
       state.particularcnr.next_page =data.meta.next_page;
       state.particularcnr.prev_page =data.meta.prev_page;
    }
    state.particularcnr.loading = loading;
}export function setParticularCanNotReceive_filter(state, [loading,data]) {
    if (loading===false && data.users ){
       state.particularcnr.data =  data.users,
       state.particularcnr.totals = data.users.length;
       state.particularcnr.number_page =1;
       state.particularcnr.current_page =1;
       state.particularcnr.next_page =null;
       state.particularcnr.prev_page =null;
    }
    state.particularcnr.loading = loading;
}
export function setCompanyCanNotReceive(state, [loading,data]) {
    if (loading===false && data.users ){
       state.companycnr.data =  concatData(state.companycnr.data,data.users),
       state.companycnr.totals = data.meta.total_count;
       state.companycnr.number_page =data.meta.total_pages;
       state.companycnr.current_page =data.meta.current_page;
       state.companycnr.next_page =data.meta.next_page;
       state.companycnr.prev_page =data.meta.prev_page;
    }
    state.companycnr.loading = loading;
}
export function setCompanyCanNotReceive_filter(state, [loading,data]) {
    if (loading===false && data.users ){
       state.companycnr.data =  data.users,
       state.companycnr.totals = data.users.length;
       state.companycnr.number_page =1;
       state.companycnr.current_page =1;
       state.companycnr.next_page =null;
       state.companycnr.prev_page =null;
    }
    state.companycnr.loading = loading;
}
export function setParticularController(state, [loading,data]) {
    if (loading===false && data.users ){
       state.particular_co.data =  concatData(state.particular_co.data,data.users),
       state.particular_co.totals = data.meta.total_count;
       state.particular_co.number_page =data.meta.total_pages;
       state.particular_co.current_page =data.meta.current_page;
       state.particular_co.next_page =data.meta.next_page;
       state.particular_co.prev_page =data.meta.prev_page;
    }
    state.particular_co.loading = loading;
}
export function setParticularController_filter(state, [loading,data]) {
    if (loading===false && data.users ){
        state.particular_co = {},
        state.particular_co.number_page = 1;
        state.particular_co.totals = data.users.length;
        state.particular_co.current_page =1;
        state.particular_co.next_page =null;
        state.particular_co.prev_page =null;
        state.particular_co.data =  (data.users)
    }
    state.particular_co.loading = loading;
}
export function setCompany(state, [loading,data]) {
    if (loading===false && data.users ){
       state.company.data =  concatData(state.company.data,data.users),
       state.company.totals = data.meta.total_count;
       state.company.number_page =data.meta.total_pages;
       state.company.current_page =data.meta.current_page;
       state.company.next_page =data.meta.next_page;
       state.company.prev_page =data.meta.prev_page;
    }
    state.company.loading = loading;
}
export function setCompany_filter(state, [loading,data]) {
    if (loading===false && data.users ){
        state.company.number_page =4;
        state.company.current_page =4;
        state.company.next_page =null;
        state.company.prev_page =null;
       state.company.data =  data.users,
       state.company.totals = data.users.length;

    }
    state.company.loading = loading;
}
export function setCompanyController(state, [loading,data]) {
    if (loading===false && data.users ){
       state.company_co.data =  concatData(state.company_co.data,data.users),
       state.company_co.totals = data.meta.total_count;
       state.company_co.number_page =data.meta.total_pages;
       state.company_co.current_page =data.meta.current_page;
       state.company_co.next_page =data.meta.next_page;
       state.company_co.prev_page =data.meta.prev_page;
    }
    state.company_co.loading = loading;
}
export function setInvitedController_filter(state, [loading,data]) {
    if (loading===false && data.users ){
        state.invited_co.data =  data.users,
        state.invited_co.totals = data.users.length;
        state.invited_co.number_page =1;
        state.invited_co.current_page =1;
        state.invited_co.next_page =null;
        state.invited_co.prev_page =null;
    }
    state.invited_co.loading = loading;
}
export function setCompanyController_filter(state, [loading,data]) {
    if (loading===false && data.users ){
       state.company_co.data =  (data.users),
       state.company_co.totals = data.users.length;
       state.company_co.number_page =1;
       state.company_co.current_page =1;
       state.company_co.next_page =null;
       state.company_co.prev_page =null;
    }
    state.company_co.loading = loading;
}
export function setInvitedController(state, [loading,data]) {
    if (loading===false && data.users ){
       state.invited_co.data =  concatData(state.invited_co.data,data.users),
       state.invited_co.totals = data.meta.total_count;
       state.invited_co.number_page =data.meta.total_pages;
       state.invited_co.current_page =data.meta.current_page;
       state.invited_co.next_page =data.meta.next_page;
       state.invited_co.prev_page =data.meta.prev_page;
    }
    state.invited_co.loading = loading;
}

export function setProfitByStatus(state, [loading,data]) {
    if (loading===false && data.profit_amount_sum) {
        state.profitsDetails.data = data.profit_amount_sum;
    }
    state.profitsDetails.loading = loading;
}

export function setProfits(state, [loading,data]) {
    if (loading===false && data.profits) {
        state.profits.data =concatData(state.profits.data,data.profits),
        state.profits.total_beneficiaries = data.meta.total_beneficiaries;
        state.profits.number_page = data.meta.total_pages;
        state.profits.current_page = data.meta.current_page;
        state.profits.total_element = data.meta.total_element;
        state.profits.next_page = data.meta.next_page;
        state.profits.prev_page = data.meta.prev_page;
    }
    state.profits.loading = loading;

}
export function setProfits_filter(state, [loading,data]) {
    if (loading===false && data.profits) {
        state.profits.data =data.profits,
        state.profits.total_element = data.profits.length;
        state.profits.number_page = 1;
        state.profits.current_page = 1;
        state.profits.next_page = null;
        state.profits.prev_page = null;

    }

    state.profits.loading = loading;

}
export function setProfitsOvl(state, [loading,data]) {
    if (loading===false && data.profit_operators) {
        state.profits_ovl.data = concatData(state.profits_ovl.data,data.profit_operators),
        state.profits_ovl.total_beneficiaries = data.meta.total_beneficiaries;
        state.profits_ovl.number_page = data.meta.total_pages;
        state.profits_ovl.current_page = data.meta.current_page;
        state.profits_ovl.next_page = data.meta.next_page;
        state.profits_ovl.prev_page = data.meta.prev_page;
        state.profits_ovl.total_pending_beneficiaries = data.meta.total_pending_amount;
        state.profits_ovl.total_treaty_beneficiaries = data.meta.total_treaty_amount;

    }
    state.profits_ovl.loading = loading;

}



export function setWallets(state,[loading,data]){
    if (loading===false && data.json) {
        state.walletsData.data = state.walletsData.data.concat(data.json),

        state.walletsData.totals = data.meta.total_count;
        state.walletsData.number_page = data.meta.total_pages;
        state.walletsData.current_page = data.meta.current_page;
        state.walletsData.next_page = data.meta.next_page;
        state.walletsData.prev_page = data.meta.prev_page;
        state.walletsData.total_amount = data.meta.total_profit;
    }
    state.walletsData.loading = loading;
}
export function setWallets_filter(state,[loading,data]){
    if (loading===false && data.json) {
        state.walletsData.data = (data.json),
        state.walletsData.current_page = 1;
        state.walletsData.next_page =null;
        state.walletsData.prev_page = null;
        state.walletsData.total_amount = data.meta.total_profit;
    }
    state.walletsData.loading = loading;
}
export function setWalletsOvl(state,[loading,data]){
    if (loading===false && data.json) {
        state.walletsData.data = concatData(state.walletsData.data,data.json),

        state.walletsData.totals = data.meta.total_count;
        state.walletsData.number_page = data.meta.total_pages;
        state.walletsData.current_page = data.meta.current_page;
        state.walletsData.next_page = data.meta.next_page;
        state.walletsData.prev_page = data.meta.prev_page;
        state.walletsData.total_amount = data.meta.total_profit;
        state.walletsData.total_pending_beneficiaries = data.meta.total_pending_beneficiaries;
        state.walletsData.total_treaty_beneficiaries = data.meta.total_treaty_beneficiaries;
    }
    state.walletsData.loading = loading;
}

export function setUserDetail(state, [loading,data]) {
    if (loading===false && data.user) {
        state.userDetail.data = data.user;
        state.userDetail.point = data.user.receive_call_amount;
        state.userDetail.account = data.user.account;
        state.userDetail.country = data.user.country;
        state.userDetail.social_networks = data.user.network;
        state.userDetail.social_networks = data.user.network;
        state.userDetail.transactions = data.user.transactions;
    }
    state.userDetail.loading = loading;

}

export function setUserStatistics(state, [loading,data]) {
    if (loading===false && data.length == undefined) {
        state.all_one_user_details.data.adds.pending = formateNumber(data.Ad[0].statuses[0].pending);
        state.all_one_user_details.data.adds.rejected = formateNumber(data.Ad[0].statuses[1].rejected);
        state.all_one_user_details.data.adds.terminated = formateNumber(data.Ad[0].statuses[2].terminated);
        state.all_one_user_details.data.adds.asset = formateNumber(data.Ad[0].statuses[3].asset);
        state.all_one_user_details.data.adds.created = formateNumber(data.Ad[0].statuses[4].created);
        state.all_one_user_details.data.adds.canceled = formateNumber(data.Ad[0].statuses[5].canceled);
        state.all_one_user_details.data.adds.totals = formateNumber(data.Ad[1].total_element);
        //calls
        state.all_one_user_details.data.calls.accepted = formateNumber(data.Call[0].issues[0].accepted);
        state.all_one_user_details.data.calls.canceled = formateNumber(data.Call[0].issues[1].canceled);
        state.all_one_user_details.data.calls.missed = formateNumber(data.Call[0].issues[2].missed);
        state.all_one_user_details.data.calls.totals = formateNumber(data.Call[1].total_element);
        //method type
        state.all_one_user_details.data.PaymentMethod.mobile = formateNumber(data.PaymentMethod[0].method_types[0].mobile);
        state.all_one_user_details.data.PaymentMethod.bank_transfer = formateNumber(data.PaymentMethod[0].method_types[1].bank_transfer);
        state.all_one_user_details.data.PaymentMethod.card = formateNumber(data.PaymentMethod[0].method_types[2].card);
        state.all_one_user_details.data.PaymentMethod.cash = formateNumber(data.PaymentMethod[0].method_types[3].cash);
        state.all_one_user_details.data.PaymentMethod.wallet = formateNumber(data.PaymentMethod[0].method_types[4].wallet);
        state.all_one_user_details.data.PaymentMethod.other = formateNumber(data.PaymentMethod[0].method_types[5].other);

        state.all_one_user_details.data.PaymentMethod.created = formateNumber(data.PaymentMethod[1].statuses[0].created);
        state.all_one_user_details.data.PaymentMethod.pending = formateNumber(data.PaymentMethod[1].statuses[1].pending);
        state.all_one_user_details.data.PaymentMethod.verify = formateNumber(data.PaymentMethod[1].statuses[2].verify);
        state.all_one_user_details.data.PaymentMethod.failed = formateNumber(data.PaymentMethod[1].statuses[3].failed);
        state.all_one_user_details.data.PaymentMethod.disable = formateNumber(data.PaymentMethod[1].statuses[4].disable);

        state.all_one_user_details.data.PaymentMethod.fast = formateNumber(data.PaymentMethod[2].verification_options[0].fast);
        state.all_one_user_details.data.PaymentMethod.king = formateNumber(data.PaymentMethod[2].verification_options[1].king);

        state.all_one_user_details.data.Profit.pending = formateNumber(data.Profit[0].statuses[0].pending);
        state.all_one_user_details.data.Profit.treaty = formateNumber(data.Profit[0].statuses[1].treaty);
        state.all_one_user_details.data.Profit.totals = formateNumber(data.Profit[1].total_element);

        state.all_one_user_details.data.StarBuying.pending = formateNumber(data.StarBuying[0].statuses[0].pending);
        state.all_one_user_details.data.StarBuying.success = formateNumber(data.StarBuying[0].statuses[1].success);
        state.all_one_user_details.data.StarBuying.failed = formateNumber(data.StarBuying[0].statuses[2].failed);
        state.all_one_user_details.data.StarBuying.canceled = formateNumber(data.StarBuying[0].statuses[3].canceled);
        state.all_one_user_details.data.StarBuying.totals = formateNumber(data.StarBuying[1].total_element);

        state.all_one_user_details.data.Transaction.pending = formateNumber(data.Transaction[0].statuses[0].pending);
        state.all_one_user_details.data.Transaction.treaty = formateNumber(data.Transaction[0].statuses[1].treaty);
        state.all_one_user_details.data.Transaction.success = formateNumber(data.Transaction[0].statuses[2].success);
        state.all_one_user_details.data.Transaction.failed = formateNumber(data.Transaction[0].statuses[4].failed);
        state.all_one_user_details.data.Transaction.canceled = formateNumber(data.Transaction[0].statuses[3].canceled);
        state.all_one_user_details.data.Transaction.totals = formateNumber(data.Transaction[1].total_element);

        state.all_one_user_details.data.TransferOrder.sent = formateNumber(data.TransferOrder[0].statuses[0].sent);
        state.all_one_user_details.data.TransferOrder.failed = formateNumber(data.TransferOrder[0].statuses[1].failed);
        state.all_one_user_details.data.TransferOrder.pending = formateNumber(data.TransferOrder[0].statuses[2].pending);
        state.all_one_user_details.data.TransferOrder.treatment = formateNumber(data.TransferOrder[0].statuses[3].treatment);
        state.all_one_user_details.data.TransferOrder.canceled = formateNumber(data.TransferOrder[0].statuses[4].canceled);
        state.all_one_user_details.data.TransferOrder.totals = formateNumber(data.TransferOrder[1].total_element);
    }
    state.all_one_user_details.loading = loading;


}

export function setUserProfitDetails(state, [loading,data]) {
    if (loading===false && data.profit_amount_sum) {
        state.setUserProfitDetails.data = data.profit_amount_sum;
    }
    state.setUserProfitDetails.loading = loading;

}

export function setRole(state,[loading,data]) {
    state.role.loading = loading;
    if (loading===false && data.length != 0) {
        state.role.data = data;
    }
}

export function setSysAd(state, [loading,data]) {
    state.admin.loading = loading;
    if (loading===false && data.controllers) {
        state.admin.data =  concatData(state.admin.data,data.controllers);
        state.admin.totals = data.meta.total_count;
        state.admin.number_page = data.meta.total_pages;
        state.admin.current_page = data.meta.current_page;
        state.admin.next_page = data.meta.next_page;
        state.admin.prev_page = data.meta.prev_page;
    }
}

export function setUserAnnonced(state, [loading,data]) {
    if (loading===false && data.report_users) {
        state.denonced_usersData.data = concatData(state.denonced_usersData.data,data.report_users),
            state.denonced_usersData.totals = data.meta.total_count;
        state.denonced_usersData.number_page = data.meta.total_pages;
        state.denonced_usersData.current_page = data.meta.current_page;
        state.denonced_usersData.next_page = data.meta.next_page;
        state.denonced_usersData.prev_page = data.meta.prev_page;
    }
    state.denonced_usersData.loading = loading;
}

export function setUserAnnonced_filter(state, [loading,data]) {
    if (loading===false && data.report_users) {
        state.denonced_usersData.data = (data.report_users),
        state.denonced_usersData.totals = data.report_users.length;
        state.denonced_usersData.number_page = 1;
        state.denonced_usersData.current_page = 1;
        state.denonced_usersData.next_page = null;
        state.denonced_usersData.prev_page = null;
    }
    state.denonced_usersData.loading = loading;
}

export function setSuspectedUsersParticular(state, [loading,data]){
    if (loading===false && data.users) {
        state.suspectParticular.data = concatData(state.suspectParticular.data,data.users),
        state.suspectParticular.totals = data.meta.total_count;
        state.suspectParticular.number_page = data.meta.total_pages;
        state.suspectParticular.current_page = data.meta.current_page;
        state.suspectParticular.next_page = data.meta.next_page;
        state.suspectParticular.prev_page = data.meta.prev_page;
    }
    state.suspectParticular.loading = loading;
}
export function setSuspectedUsersCompany(state, [loading,data]){
    if (loading===false && data.users) {
        state.suspectCompany.data = concatData(state.suspectCompany.data,data.users),
        state.suspectCompany.totals = data.meta.total_count;
        state.suspectCompany.number_page = data.meta.total_pages;
        state.suspectCompany.current_page = data.meta.current_page;
        state.suspectCompany.next_page = data.meta.next_page;
        state.suspectCompany.prev_page = data.meta.prev_page;
    }
    state.suspectCompany.loading = loading;
}

export function setLogs(state, [loading,data]) {
    if (loading===false && data.controller_activity_logs) {
        state.logs.data =  concatData(state.logs.data,data.controller_activity_logs);
        state.logs.totals = data.meta.total_count;
        state.logs.number_page = data.meta.total_pages;
        state.logs.current_page = data.meta.current_page;
        state.logs.next_page = data.meta.next_page;
        state.logs.prev_page = data.meta.prev_page;
    }
    state.logs.loading = loading;
}

export function setPublicity(state,[loading,data]) {
    if (loading===false && data.ads) {
        let temp = data.ads;
        state.publicity.data = concatData(state.publicity.data,temp) ;
        state.publicity.data = data.ads;
        state.publicity.totals = (data.meta.total_count);
        state.publicity.number_page = data.meta.total_pages;
        state.publicity.current_page = data.meta.current_page;
        state.publicity.next_page = data.meta.next_page;
        state.publicity.prev_page = data.meta.prev_page;
    }
    state.publicity.loading = loading
}

export function setPublicityAsset(state,[loading,data]) {
    if (loading===false && data.ads) {
        let temp = data.ads;
        state.publicity_asset.data = concatData(state.publicity_asset.data,temp) ;
        state.publicity_asset.data = data.ads;
        state.publicity_asset.totals = (data.meta.total_count);
        state.publicity_asset.number_page = data.meta.total_pages;
        state.publicity_asset.current_page = data.meta.current_page;
        state.publicity_asset.next_page = data.meta.next_page;
        state.publicity_asset.prev_page = data.meta.prev_page;
    }
    state.publicity_asset.loading = loading
}

export function setPublicityTerminated(state,[loading,data]) {
    if (loading===false && data.ads) {
        let temp = data.ads;
        state.publicity_terminated.data = concatData(state.publicity_terminated.data,temp) ;
        state.publicity_terminated.data = data.ads;
        state.publicity_terminated.totals = (data.meta.total_count);
        state.publicity_terminated.number_page = data.meta.total_pages;
        state.publicity_terminated.current_page = data.meta.current_page;
        state.publicity_terminated.next_page = data.meta.next_page;
        state.publicity_terminated.prev_page = data.meta.prev_page;
    }
    state.publicity_terminated.loading = loading
}

export function setPublicityRejected(state,[loading,data]) {
    if (loading===false && data.ads) {
        let temp = data.ads;
        state.publicity_rejected.data = concatData(state.publicity_rejected.data,temp) ;
        state.publicity_rejected.data = data.ads;
        state.publicity_rejected.totals = (data.meta.total_count);
        state.publicity_rejected.number_page = data.meta.total_pages;
        state.publicity_rejected.current_page = data.meta.current_page;
        state.publicity_rejected.next_page = data.meta.next_page;
        state.publicity_rejected.prev_page = data.meta.prev_page;
    }
    state.publicity_rejected.loading = loading
}

export function setPendingPayout(state, [loading,data]) {
    if (loading===false && data.transfer_orders) {
        let temp = data.transfer_orders;
        state.pending.data = concatData(state.pending.data,temp) ;
        state.pending.totals = (data.meta.total_count);
        state.pending.number_page = data.meta.total_pages;
        state.pending.current_page = data.meta.current_page;
        state.pending.next_page = data.meta.next_page;
        state.pending.prev_page = data.meta.prev_page;
    }
    state.pending.loading = loading;

}
export function setTraitementPayout(state, [loading,data]) {
    if (loading===false && data.transfer_orders) {
        let temp = data.transfer_orders;
        state.traitement.data = concatData(state.traitement.data,temp) ;
        state.traitement.totals = (data.meta.total_count);
        state.traitement.number_page = data.meta.total_pages;
        state.traitement.current_page = data.meta.current_page;
        state.traitement.next_page = data.meta.next_page;
        state.traitement.prev_page = data.meta.prev_page;
    }
    state.traitement.loading = loading;
}

export function setSentPayout(state, [loading,data]) {
    if (loading===false && data.transfer_orders) {
        let temp = data.transfer_orders;
        state.sent.data = concatData(state.sent.data,temp) ;
        state.sent.totals = (data.meta.total_count);
        state.sent.number_page = data.meta.total_pages;
        state.sent.current_page = data.meta.current_page;
        state.sent.next_page = data.meta.next_page;
        state.sent.prev_page = data.meta.prev_page;
    }
    state.sent.loading = loading;
}


export function setFailedPayout(state, [loading,data]) {
    if (loading===false && data.transfer_orders) {
        let temp = data.transfer_orders;
        state.failed.data = concatData(state.failed.data,temp) ;
        state.failed.totals = formateNumber(data.meta.total_count);
        state.failed.number_page = data.meta.total_pages;
        state.failed.current_page = data.meta.current_page;
        state.failed.next_page = data.meta.next_page;
        state.failed.prev_page = data.meta.prev_page;
    }
    state.failed.loading =loading;
}

export function setCanceledPayout(state, [loading,data]) {
    if (loading===false && data.transfer_orders) {
        let temp = data.transfer_orders;
        state.canceled.data = concatData(state.canceled.data,temp) ;
        state.canceled.totals = (data.meta.total_count);
        state.canceled.number_page = data.meta.total_pages;
        state.canceled.current_page = data.meta.current_page;
        state.canceled.next_page = data.meta.next_page;
        state.canceled.prev_page = data.meta.prev_page;
    }
    state.canceled.loading = loading;

}

export function setCreatedPayout(state, [loading,data]) {
    if (loading===false && data.transfer_orders) {
        let temp = data.transfer_orders;
        state.created.data = concatData(state.created.data,temp) ;
        state.created.totals = (data.meta.total_count);
        state.created.number_page = data.meta.total_pages;
        state.created.current_page = data.meta.current_page;
        state.created.next_page = data.meta.next_page;
        state.created.prev_page = data.meta.prev_page;
    }
    state.created.loading = loading;
}
export function setCreatedMobilePayout(state, [loading,data]) {
    if (loading===false && data.transfer_orders) {
        let temp = data.transfer_orders;
        state.created_mobile.data = concatData(state.created_mobile.data,temp) ;
        state.created_mobile.totals = (data.meta.total_count);
        state.created_mobile.number_page = data.meta.total_pages;
        state.created_mobile.current_page = data.meta.current_page;
        state.created_mobile.next_page = data.meta.next_page;
        state.created_mobile.prev_page = data.meta.prev_page;
    }
    state.created_mobile.loading = loading;
}


export function setPermanentlyPayout(state, [loading,data]) {
    if (loading===false && data.transfer_orders) {
        let temp = data.transfer_orders;
        state.permanently_cancel.data = concatData(state.permanently_cancel.data,temp) ;
        state.permanently_cancel.totals = (data.meta.total_count);
        state.permanently_cancel.number_page = data.meta.total_pages;
        state.permanently_cancel.current_page = data.meta.current_page;
        state.permanently_cancel.next_page = data.meta.next_page;
        state.permanently_cancel.prev_page = data.meta.prev_page;
    }
    state.permanently_cancel.loading = loading;
}

export function setTransferOrderTotalAmountByStatus(state, [loading,data])
{
    if (loading===false && data.amount_sum){

        let dataGet = data.amount_sum;
        state.amountByStatus.canceled = (dataGet.canceled);
        state.amountByStatus.failed =(dataGet.failed);
        state.amountByStatus.pending =(dataGet.pending);
        state.amountByStatus.treatment =(dataGet.treatment);
        state.amountByStatus.sent =(dataGet.sent);
        state.amountByStatus.created =(dataGet.sent);

    }
    state.amountByStatus.loading = loading;

}

export function setMotifsJuridique(state, [loading,data]) {
    if (loading===false && data.legal_reasons) {
        let temp = data.legal_reasons;
        state.raisons.data = concatData(state.raisons.data,temp) ;
        state.raisons.totals = (data.meta.total_count);
        state.raisons.number_page = data.meta.total_pages;
        state.raisons.current_page = data.meta.current_page;
        state.raisons.next_page = data.meta.next_page;
        state.raisons.prev_page = data.meta.prev_page;
    }
    state.raisons.loading =loading;
}

export function setPendingPayout_op(state,  [loading,data]) {
    if (loading===false && data.operator_transfer_orders) {
        let temp = data.operator_transfer_orders;
        state.pending.data = concatData(state.pending.data,temp) ;
        state.pending.totals = (data.meta.total_count);
        state.pending.number_page = data.meta.total_pages;
        state.pending.current_page = data.meta.current_page;
        state.pending.next_page = data.meta.next_page;
        state.pending.prev_page = data.meta.prev_page;
        state.pending.total_pending_amount = data.meta.total_pending_amount;
    }
    state.pending.loading = loading;

}
export function setSentPayout_op(state,  [loading,data]) {
    if (loading===false && data.operator_transfer_orders) {
        let temp = data.operator_transfer_orders;
        state.sent.data = concatData(state.sent.data,temp) ;
        state.sent.totals = (data.meta.total_count);
        state.sent.number_page = data.meta.total_pages;
        state.sent.current_page = data.meta.current_page;
        state.sent.next_page = data.meta.next_page;
        state.sent.prev_page = data.meta.prev_page;
        state.sent.total_sent_amount = data.meta.total_sent_amount;
    }
    state.sent.loading = loading;
}
export function setCanceledPayout_op(state,  [loading,data]) {
    if (loading===false && data.operator_transfer_orders) {
        let temp = data.operator_transfer_orders;
        state.canceled.data = concatData(state.canceled.data,temp) ;
        state.canceled.totals = (data.meta.total_count);
        state.canceled.number_page = data.meta.total_pages;
        state.canceled.current_page = data.meta.current_page;
        state.canceled.next_page = data.meta.next_page;
        state.canceled.prev_page = data.meta.prev_page;
        state.canceled.total_canceled_amount = data.meta.total_canceled_amount;
    }
    state.canceled.loading = loading;
}
export function setFailedPayout_op(state,  [loading,data]) {
    if (loading===false && data.operator_transfer_orders) {
        let temp = data.operator_transfer_orders;
        state.failed.data = concatData(state.failed.data,temp) ;
        state.failed.totals = (data.meta.total_count);
        state.failed.number_page = data.meta.total_pages;
        state.failed.current_page = data.meta.current_page;
        state.failed.next_page = data.meta.next_page;
        state.failed.prev_page = data.meta.prev_page;
        state.failed.total_failed_amount = data.meta.total_failed_amount;
    }
    state.failed.loading = loading;
}
export function setTraitementPayout_op(state,  [loading,data]) {
    if (loading===false && data.operator_transfer_orders) {
        let temp = data.operator_transfer_orders;
        state.traitement.data = concatData(state.traitement.data,temp) ;
        state.traitement.totals = (data.meta.total_count);
        state.traitement.number_page = data.meta.total_pages;
        state.traitement.current_page = data.meta.current_page;
        state.traitement.next_page = data.meta.next_page;
        state.traitement.prev_page = data.meta.prev_page;
        state.traitement.total_treatment_amount = data.meta.total_treatment_amount;
    }
    state.traitement.loading = loading;
}
export function setCreatedPayout_op(state,  [loading,data]) {
    if (loading===false && data.operator_transfer_orders) {
        let temp = data.operator_transfer_orders;
        state.created.data = concatData(state.created.data,temp) ;
        state.created.totals = (data.meta.total_count);
        state.created.number_page = data.meta.total_pages;
        state.created.current_page = data.meta.current_page;
        state.created.next_page = data.meta.next_page;
        state.created.prev_page = data.meta.prev_page;
        state.created.total_created_amount = data.meta.total_created_amount;
    }
    state.created.loading = loading;

}
export function setDeleteUser(state, [loading,data]) {
    if (loading===false && data.users ){
        let temp = data.users;
        state.deleteUser.data = concatData(state.deleteUser.data,temp) ;
        state.deleteUser.totals = (data.meta.total_count);
        state.deleteUser.number_page =data.meta.total_pages;
        state.deleteUser.current_page =data.meta.current_page;
        state.deleteUser.next_page =data.meta.next_page;
        state.deleteUser.prev_page =data.meta.prev_page;
    }
    state.deleteUser.loading = loading;

}

export function setDoubleParticularUser(state, [loading,data]) {
    if (loading===false && data.json) {
        console.log(data)
        state.particular.data =concatData(state.particular.data,data.json);
        state.particular.totals = (data.meta.total_count);
        state.particular.number_page = data.meta.total_pages;
        state.particular.current_page = data.meta.current_page;
        state.particular.next_page = data.meta.next_page;
        state.particular.prev_page = data.meta.prev_page;
    }
    state.particular.loading = loading;
}

export function setDoubleCompanyUser(state, [loading,data]) {
    if (loading===false && data.json) {
        state.company.data =concatData(state.company.data,data.json);
        state.company.totals = (data.meta.total_count);
        state.company.number_page = data.meta.total_pages;
        state.company.current_page = data.meta.current_page;
        state.company.next_page = data.meta.next_page;
        state.company.prev_page = data.meta.prev_page;
    }
    state.company.loading = loading;

}
