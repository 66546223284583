import {
    createRouter,
    createWebHistory,
} from 'vue-router';


import LoginComponent from "@/components/authentication/LoginComponent.vue";

import masterComponent from "@/components/layouts/masterComponent.vue";

import HomeComponent from "@/components/dashboard/HomeComponent.vue";
import DashUserComponent from "@/components/dashboard/DashUserComponent.vue";
import store from "@/store";
import UserProfits from "@/components/audit/UserProfits.vue";
import UserWallet from "@/components/audit/UserWallet.vue";
import UserWalletOvl from "@/components/audit/UserWalletOvl.vue";
import UserProfitsOvl from "@/components/audit/UserProfitsOvl.vue";
import denoncedUsers from "@/components/controller/denoncedUsers.vue";
import userComponent from "@/components/controller/userComponent.vue";
import userDetailsComponent from "@/components/controller/userDetailsComponent.vue";
import businessUser from "@/components/business/businessUser.vue";
import blacklistUser from "@/components/business/blacklistUser.vue";
import adminSystem from "@/components/Juridique/adminSystem.vue";
import userAuditComponent from "@/components/Juridique/userAuditComponent.vue";
import suspectedUsers from "@/components/Juridique/suspectedUsers.vue";
import logsComponent from "@/components/Juridique/logsComponent.vue";
import pendingComponent from "@/components/moderation/pendingComponent.vue";
import assetComponent from "@/components/moderation/assetComponent.vue";
import terminatedComponent from "@/components/moderation/terminatedComponent.vue";
import rejectedComponent from "@/components/moderation/rejectedComponent.vue";
import bnkComponent from "@/components/treasors/bnkComponent.vue";
import mnoComponent from "@/components/treasors/mnoComponent.vue";
import mnobnkcanceledComponent from "@/components/treasors/mnobnkcanceledComponent.vue";
import createdComponent from "@/components/treasors/createdComponent.vue";

import bnkComponentOperator from "@/components/treasors_2/bnkComponent.vue";
import mnoComponentOperator from "@/components/treasors_2/mnoComponent.vue";
import mnobnkcanceledComponentOperator from "@/components/treasors_2/mnobnkcanceledComponent.vue";
import createdComponentOperator from "@/components/treasors_2/createdComponent.vue";
import deleteUser from "@/components/securite/deleteUser.vue";
import duplicatedUser from "@/components/securite/duplicatedUser.vue";

const routes = [

    {
        path:'',
        name:'Login',
        component:LoginComponent,
        meta:{
            requiresGuest : true
        }
    },
    {
        path:"/:pathMatch(.*)",
        name:'notfount',
        component: LoginComponent,
    },
    {
        path:"/",
        name:'app',
        component:masterComponent,
        meta:{
            requiresAuth:true,
            role:"controller"
        },
        children: [
            {
                path:'dashboard',
                name:'app.home',
                component:HomeComponent
            },

            {
                path:'users',
                name:'app.dash_user',
                component:DashUserComponent
            },
            {
                path:'user_profits',
                name:'app.user_profits',
                component:UserProfits
            },
            {
                path:'user_wallets',
                name:'app.user_wallets',
                component:UserWallet
            },
            {
                path:'user_wallets_ovl',
                name:'app.user_wallets_ovl',
                component:UserWalletOvl
            },
            {
                path:'user_profits_ovl',
                name:'app.user_profits_ovl',
                component:UserProfitsOvl
            },
            {
                path:'user_denonced',
                name:'app.user_denonced',
                component:denoncedUsers
            },
            {
                path:'users_in_controller',
                name:'app.users_controller',
                component:userComponent
            },
            {
                path:':id',
                name:'app.details',
                component:userDetailsComponent
            },
            {
                path:'business_user',
                name:'app.business_user',
                component:businessUser
            },
            {
                path:'business_user_can_not_receive_paid',
                name:'app.business_user_blacklist',
                component:blacklistUser
            },
            {
                path:'juridique_admin_sys',
                name:'app.juridique_sys',
                component:adminSystem
            },
            {
                path:'juridique_user_traitement',
                name:'app.juridique_user_traitement',
                component:userAuditComponent
            },
            {
                path:'juridique_user_suspected',
                name:'app.juridique_user_suspected',
                component:suspectedUsers
            },
            {
                path:'logs',
                name:'app.logs',
                component:logsComponent
            },
            {
                path:'pub_pending',
                name:'app.pub_pending',
                component:pendingComponent
            },{
                path:'pub_asset',
                name:'app.pub_asset',
                component:assetComponent
            },{
                path:'pub_terminated',
                name:'app.pub_terminated',
                component:terminatedComponent
            },{
                path:'pub_rejected',
                name:'app.pub_rejected',
                component:rejectedComponent
            },{
                path:'tresorerie_bnk',
                name:'app.tresorerie_bnk',
                component:bnkComponent
            },{
                path:'tresorerie_mno_manage',
                name:'app.tresorerie_mno_manage',
                component:mnoComponent
            },{
                path:'tresorerie_mno_bnk_canceled',
                name:'app.tresorerie_mno_bnk_canceled',
                component:mnobnkcanceledComponent
            },{
                path:'tresorerie_create_payout',
                name:'app.tresorerie_create_payout',
                component:createdComponent
            },
            {
                path:'operator_tresorerie_bnk',
                name:'app.operator_tresorerie_bnk',
                component:bnkComponentOperator
            },{
                path:'operator_tresorerie_mno_manage',
                name:'app.operator_tresorerie_mno_manage',
                component:mnoComponentOperator
            },{
                path:'operator_tresorerie_mno_bnk_canceled',
                name:'app.operator_tresorerie_mno_bnk_canceled',
                component:mnobnkcanceledComponentOperator
            },{
                path:'operator_tresorerie_create_payout',
                name:'app.operator_tresorerie_create_payout',
                component:createdComponentOperator
            },{
                path:'security_del_user',
                name:'app.security_del_user',
                component:deleteUser
            },{
                path:'security_duplicated_user',
                name:'app.security_duplicated_user',
                component:duplicatedUser
            },

        ],
    },
];

const router = createRouter({
    history:createWebHistory(),
    routes
})


router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.state.user.token) {
        window.location.href="/";
    } else if (to.meta.requiresGuest && store.state.user.token) {
        next({name: 'app.home'})
    } else {
        next();
    }

})
export default router;