// import {createStore} from "vuex";

// const store = createStore({
//     state:{
//         user:{
//             token:localStorage.getItem('TOKEN'),
//             data:{}
//         }
//     },
//     getters:{},
//     actions,
//     mutations,
// })


// export default store;


import {createStore} from "vuex";
import state from '../store/vars/state'
import * as actions from '../store/vars/actions'
import * as mutations from '../store/vars/mutations'

const store = createStore({
  state,
  getters: {},
  actions,
  mutations,
})

export default store
