<style>

.table-responsive {
  overflow-y: hidden !important;
}
</style>
<script>
import {
  computed
} from 'vue';

import store from '../../store';
import {checkvalue, exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";
import {formatNumber} from "chart.js/helpers";

export default {
  name: 'UserWallet',
  components: {TableComponent},
  data() {
    return {
      wallets: [],
      walletsDetails: [],
      totalsProfit:0,
      wallets_search_by:'NAME',
      wallets_search_tiping:'',
      elt:"",
    };
  },
  mounted() {
    this.getNextProfit();
  },
  beforeMount(){
    this.getwallets();
  },
  computed:{
    walletsFilter(){
      if (this.wallets_search_tiping.trim().length>0){
        if (this.wallets_search_by=="NAME"){
          return (this.wallets.data.filter((item)=>item.first_name.toLowerCase().includes(this.wallets_search_tiping.toLowerCase()) || item.last_name.toLowerCase().includes(this.wallets_search_tiping.toLowerCase()) || item.gender.toLowerCase().includes(this.wallets_search_tiping.toLowerCase())));
        }
      }
      return this.wallets.data;
    },
  },

  methods: {
    checkvalue,
    formateNumber,
    formatNumber,
    dateFormat(date){
      return moment(date);
    },
    getNextProfit() {
      window.onscroll = () => {
        // let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
        let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
        if (scrollBottom < 1 || scrollBottom <= 0) {
          if (store.state.walletsData.next_page !==null) {
            this.getwallets();
          }
        }
      }
    },
    getwallets() {
      let self = this;
      let page = store.state.walletsData.next_page;
      store.dispatch("getWallet", page);
      setTimeout(()=>{
        self.wallets = computed(() => store.state.walletsData);
      },500)
    },
    exportexls_auth(type,name,fn,dl){
      this.elt = this.$refs.profit_;
      return exportexls_auth(type,name,fn,dl,this.elt)
    }
  },

}

</script>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ $t("dashboard.wallets.title") +" users"}}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">{{ $t("dashboard.wallets.title") +" users"}}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm disabled" type="button" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>

            </div>
          </div>
        </div>
      </div>
      <div class="content-body">

        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="card">
                <div class="card-header  align-items-start pb-0" style="margin-bottom: 3%">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(wallets.total_amount)}} $</h2>
                  <p class="card-text">{{ $t("dashboard.wallets.amount") }}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Greetings Card ends -->

            <!-- Subscribers Chart Card starts -->
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 3%;">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">

                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pocket"><path d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z"></path><polyline points="8 10 12 14 16 10"></polyline></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(wallets.totals)}} </h2>
                  <p class="card-text">{{ $t("dashboard.wallets.number") }}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Subscribers Chart Card ends -->

          </div>
        </section>
        <!-- Multilingual -->



        <!-- Justified Pills Start -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="particulier" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("dashboard.wallets.title") +" user"}}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','particular_d')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="wallets_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">NAME</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="wallets_search_tiping" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="profit_">
                          <thead>
                          <tr>
                            <th class="coll">ID USERS</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>

                            <th class="coll">PHOTO</th>
                            <th class="coll">NAME</th>
                            <th class="coll">Percent</th>
                            <th class="coll">{{$t("users.profits.total")}}</th>
                            <th class="coll">{{$t("users.table_header.blocked")}}</th>
                            <th class="coll">VIP</th>
                          </tr>
                          </thead>
                          <tbody v-if="wallets.total_element!==0">
                          <tr v-for="prof in walletsFilter" v-bind:key="prof.id">
                            <th scope="row">{{prof.id}}</th>
                            <th> {{dateFormat(prof.created_at).format('LLLL')}} </th>
                            <th> {{dateFormat(prof.updated_at).format('LLLL')}} </th>
                            <th>
                              <img :src="prof.profile_image_link" width="32" class="rounded-circle" height="32">
                            </th>
                            <th>
                              {{(prof.gender)?prof.gender:"" }} {{prof.first_name+" "+prof.last_name}}
                            </th>
                            <th>
                              {{ prof.percent }} %
                            </th>
                            <th scope="row">{{prof.total_amount}} $</th>

                            <th scope="row">
                              <span v-if="!prof.blocked" class="badge bg-danger" >{{$t("users.table_content.no")}}</span>
                              <span v-if="prof.blocked" class="badge bg-success" >{{$t("users.table_content.yes")}}</span>
                            </th>
                            <th scope="row">
                              <span v-if="!prof.vip" class="badge bg-danger" >{{$t("users.table_content.no")}}</span>
                              <span v-if="prof.vip" class="badge bg-success" >{{$t("users.table_content.yes")}}</span>
                            </th>
                          </tr>
                          </tbody >
                          <tbody v-else>
                          <tr >
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="wallets.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

