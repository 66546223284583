export function logoutDestroySession(){
    localStorage.clear();
}

export function setLogoutSession(){
    localStorage.clear();
    window.location.href="/";
}

export function checkRole(role){

    try {
        console.log(role)
    }catch (e) {
        console.log(e)
    }
    console.log(role)

}

export function setLogoutSession2(){
    if (localStorage.getItem('EXP')){
        const parts = localStorage.getItem('EXP').split(" ");
        const dateParts = parts[0].split("-");
        const timePart = parts[1];
        // Rearrange the date parts
        const formattedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
        // Combine the formatted date and time
        const formattedDateTime = `${formattedDate} ${timePart}`;
        const currentDate = new Date();
        // Get the individual date and time components
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        // Format the date and time string
        const formattedDateTimeN = `${year}-${month}-${day} ${hours}:${minutes}`;


        const date1 = new Date(formattedDateTimeN);
        const date2 = new Date(formattedDateTime);
        const differenceMs = date2.getTime() - date1.getTime();
        const differenceHours = differenceMs / (1000 * 60 * 60);


        if(differenceHours <= 5){
            localStorage.clear();
            location.reload();
            //window.location.href = '/';

        }
    }
}

