<script>
import {
  computed,
} from 'vue';

import store from '../../store';
import {exportexls_auth} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import Swal from 'sweetalert2';
import moment from "moment";
import {formateNumber} from "../../store/helpers/helper";


export default {
  components: {TableComponent},
  data() {
    return {
      publicity: [],
      publicity_search_by: 'ID',
      publicity_search_tiping: '',
      status: '',
      elt: '',
    };
  },
  mounted() {
    this.getNextPolicy();
  },
  beforeMount() {
    this.getpublicity();
  },

  computed: {
    publicityFilter() {
      if (this.publicity_search_tiping.trim().length > 0) {
        if (this.publicity_search_by == "ID") {
          return this.publicity.data.filter((item) => item.id == this.publicity_search_tiping);
        } else if (this.publicity_search_by == "TITLE") {
          return this.publicity.data.filter((item) => item.title.toLowerCase().includes(this.publicity_search_tiping.toLowerCase()));
        } else if (this.publicity_search_by == "MESSAGE") {
          return this.publicity.data.filter((item) => item.message.toLowerCase().includes(this.publicity_search_tiping.toLowerCase()));
        } else if (this.publicity_search_by == "CIBLE") {
          return this.publicity.data.filter((item) => item.cible_prevision.toLowerCase().includes(this.publicity_search_tiping.toLowerCase()));
        } else if (this.publicity_search_by == "TEMPS") {
          return this.publicity.data.filter((item) => item.time_limit == (this.publicity_search_tiping.toLowerCase()));
        } else if (this.publicity_search_by == "NUM") {
          return this.publicity.data.filter((item) => item.ads_id == this.publicity_search_tiping.toLowerCase());
        }
      }
      return this.publicity.data;
    },
  },

  methods: {
    formateNumber,
    dateFormat(date) {
      return moment(date);
    },
    getNextPolicy() {
      window.onscroll = () => {
        // let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
        let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
        if (scrollBottom < 1 || scrollBottom <= 0) {
          if (store.state.publicity.next_page !==null) {
            this.getpublicity();
          }
        }
      }
    },
    getpublicity(action = false) {
      let self = this;
      let page = 0;
      (!action) ? page = store.state.publicity.next_page : page = store.state.publicity.current_page;
      store.dispatch("getPublicity", ['pending', page]);
      self.publicity = computed(() => store.state.publicity);
    },
    editPublicityStatus(id, action) {
      store.dispatch("updatePubsStatus", [parseInt(id), action])
          .then((response) => {
            this.getpublicity(true)

            Swal.fire({
              icon: "success",
              title: this.$t('alert.success_title'),
              text: this.$t('alert.success_text'),
              customClass: {
                confirmButton: "btn btn-success"
              }
            });
            this.status=""
            return response
          })
          .catch((error) => {
            Swal.fire({
              title: this.$t('alert.error_title'),
              text: this.$t('alert.error_text'),
              icon: "error",
              customClass: {confirmButton: "btn btn-success"}
            });
            return error;
          });
    },
    confrimation(id) {
      Swal.fire({
        title: this.$t('alert.comfirm_title'),
        text: this.$t('alert.comfirm_text'),
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText: this.$t('alert.yes'),
        customClass: {confirmButton: "btn btn-warning", cancelButton: "btn btn-outline-danger ms-1"},
        buttonsStyling: !1,
      }).then((result) => {
        if (result.value) {
          this.editPublicityStatus(id, this.status);
        }
      })
    },

    exportexls_auth(type, name, fn, dl) {
      this.elt = this.$refs.pubs;
      return exportexls_auth(type, name, fn, dl, this.elt)
    }
  },
}

</script>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{$t("dashboard.pub_pending.title")}}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">{{$t("dashboard.pub_pending.sub_title")}}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle disabled" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" :class="{active:this.changePage==='particular'}" href="#" v-on:click="switchBetween(2)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg><span class="align-middle m-lg-1">{{$t("dashboard.particular")}}</span></a>
                <a class="dropdown-item" :class="{active:this.changePage==='company'}" href="#" v-on:click="switchBetween(1)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  <span class="align-middle  m-lg-1">{{$t("dashboard.company")}}</span></a>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <div class="row match-height">
          <!-- Orders Chart Card starts -->
          <div class="col-lg-12 col-sm-6 col-12" >
            <div class="card">
              <div class="card-header align-items-start pb-0" style="margin-bottom: 2%;">
                <div class="avatar bg-light-warning p-50 m-0">
                  <div class="avatar-content">

                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-volume-2"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path></svg>                  </div>
                </div>
                <h2 class="fw-bolder mt-1">{{formateNumber( publicity.totals) }}</h2>
                <p class="card-text">{{ $t("publicity.card_title") }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Justified Pills Start -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <table-component>
                <template v-slot:export>
                  <div class="btn-group">
                    <div class="btn-group">
                      <button v-on:click="exportexls_auth('xlsx','pubs')" type="button"
                              class="btn btn-outline-primary  waves-effect" aria-expanded="false">
                          <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                   height="24" viewBox="0 0 24 24" fill="none"
                                   stroke="currentColor" stroke-width="2"
                                   stroke-linecap="round" stroke-linejoin="round"
                                   class="feather feather-file font-small-4 me-50"><path
                                  d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline
                                  points="13 2 13 9 20 9"></polyline></svg>
                              {{ $t("global_texte.export") }}
                          </span>
                      </button>
                    </div>
                  </div>
                </template>
                <template v-slot:show>
                  <select name="DataTables_Table_0_length" v-model="publicity_search_by"
                          aria-controls="DataTables_Table_0" class="form-select">
                    <option value="ID"><strong>ID</strong></option>
                    <option value="MESSAGE"><strong>{{ $t("publicity.tb_message") }}</strong></option>
                    <option value="TITLE"><strong>{{ $t("publicity.tb_title") }}</strong></option>
                    <option value="CIBLE"><strong>{{ $t("publicity.tb_cible") }}</strong></option>
                    <option value="TEMPS"><strong>{{ $t("publicity.tb_tl") }}</strong></option>
                    <option value="NUM"><strong>{{ $t("publicity.tb_pub_num") }}</strong></option>
                  </select>
                </template>
                <template v-slot:search>
                  <div class="dt-buttons d-inline-flex">
                    <label class="m-xxl-1"><input v-model="publicity_search_tiping" type="search"
                                                      class="form-control" placeholder="Recherchez"
                                                      aria-controls="DataTables_Table_0"></label>
                  </div>
                </template>
                <template v-slot:table>
                  <table class="table table-responsive table-striped mt-2 table-bordered" ref="pubs">
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>ID USERS</th>
                      <th scope="col">{{ $t("report_user.table_crested") }}</th>
                      <th scope="col">{{ $t("report_user.table_updated") }}</th>
                      <th>TITLE</th>
                      <th>MESSAGE</th>
                      <th>{{ $t("publicity.tb_cp") }}</th>
                      <th>{{ $t("publicity.tb_ar") }}</th>
                      <th>{{ $t("publicity.tb_tl") }}</th>
                      <th title="PUB NUM">{{ $t("publicity.tb_pub_num") }}</th>
                      <th>{{ $t("publicity.tb_status") }}</th>
                      <th>{{ $t("publicity.tb_interet") }}</th>
                      <th>{{ $t("publicity.tb_change_status") }}</th>
                    </tr>
                    </thead>
                    <tbody  v-if="publicity.totals !==0">
                    <tr  v-for="prof in publicityFilter" v-bind:key="prof.id">
                      <th scope="row">{{ prof.id }}</th>
                      <th scope="row">{{ prof.user.id }}</th>

                      <th> {{ dateFormat(prof.created_at).format('LLLL') }}</th>
                      <th> {{ dateFormat(prof.updated_at).format('LLLL') }}</th>
                      <th scope="row">{{ prof.title }}</th>
                      <th>{{ prof.message }}</th>
                      <th>{{ prof.cible_prevision }}</th>
                      <th>{{ prof.real_audience }}</th>
                      <th>{{ prof.time_limit }}</th>
                      <th>{{ prof.ads_id }}</th>
                      <th>
                        <span v-if="prof.status==='pending'" class="badge bg-warning">{{
                            $t("publicity.pending")
                          }}</span>

                      </th>
                      <th>{{ prof.interest }} %</th>
                      <th scope="row">
                        <div class="d-flex"
                             v-if="prof.status==='rejected' || prof.status==='pending' || prof.status==='asset'">
                          <div class="mb-1">
                            <select class="form-select" v-model="this.status" v-on:change="confrimation(prof.id)">
                              <option value=""></option>
                              <option value="rejected">{{ $t("publicity.rejected") }}</option>
                              <option value="asset">{{ $t("publicity.asset") }}</option>
                            </select>
                          </div>
                        </div>
                      </th>
                    </tr>

                    </tbody>
                    <tbody v-else>
                      <tr >
                        <td colSpan="13" class="text-center">{{ $t("global_texte.empty_list") }}</td>
                      </tr>
                    </tbody>
                    <tbody>
                    <tr>
                      <td colSpan="13" class="text-center" v-if="this.publicity.loading">
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </template>

              </table-component>
              <!--/ Row grouping -->
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

