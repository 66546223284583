<style>

.table-responsive {
  overflow-y: hidden !important;
}
</style>
<script>
import {
  computed
} from 'vue';

import store from '../../store';
import {checkvalue, exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";
import Swal from 'sweetalert2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  components: {TableComponent,Loading},
  data() {
    return {
      filterChose: 'option1',
      denonced_users: [],
      denonced_usersDetails: [],
      totalsProfit:0,
      denonced_users_search_by:'NOM',
      denonced_users_search_tiping:'',
      elt:"",
      next_page:0,
      dataExport:{
        main_model:"report_user",
        created_at: {
          'start': "",
          'end':"",
        },
        limit: "",
      },
    };
  },
  mounted() {
    this.getNextProfit();
  },
  beforeMount(){
    this.getdenonced_users();
  },
  computed:{
    denonced_usersFilter(){
      if (this.denonced_users_search_tiping.trim().length>0){
        if (this.denonced_users_search_by=="NOM"){
          return (this.denonced_users.data.filter((item)=>item.user_cible.last_name.toLowerCase().includes(this.denonced_users_search_tiping.toLowerCase())));
        }else if(this.denonced_users_search_by=="PRENOM"){
          return (this.denonced_users.data.filter((item)=>item.user_cible.first_name.toLowerCase().includes(this.denonced_users_search_tiping.toLowerCase())));
        }
      }
      return this.denonced_users.data;
    },
  },

  methods: {
    checkvalue,
    formateNumber,
    dateFormat(date){
      return moment(date);
    },
    getExportationData() {
      store.dispatch("getUserAnnonced_filter", [this.dataExport,this.filterChose]);
      this.denonced_users = computed(() => store.state.denonced_usersData);
    },
    getNextProfit() {
      window.onscroll = () => {
        // let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
        let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
        if (scrollBottom < 1 || scrollBottom <= 0) {
          if (store.state.denonced_usersData.next_page !==null) {
            this.getdenonced_users();
          }
        }
      }
    },
    confrimation(id){
      Swal.fire({
        title:  this.$t('alert.comfirm_title'),
        text: this.$t('alert.comfirm_text'),
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText: "Oui, Modifier",
        customClass: { confirmButton: "btn btn-warning", cancelButton: "btn btn-outline-danger ms-1" },
        buttonsStyling: !1,
      }).then((result) => {
        if (result.value) {
          this.changeStatus(id)
        }
      })
    },


    changeStatus(id){
      const params={
        id:id,
        status : this.valueForChanhe,
      };
      store.dispatch("updateAnnoncedUser", params)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title:  this.$t('alert.success_title'),
              text: this.$t('alert.success_text'),
              customClass: {
                confirmButton: "btn btn-success"
              }
            })
            this.getdenonced_users(true);

           return response;
          })
          .catch((error) => {
            Swal.fire({
              title:  this.$t('alert.error_title'),
              text: this.$t('alert.error_text'),
              icon: "error",
              customClass: { confirmButton: "btn btn-success" }
            });
            return error

          })
    },
    filterFieldsEmpty(){
      this.dataExport.limit="";
      this.dataExport.created_at.start=""
      this.dataExport.created_at.end=""
    },
    getdenonced_users(action=false) {

      let self = this;
      let page = 0;
      if (!action){
         page = store.state.denonced_usersData.next_page;
      }else{
        page = store.state.denonced_usersData.current_page;
      }
      store.dispatch("getUserAnnonced", page);
      setTimeout(()=>{
        self.denonced_users = computed(() => store.state.denonced_usersData);
      },500)
    },
    exportexls_auth(type,name,fn,dl){
      this.elt = this.$refs.profit_;
      return exportexls_auth(type,name,fn,dl,this.elt)
    }
  },

}

</script>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <loading v-model:active="this.denonced_users.loading"
             :can-cancel="false"
             :is-full-page="fullPage"/>

    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ $t("dashboard.denonced_users.title") }}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">{{ $t("dashboard.denonced_users.title") }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm disabled" type="button" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>

            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">


            <!-- Subscribers Chart Card starts -->
            <div class="col-lg-12 col-sm-6 col-12">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 3%;">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">

                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(denonced_users.totals)}} </h2>
                  <p class="card-text">{{ $t("dashboard.denonced_users.number") }}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Subscribers Chart Card ends -->

          </div>
        </section>
        <!-- Multilingual -->

        <div class="row d-flex">
          <div class="col-10 text-center float-none">
            <div class="demo-inline-spacing text-center mb-1" style="margin-top: -2%;"><br>
              <div class="form-check form-check-inline" v-on:click="filterFieldsEmpty">
                <input v-model="this.filterChose" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked="" value="option1">
                <label class="form-check-label" for="inlineRadio1">{{$t("search.infsup")}}</label>
              </div>
              <div  class="form-check form-check-inline " v-on:click="filterFieldsEmpty">
                <input v-model="this.filterChose" class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" value="option2">
                <label class="form-check-label" for="inlineRadio2">{{$t("search.supegale")}}</label>
              </div>
            </div>
          </div>
          <div class="col-2">
            <span style="cursor: pointer;" class="float-end" v-on:click="filterFieldsEmpty" >{{$t("search.clean")}}</span>
          </div>
        </div>
        <div class="col-md-12 col-xl-12">
          <div v-if="this.filterChose==='option1'" class="card shadow-none bg-transparent border-danger">
            <div class="card-body">
              <div class="d-flex row">
                <div class="col-2">
                  <select v-on:change="getExportationData()" class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.activate_title")}}</option>
                    <option value="true">Show all</option>
                  </select>
                </div>
                <div class="col-3 d-flex">
                  <span>Limit:</span>
                  <input type="number"  v-model="this.dataExport.limit"  class="form-control form-select-sm flatpickr-basic" >
                </div>
                <div class="col-3 d-flex">
                  <span>Debut:</span>
                  <input type="date" id="fp-default"  v-model="this.dataExport.created_at.start" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
                </div>
                <div class="col-3 d-flex">
                  <span>Fin:</span>
                  <input type="date" v-model="this.dataExport.created_at.end" :min="this.dataExport.created_at.start" id="fp-default" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
                </div>
                <div class="col-1 float-end">
                  <button class="btn-section-block btn-sm btn btn-warning" v-on:click="getExportationData(true)">Searche</button>
                </div>

              </div>
            </div>
          </div>
          <div v-if="this.filterChose==='option2'" class="card shadow-none bg-transparent border-danger">
            <div class="card-body">
              <div class="d-flex row">
                <div class="col-4">
                  <select v-on:change="getExportationData()" class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.activate_title")}}</option>
                    <option value="true">Afficher tous</option>
                  </select>
                </div>

                <div class="col-3 d-flex">
                  <span>{{$t("search.limit")}}:</span>
                  <input type="number" v-model="this.dataExport.limit"  class="form-control form-select-sm flatpickr-basic" >
                </div>


                <div class="col-4 d-flex">
                  <span>{{$t("search.date")}}:</span>
                  <input type="date" format="yyyy-mm-dd" v-model="this.dataExport.created_at.start" id="fp-default" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
                </div>
                <div class="col-1 float-end">
                  <a href="#" class="btn-section-block btn-sm btn btn-warning" :class="{disabled:isButtonop2}" v-on:click="getExportationData()">{{$t("search.search")}}</a>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Justified Pills Start -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="particulier" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("dashboard.denonced_users.title") }}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','particular_d')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="denonced_users_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NOM">{{ $t("report_user.table_surname") }}</option>
                        <option value="PRENOM">{{ $t("report_user.table_first_name") }}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="denonced_users_search_tiping" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="profit_">
                          <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th scope="col">PHOTO</th>
                            <th scope="col">{{ $t("report_user.table_status") }}</th>
                            <th scope="col">{{ $t("report_user.table_surname") }}</th>
                            <th scope="col">{{ $t("report_user.table_first_name") }}</th>
                            <th scope="col">CODE</th>
                            <th scope="col">PHONE</th>
                            <th scope="col">{{ $t("report_user.table_blocked") }}</th>
                            <th scope="col">{{ $t("report_user.table_times") }}</th>
                            <th scope="col">{{ $t("report_user.table_score") }}</th>
                            <th scope="col">{{ $t("report_user.table_total_score") }}</th>
                            <th scope="col">ACTIONS</th>
                          </tr>
                          </thead>
                          <tbody v-if="denonced_users.totals!==0">
                          <tr v-for="part in denonced_usersFilter" :key="part.id">
                            <th scope="row">{{part.id}}</th>
                            <th> {{dateFormat(part.created_at).format('LLLL')}} </th>
                            <th> {{dateFormat(part.updated_at).format('LLLL')}} </th>

                            <th scope="row">
                              <img :src="part.user_cible.profile_image_link" alt="Avatar" class="rounded-circle" width="32" height="32">
                            </th>
                            <th scope="row">
                              <span v-if="part.status==='pending'" class="badge bg-warning">{{ $t("report_user.table_content_pending") }}</span>
                              <span v-if="part.status==='treaty'" class="badge bg-success">{{ $t("report_user.table_content_treaty") }}</span>
                            </th>
                            <th scope="row">
                              {{part.user_cible.last_name}}
                            </th>
                            <th scope="row">{{part.user_cible.first_name}}</th>
                            <th scope="row">{{part.user_cible.country_code}}</th>
                            <th scope="row">{{part.user_cible.phone_number}}</th>
                            <th scope="row">
                              <span v-if="part.user_cible.blocked==false" class="badge bg-warning">{{ $t("report_user.table_content_blocked") }}</span>
                              <span v-if="part.user_cible.blocked==true" class="badge bg-danger">{{ $t("report_user.table_content_unblocked") }}</span>
                            </th>
                            <th scope="row">{{part.reported_number}}</th>
                            <th scope="row">{{part.score}}</th>
                            <th scope="row">{{part.total_score}}</th>
                            <th scope="row">
                              <div class="d-flex">
                                <div class="mb-1">
                                  <select v-model="valueForChanhe" class="form-select" v-on:change="confrimation(part.id)" >
                                    <option value="pending"  :class="{selected:part.status=='pending'}">{{ $t("report_user.table_content_pending") }}</option>
                                    <option value="treaty"   :class="{selected:part.status=='treaty'}">{{ $t("report_user.table_content_treaty") }}</option>
                                  </select>
                                </div>
                              </div>
                            </th>
                          </tr>
                          </tbody >
                          <tbody v-else>
                          <tr >
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="denonced_users.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

