import axiosClient from '../../config/axios/axios';
export function getCurrentUser({commit}, data) {
 try {
     return axiosClient.get('/', data)
         .then(({data}) => {
             commit('', data);
             return data;
         })
 }catch (e) {
     return e;
 }
}

// eslint-disable-next-line no-unused-vars
export function login({commit}, data) {
    try {
        return axiosClient.post('auth/login', data)
    }catch (e) {
        return e;
    }
}

export function session_vux({commit}, response) {
    try {
        let iso_code = (response.currency)?response.currency.iso_code:"USD";
        let iso_id = (response.currency)?response.currency.id:1;
        commit('setUser', {first_name:response.first_name,last_name:response.last_name,role:response.role.name,c_name:iso_code,c_id:iso_id});
        commit('setToken', [response.token,response.exp])
        commit('setOTP', [response.token,true])
    }catch (e) {
        return e;
    }
}
// eslint-disable-next-line no-unused-vars
export function updatePayouts({commit}, [id,params]) {
    try {
        return axiosClient.put(`admin/transfer_orders/${id}`, params)
    }catch (e) {
        return e;
    }
}

export function getStatistiqueUsers({commit}, acount_id) {
    try {
        let url = `admin/statisticals?filter_model=user&account_id=${acount_id}`
        let mutation = '';
        if (acount_id===1){
            mutation = "setStCompany";
        }else if (acount_id===2){
            mutation="setStParticulier";
        }
        return axiosClient.get(url)
            .then((response) => {
            commit(mutation, [false, response.data]);
        }).catch(() => {commit(mutation,[false,[]])})
    }catch (e) {
        return e;
    }
}

export function invitedUser({commit},page){
    try {
        const url = `admin/users?activate=false&page=${page}`;

        commit('setInvitedUser',[true,[]]);

        setTimeout(() => {
            return axiosClient.get(url)
            .then((response) => {
                commit('setInvitedUser',[false,response.data]);

            }).catch((error) => {
                commit('setInvitedUser',[false,[]]);
                return error;
            })
        }, 500);
    }catch (e) {
        return e;
    }
}

// eslint-disable-next-line no-unused-vars
export function getChartDatas({commit},[id=1,activate=false]) {
    try {
        const url = `admin/count_element_by_month?filter_model=user&activate=${activate}&account_id=${id}`;
        // commit('setChartDatas', [true,[]])
        return axiosClient.get(url);
    }catch (e) {
        return e;
    }
}


export function getUserStatistics({commit},acount_id) {
    try {
         let url = `admin/statisticals?filter_model=user&account_id=${acount_id}`;
        if (acount_id === 2){
            commit('setStParticulier', [true, []]);
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setStParticulier', [false, response.data])
                        return response
                    })
                    .catch(() => {
                        commit('setStParticulier',[false,[]])
                    })
            }, 500);

        }else if(acount_id === 1){
            commit('setStCompany', [true, []])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setStCompany', [false, response.data])
                    })
                    .catch(() => {
                        commit('setStCompany',[false,[]])
                    })
            }, 500);
        }
    }catch (e) {
        return e;
    }

}

export function getUsers({commit},[id,page]) {
    try {
        /**
         * if we want get all users rather particular or company
         */
            let url = `admin/users?include=transfer_methods&account_id=${id}&page=${page}`;

            if (id===2){
                commit('setParticular', [true,[]]);
                setTimeout(() => {
                    return axiosClient.get(url)
                        .then((response) => {
                            commit('setParticular', [false, response.data])
                        })

                        // eslint-disable-next-line no-unused-vars
                        .catch((error) => {
                            commit('setParticular',[false,[]]);

                        })
                }, 500);
            }else if(id===1){
                commit('setCompany', [true,[]]);

                setTimeout(() => {
                    return axiosClient.get(url)
                        .then((response) => {
                            commit('setCompany', [false, response.data])
                            return response
                        })
                        .catch((error) => {
                            commit('setCompany',[false,[]])
                            return error
                        })
                }, 500);
            }
    }catch (e) {
        return e;
    }
}

export function invitedUserController({commit},page){
    try {
        const url = `admin/users?activate=false&page=${page}`;
        commit('setInvitedController', [true,[]])
        setTimeout(() => {
            return axiosClient.get(url).then((response) => {
                commit('setInvitedController', [false, response.data])
            }).catch((error) => {
                commit('setInvitedController',[false,[]])
                return error;
            })
        }, 500);
    }catch (e) {
        return e
    }
}
export function getUsersControllers({commit},[id,page]) {
    try {
        /**
         * if we want get all users rather particular or company
         */
            let url = `admin/users?include=transfer_methods&activate=true&account_id=${id}&page=${page}`;

            if (id===2){
                commit('setParticularController', [true,[]]);
                setTimeout(() => {
                    return axiosClient.get(url)
                        .then((response) => {
                            commit('setParticularController', [false, response.data])
                        })

                        // eslint-disable-next-line no-unused-vars
                        .catch((error) => {
                            commit('setParticularController',[false,[]]);
                            return error;
                        })
                }, 500);
            }else if(id===1){
                commit('setCompanyController', [true,[]]);

                setTimeout(() => {
                    return axiosClient.get(url)
                        .then((response) => {
                            commit('setCompanyController', [false, response.data])
                            return response
                        })
                        .catch((error) => {
                            commit('setCompanyController',[false,[]])
                            return error
                        })
                }, 500);
            }else if(id===3){
                invitedUserController({commit},page)
            }
    }catch (e) {
        return e;
    }
}


export function getProfitByStatus({commit}) {
    try {
        commit('setProfitByStatus', [true,[]])
        const url = `admin/profit_amount_sum`;
        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    commit('setProfitByStatus', [false, response.data])
                })
                .catch((error) => {
                    commit('setProfitByStatus', [false,[]])
                    return error;
                })
        }, 500);
    }catch (e) {
        return e;
    }
}


export function getProfits({commit},page=1) {
    commit('setProfits', [true,[]])
    const url = `admin/profits?account_id=1&include=user,call&page=${page}`;
    // const url = `admin/user_with_profits?page=${page}`;
    setTimeout(() => {
        return axiosClient.get(url)
            .then((response) => {
                commit('setProfits', [false, response.data])
            })
            .catch((error) => {
                commit('setProfits', [false,[]])
                return error;
            })
    }, 500);
}
export function getProfitsOvl({commit},page=1) {
    commit('setProfitsOvl', [true,[]])
    const url = `admin/global_operator_profits?include=controller&page=${page}`;
    // const url = `admin/user_with_profits?page=${page}`;

    setTimeout(() => {
        return axiosClient.get(url)
            .then((response) => {
                commit('setProfitsOvl', [false, response.data])
                return response
            })
            .catch((error) => {
                commit('setProfitsOvl', [false,[]])
                return error;
            })
    }, 500);
}

export function getWallet({commit},page=1) {
    try {
        commit('setWallets', [true,[]])
        const url = `admin/user_with_profits?include=user&page=${page}`;
        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    commit('setWallets', [false, response.data]);
                    return response;
                })
                .catch((error) => {
                    commit('setWallets', [false,[]])
                    return error;
                })
        }, 500);
    }catch (e) {
        return e;
    }
}
export function getWalletOvl({commit},page=1) {
    try {
        commit('setWalletsOvl', [true,[]])
        const url = `admin/controller_with_profits?page=${page}`;
        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    commit('setWalletsOvl', [false, response.data]);
                    return response;
                })
                .catch((error) => {
                    commit('setWalletsOvl', [false,[]])
                    return error;
                })
        }, 500);
    }catch (e) {
        return e;
    }
}
export function getUserAnnonced({commit},page=1) {
    try {
        commit('setUserAnnonced', [true,[]])
        const url = `admin/report_users?include=user,user_cible&page=${page}`;
        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    commit('setUserAnnonced', [false, response.data])
                })
                .catch((error) => {
                    commit('setUserAnnonced', [false,[]])
                    return error;
                })
        }, 500);
    }catch (e) {
        return e;
    }
}

// eslint-disable-next-line no-unused-vars
export function updateAnnoncedUser({commit}, params) {
    try {
        const id = params.id;
        params = {
            status:params.status,
        }
        return axiosClient.put(`admin/report_users/${id}`, params)
    }catch (e) {
        return e;
    }
}

// eslint-disable-next-line no-unused-vars
export function updateUserBlocked({commit}, [id,option]) {
    try {
        const formEditQuestion = {
            blocked:option,
        }
        return axiosClient.put(`admin/users/${id}?include=user_point`, formEditQuestion)
    }catch (e) {
       // setLogoutSession();
    }
}

export function getUser({commit}, id) {
    try {
        commit('setUserDetail', [true, []])
        let url = `admin/users/${id}?include=user_point,transfer_order,profit,social_status,country,document_type,account,user_nationalities.nationality,user_spoken_languages.spoken_language,user_manager_nationalities.nationality,user_currency,profits,receive_call_amount`;
        // let url = `admin/statisticals?filter_model=ad,call,payment_method,profit,star_buying,transaction,transfer_order&user_id=${id}`;
        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    commit('setUserDetail', [false, response.data])
                })
                .catch(() => {
                    commit('setUserDetail',[false,[]])
                })
        }, 500);
    }catch (e) {
        return e;
    }
}

export function getUserStatisticsOne({commit}, id) {
    try {
        commit('setUserStatistics', [true, []])
        let url = `admin/statisticals?filter_model=ad,call,payment_method,profit,star_buying,transaction,transfer_order&user_id=${id}`;

        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    commit('setUserStatistics', [false, response.data])
                })
                .catch(() => {
                    commit('setUserStatistics',[false,[]])
                })
        }, 500);
    }catch (e) {
        //setLogoutSession();
    }
}

export function getUserProfitDetails({commit}, id) {
    try {
        commit('setUserProfitDetails', [true, []])
        let url = `admin/user_profit_amount?user_id=${id}`;

        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    commit('setUserProfitDetails', [false, response.data])
                })
                .catch(() => {
                    commit('setUserProfitDetails',[false,[]])
                })
        }, 500);
    }catch (e) {
        return e;
    }
}

// eslint-disable-next-line no-unused-vars
export function updateUserState({commit}, [id,option]) {
    try {
        let formEditQuestion = '';
        const url1 = `admin/users/${id}?include=user_point`;
        if (option==="CAN_BLOCKED_FALSE"){
            formEditQuestion = {
                blocked:false,
            }
        }else if(option==="CAN_BLOCKED_TRUE"){
            formEditQuestion = {
                blocked:true,
            }
        }else if(option==="CAN_TEMPORARE_FALSE"){
            formEditQuestion = {
                blocked_instantly:true,
            }

        }else if(option==="CAN_RECEIVE_PUB_FALSE"){
            formEditQuestion = {
                can_receive_ad:false,
            }
        }else if(option==="CAN_RECEIVE_PUB_TRUE"){
            formEditQuestion = {
                can_receive_ad:true,
            }
        }else if(option==="CAN_RECEIVE_PRO_FALSE"){
            formEditQuestion = {
                can_receive_profit:false,
            }
        }else if(option==="CAN_RECEIVE_PRO_TRUE"){
            formEditQuestion = {
                can_receive_profit:true,
            }
        }else if(option==="VIP_FALSE"){
            formEditQuestion = {
                vip:false,
            }
        }
        else if(option==="VIP_TRUE"){
            formEditQuestion = {
                vip:true,
                max_vip_point:2,
            }
        }else if(option==="CAN_BANISHED"){
            formEditQuestion = {
                banished:true,
            }
        }else if(option==="CAN_BANISHED_ACTIVE"){
            formEditQuestion = {
                banished:false,
            }
        }
        else if(option === "CAN_RECEIVED_FALSE"){
            formEditQuestion = {
                can_receive_profit:false,
            }
        }
        else if(option === "CAN_RECEIVED_TRUE"){
            formEditQuestion = {
                can_receive_profit:true,
            }
        }else if(option === "CAN_SUSPECTED_TRUE"){
            formEditQuestion = {
                suspected:true,
            }
        }else if(option === "CAN_SUSPECTED_FALSE"){
            formEditQuestion = {
                suspected:false,
            }
        }
        return axiosClient.put(url1, formEditQuestion)
    }catch (e) {
        //setLogoutSession();
    }
}

// eslint-disable-next-line no-unused-vars
export function updateUserStateWithMaxVipPoint({commit}, formData) {
    try {
        let id = formData.id;
        let formEditQuestion = {
            vip:true,
            max_vip_point: formData.max_vip_point,
        };
        const url1 = `admin/users/${id}?include=user_point`;

        return axiosClient.put(url1, formEditQuestion)
    }catch (e) {
        //setLogoutSession();
    }
}

export function getUsersCanReceivedNo({commit},[id,page]) {
    try {
        const url = `admin/users?account_id=${id}&can_receive_profit=false&with_deleted=true&page=${page}`;
        if (id==2) {
            commit('setParticularCanNotReceive', [true,[]])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setParticularCanNotReceive', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setParticularCanNotReceive',[false,[]])
                        return error;
                    })
            }, 500);
        }else if(id==1){
            commit('setCompanyCanNotReceive', [true,[]])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setCompanyCanNotReceive', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setCompanyCanNotReceive',[false,[]])
                        return error
                    })
            }, 500);
        }
    }catch (e) {
        return e;
    }
}

export function getRole({commit}){
    try {
        const url = `admin/roles`;
        commit('setRole', [true,[]])
        setTimeout(()=>{
            return axiosClient.get(url)
                .then((response) => {
                    commit('setRole', [false, response.data.roles])
                })
                .catch((error) => {
                    commit('setRole', [false,[]])
                    return error;
                })
        },500)
    }catch (e) {
        return e;
    }
}

export function getSys({commit},page) {
    try {
        const url = `auth/controller_list?include=role&page=${page}`;
        commit('setSysAd', [true,[]])
        setTimeout(()=>{
            return axiosClient.get(url)
                .then((response) => {
                    commit('setSysAd', [false, response.data])
                })
                .catch((error) => {
                    commit('setSysAd', [false,[]])
                    return error;
                })
        },500)
    }catch (e) {
        return e
    }
}

// eslint-disable-next-line no-unused-vars
export function deleteUser({commit}, id) {
    try {
        return axiosClient.delete(`admin/delete_user_account/${id}`)
    }catch (e) {
        return e;
    }
}

export function getSuspectedUsers({commit},[id,page]){
    try {
        let  url = `admin/users?include=transfer_methods&account_id=${id}&activate=true&suspected=true&with_deleted=true&page=${page}`

        if (id===2){
            commit('setSuspectedUsersParticular',[true,[]]);
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setSuspectedUsersParticular', [false, response.data])
                    })
                    .catch((errors) => {
                        commit('setSuspectedUsersParticular',[false,[]]);
                        return errors
                    })
            }, 500);
        }else if (id===1){
            commit('setSuspectedUsersCompany',[true,[]]);
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setSuspectedUsersCompany', [false, response.data])
                    })
                    .catch((errors) => {
                        commit('setSuspectedUsersCompany',[false,[]]);
                        return errors
                    })
            }, 500);
        }
    }catch (e) {
        return e;
    }
}

export function getLogs({commit},page) {
    try {
        const url = `admin/controller_activity_logs?include=controller&page=${page}`;
        commit('setLogs', [true,[]])
        return axiosClient.get(url)
            .then((response) => {
                commit('setLogs', [false, response.data])
            })
            .catch((error) => {
                commit('setLogs', [false,[]])
                return error;
            })
    }catch (e) {
        return e;
    }
}

export function getPublicity({commit},[status,page=1]) {
    try {
        commit('setPublicity', [true,[]])
        const url = `admin/ads?include=user&status=${status}&page${page}`;
        return axiosClient.get(url)
            .then((response) => {
                commit('setPublicity', [false, response.data])
            })
            .catch((error) => {
                commit('setPublicity', [false,[]])
                return error
            })
    }catch (e) {
        return e
    }
}

export function getPublicityAsset({commit},[status,page=1]) {
    try {
        commit('setPublicityAsset', [true,[]])
        const url = `admin/ads?include=user&status=${status}&page${page}`;
        return axiosClient.get(url)
            .then((response) => {
                commit('setPublicityAsset', [false, response.data])
            })
            .catch((error) => {
                commit('setPublicityAsset', [false,[]])
                return error;
            })
    }catch (e) {
        return e
    }
}
export function getPublicityTerminated({commit},[status,page=1]) {
    try {
        commit('setPublicityTerminated', [true,[]])
        const url = `admin/ads?include=user&status=${status}&page${page}`;
        return axiosClient.get(url)
            .then((response) => {
                commit('setPublicityTerminated', [false, response.data])
            })
            .catch((error) => {
                commit('setPublicityTerminated', [false,[]])
                return error
            })
    }catch (e) {
        return e
    }
}
export function getPublicityRejected({commit},[status,page=1]) {
    try {
        commit('setPublicityRejected', [true,[]])
        const url = `admin/ads?include=user&status=${status}&page${page}`;
        return axiosClient.get(url)
            .then((response) => {
                commit('setPublicityRejected', [false, response.data])
            })
            .catch((error) => {
                commit('setPublicityRejected', [false,[]])
                return error
            })
    }catch (e) {
        return e
    }
}


// eslint-disable-next-line no-unused-vars
export function updatePubsStatus({commit}, [id,params]) {
    try {
        const pub_id = id;
        params = {
            status:params,
        }
        return axiosClient.put(`admin/ads/${pub_id}`, params)
    }catch (e) {
        return e
    }
}

export function getTransferOrderTotalAmountByStatus({commit},mode){
    try {
        commit('setTransferOrderTotalAmountByStatus',[true,[]]);
        let url = `admin/transfer_order_total_amount_by_status?mode=${mode}`;
        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    commit('setTransferOrderTotalAmountByStatus', [false, response.data])
                })
                .catch((error) => {
                    commit('setTransferOrderTotalAmountByStatus',[false,[]]);
                    return error
                })
        }, 500);
    }catch (e) {
        return e;
    }
}

export function getMotifsJuridique({commit},page=1) {
    try {
        commit('setMotifsJuridique', [true,[]])
        const url = `admin/legal_reasons?page=${page}`;
        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    commit('setMotifsJuridique', [false, response.data])
                })
                .catch((error) => {
                    commit('setMotifsJuridique', [false,[]])
                    return error;
                })
        }, 500);
    }catch (e) {
        return e;
    }
}


export function getPayouts({commit},[mode,type,page=1]) {
    try {
        let url = `admin/transfer_orders?include=user,legal_reason&mode=${mode}&status=${type}&with_deleted=true&page=${page}`;
        if (mode==="all" && (type==="canceled" || type==="permanently_cancel")) {
            url = `admin/transfer_orders?include=user,reason&status=${type}&with_deleted=true&page=${page}`;
        }
        if(type==="pending"){
            commit('setPendingPayout', [true,[]])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setPendingPayout', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setPendingPayout', [false,[]]);
                        return error
                    })
            }, 500);
        }else if(type==="treatment"){
            commit('setTraitementPayout', [true,[]])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setTraitementPayout', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setTraitementPayout', [false,[]])
                        return error
                    })
            }, 500);
        }else if(type==="sent") {
            commit('setSentPayout', [true, []])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setSentPayout', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setSentPayout', [false, []]);
                        return error
                    })
            }, 500);
        }else if(type==="failed") {
            commit('setFailedPayout', [true, []])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setFailedPayout', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setFailedPayout', [false, []])
                        return error
                    })
            }, 500);
        }else if(type==="canceled") {
            commit('setCanceledPayout', [true, []])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setCanceledPayout', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setCanceledPayout', [false, []])
                        return error;
                    })
            }, 500);
        }else if(type==="permanently_cancel") {
            commit('setPermanentlyPayout', [true, []])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setPermanentlyPayout', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setPermanentlyPayout', [false, []])
                        return error;
                    })
            }, 500);
        }
        else if(type==="created") {
            if (mode==="bank") {

                commit('setCreatedPayout', [true, []])
                setTimeout(() => {
                    return axiosClient.get(url)
                        .then((response) => {
                            commit('setCreatedPayout', [false, response.data])
                        })
                        .catch((error) => {
                            commit('setCreatedPayout', [false, []])
                            return error;
                        })
                }, 500);
            }else{
                commit('setCreatedMobilePayout', [true, []])
                setTimeout(() => {
                    return axiosClient.get(url)
                        .then((response) => {
                            commit('setCreatedMobilePayout', [false, response.data])
                        })
                        .catch((error) => {
                            commit('setCreatedMobilePayout', [false, []])
                            return error;
                        })
                }, 500);
            }

        }
    }catch (e) {
        return e;
    }
}

export function getAllWidrawRequest({commit},[mode='bank',status="pending",page=1]){
    try {
        let url = `admin/manage_operator_transfer_orders?include=legal_reason&mode=${mode}&status=${status}&page=${page}`;
        if (mode==="all" && (status==="canceled" || status==="permanently_cancel")){
            url = `admin/manage_operator_transfer_orders?include=legal_reason&status=${status}&page=${page}`;
        }
        if (status==='pending'){

            commit('setPendingPayout_op', [true, []])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setPendingPayout_op', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setPendingPayout_op', [false, []])
                        return error;
                    })
            }, 500);

        }else if (status==='sent'){
            commit('setSentPayout_op', [true,[]])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setSentPayout_op', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setSentPayout_op', [false,[]])
                        return error;
                    })
            }, 500);

        }else if (status==='canceled'){
            commit('setCanceledPayout_op', [true,[]])

            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setCanceledPayout_op', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setCanceledPayout_op', [false,[]])
                        return error;
                    })
            }, 500);

        }else if(status==="permanently_cancel"){
            commit('setCanceledPayout_op', [true,[]])

            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setCanceledPayout_op', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setCanceledPayout_op', [false,[]])
                        return error
                    })
            }, 500);
        } else if(status==='failed'){
            commit('setFailedPayout_op', [true,[]])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setFailedPayout_op', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setFailedPayout_op', [false,[]])
                        return error
                    })
            }, 500);

        }else if(status==='treatment'){
            commit('setTraitementPayout_op', [true,[]])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setTraitementPayout_op', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setTraitementPayout_op', [false,[]])

                        return error
                    })
            }, 500);

        }else if(status==='created'){

            if (mode==="bank") {

                commit('setCreatedPayout_op', [true, []])
                setTimeout(() => {
                    return axiosClient.get(url)
                        .then((response) => {
                            commit('setCreatedPayout_op', [false, response.data])
                        })
                        .catch((error) => {
                            commit('setCreatedPayout_op', [false, []])
                            return error
                        })
                }, 500);
            }else{
                commit('setCreatedMobilePayout_op', [true, []])
                setTimeout(() => {
                    return axiosClient.get(url)
                        .then((response) => {
                            commit('setCreatedMobilePayout_op', [false, response.data])
                        })
                        .catch((error) => {
                            commit('setCreatedMobilePayout_op', [false, []])
                            return error
                        })
                }, 500);
            }
        }

    }catch (e) {
        return e;
    }
}

// eslint-disable-next-line no-unused-vars
export function updatePaidStatus({commit}, [id,params]) {
    try {
        return axiosClient.put(`admin/manage_operator_transfer_orders/${id}`, params)
    }catch (e) {
        return e;
    }
}

export function getDeleteUsers({commit},[id,page]){
    try {
        let url = `admin/deleted_users?include=transfer_methods&account_id=${id}&page=${page}`;
        if (id ==2) {
            commit('setDeleteUser', [true, []])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setDeleteUser', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setDeleteUser',[false,[]]);
                        return error;
                    })
            }, 500);
        }else if(id==1){
            commit('setCompany', [true, []])

            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setCompany', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setCompany',[false,[]]);
                        return error;
                    })
            }, 500);
        }
    }catch (e) {
        return e;
    }
}

export function getDoubleAcounteUser({commit},[id,page]){
    try {
        let url = `admin/duplicated_account?include=transfer_methods&account_id=${id}&page=${page}`;
        if (id ==2) {
            commit('setDoubleParticularUser',[true,[]]);
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setDoubleParticularUser', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setDoubleParticularUser',[false,[]]);
                        return error;
                    })
            }, 500);
        }else if(id==1){
            commit('setDoubleCompanyUser', [true, []])
            setTimeout(() => {
                return axiosClient.get(url)
                    .then((response) => {
                        commit('setDoubleCompanyUser', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setDoubleCompanyUser',[false,[]]);
                        return error
                    })
            }, 500);
        }
    }catch (e) {
        return e;
    }
}

// eslint-disable-next-line no-unused-vars
export function updateControllerState({commit}, [id,state]) {
    try {
        return axiosClient.put(`auth/change_controller_role/${id}`, {disabled:state})
    }catch (e) {
        return  e;
    }
}

// eslint-disable-next-line no-unused-vars
export function exportData({commit}, [accout_id=2,data,params=[],type]) {
    try {
        let dataFormate= {},urlFormate="";

        dataFormate.main_model = data.main_model;

        if (data.limit){
            dataFormate.limit = data.limit;
        }

        if (params.activate!=="selected"){
            urlFormate += "&activate="+params.activate;
        }
        if (params.vip!=="selected"){
            urlFormate += "&vip="+params.vip;
        }if (params.blocked!=="selected"){
            urlFormate += "&blocked="+params.blocked;
        }
        if (data.created_at.start && data.created_at.end && type==="option1" ){
            dataFormate.created_at = {
                ">=":data.created_at.start,
                "<=":data.created_at.end
            };
        }

        if (data.created_at.start && type==="option2"){
            dataFormate.created_at = {
                ">=":data.created_at.start,
            };
        }
        console.log(dataFormate, urlFormate);
        // eslint-disable-next-line no-unused-vars
        let url = `admin/filter_exportable_data?account_id=${accout_id+''+urlFormate}`;

        if (accout_id===2){
            commit('setParticular_filter', [true,[]]);
            setTimeout(() => {
                return axiosClient.post(url,dataFormate)
                    .then((response) => {
                        commit('setParticular_filter', [false, response.data])
                    })

                    // eslint-disable-next-line no-unused-vars
                    .catch((error) => {
                        commit('setParticular_filter',[false,[]]);

                    })
            }, 500);
        }else if(accout_id===1){
            commit('setCompany_filter', [true,[]]);

            setTimeout(() => {
                return axiosClient.post(url,dataFormate)
                    .then((response) => {
                        commit('setCompany_filter', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setCompany_filter',[false,[]])
                        return error
                    })
            }, 500);
        }
        //return axiosClient.post(url,dataFormat )
    }catch (e) {
        console.log(e)
        return e;
    }
}

export function exportDataProfit({commit}, [dataFor,type]) {
    try {

        let dataFormate= {},urlFormate="";

        dataFormate.main_model = dataFor.main_model;
        if (dataFor.limit){
            dataFormate.limit = dataFor.limit;
        }
        if (dataFor.created_at.start && dataFor.created_at.end && type==="option1" ){
            dataFormate.created_at = {
                ">=":dataFor.created_at.start,
                "<=":dataFor.created_at.end
            };
        }

        if (dataFor.created_at.start && type==="option2"){
            dataFormate.created_at = {
                ">=":dataFor.created_at.start,
            };
        }
        console.log(dataFormate, urlFormate);
        // eslint-disable-next-line no-unused-vars
        let url = `admin/filter_exportable_data?account_id=1&include=user,call`;
        commit('setProfits_filter', [true,[]])
        // const url = `admin/user_with_profits?page=${page}`;
        setTimeout(() => {
            return axiosClient.post(url,dataFormate)
                .then((response) => {
                    commit('setProfits_filter', [false, response.data])
                })
                .catch((error) => {
                    commit('setProfits_filter', [false,[]])
                    return error;
                })
        }, 500);

        //return axiosClient.post(url,dataFormat )
    }catch (e) {
        console.log(e)
        return e;
    }
}
/*
export function exportDataWallet({commit}, [dataFor,type]) {
    try {

        let dataFormate= {},urlFormate="";

        dataFormate.main_model = dataFor.main_model;
        if (dataFor.limit){
            dataFormate.limit = dataFor.limit;
        }
        if (dataFor.created_at.start && dataFor.created_at.end && type==="option1" ){
            dataFormate.created_at = {
                ">=":dataFor.created_at.start,
                "<=":dataFor.created_at.end
            };
        }

        if (dataFor.created_at.start && type==="option2"){
            dataFormate.created_at = {
                ">=":dataFor.created_at.start,
            };
        }
        console.log(dataFormate, urlFormate);
        // eslint-disable-next-line no-unused-vars
        let url = `admin/filter_exportable_data?include=user,call`;
        commit('setProfits_filter', [true,[]])
        // const url = `admin/user_with_profits?page=${page}`;
        commit('setWallets_filter', [true,[]])
        setTimeout(() => {
            return axiosClient.get(url)
                .then((response) => {
                    console.log(response)
                    commit('setWallets_filter', [false, response.data]);
                })
                .catch((error) => {
                    commit('setWallets_filter', [false,[]])
                    return error;
                })
        }, 500);

        //return axiosClient.post(url,dataFormat )
    }catch (e) {
        console.log(e)
        return e;
    }
}
*/



export function exportData_usercontroler({commit},[id=2,data,params=[],type]) {
    try {
        /**
         * if we want get all users rather particular or company
         */
        let dataFormate= {},urlFormate="";

        dataFormate.main_model = data.main_model;

        if (data.limit){
            dataFormate.limit = data.limit;
        }

        if (params.activate!=="selected"){
            urlFormate += "&activate="+params.activate;
        }
        if (params.vip!=="selected"){
            urlFormate += "&vip="+params.vip;
        }if (params.blocked!=="selected"){
            urlFormate += "&blocked="+params.blocked;
        }
        if (data.created_at.start && data.created_at.end && type==="option1" ){
            dataFormate.created_at = {
                ">=":data.created_at.start,
                "<=":data.created_at.end
            };
        }

        if (data.created_at.start && type==="option2"){
            dataFormate.created_at = {
                ">=":data.created_at.start,
            };
        }

        let url = `admin/filter_exportable_data?include=transfer_methods&account_id=${id+''+urlFormate}`;

        if (id===2){
            commit('setParticularController_filter', [true,[]]);
            setTimeout(() => {
                return axiosClient.post(url,dataFormate)
                    .then((response) => {
                        commit('setParticularController_filter', [false, response.data])
                    })

                    // eslint-disable-next-line no-unused-vars
                    .catch((error) => {
                        commit('setParticularController_filter',[false,[]]);
                        return error;
                    })
            }, 500);
        }else if(id===1){
            commit('setCompanyController_filter', [true,[]]);

            setTimeout(() => {
                return axiosClient.post(url,dataFormate)
                    .then((response) => {
                        commit('setCompanyController_filter', [false, response.data])
                        return response
                    })
                    .catch((error) => {
                        commit('setCompanyController_filter',[false,[]])
                        return error
                    })
            }, 500);
        }else if(id===3){
            invitedUserController_filter({commit},[dataFormate,urlFormate])
        }
    }catch (e) {
        return e;
    }
}

export function invitedUserController_filter({commit},[dataFormate,urlFormate]){
    try {
        const url = `admin/filter_exportable_data?activate=false${urlFormate}`;
        commit('setInvitedController_filter', [true,[]])
        setTimeout(() => {
            return axiosClient.post(url,dataFormate).then((response) => {
                console.log(response);
                commit('setInvitedController_filter', [false, response.data])
            }).catch((error) => {
                commit('setInvitedController_filter',[false,[]])
                return error;
            })
        }, 500);
    }catch (e) {
        console.log(e)
        return e
    }
}

export function getUserAnnonced_filter({commit},[data,type]) {
    try {
        let dataFormate= {};
        dataFormate.main_model = data.main_model;

        if (data.limit){
            dataFormate.limit = data.limit;
        }
        if (data.created_at.start && data.created_at.end && type==="option1" ){
            dataFormate.created_at = {
                ">=":data.created_at.start,
                "<=":data.created_at.end
            };
        }

        if (data.created_at.start && type==="option2"){
            dataFormate.created_at = {
                ">=":data.created_at.start,
            };
        }
        commit('setUserAnnonced_filter', [true,[]])
        const url = `admin/filter_exportable_data?include=user,user_cible`;
        setTimeout(() => {
            return axiosClient.post(url,dataFormate)
                .then((response) => {
                    console.log(response);
                    commit('setUserAnnonced_filter', [false, response.data])
                })
                .catch((error) => {
                    commit('setUserAnnonced_filter', [false,[]])
                    return error;
                })
        }, 500);
    }catch (e) {
        return e;
    }
}

export function getUsersCanReceivedNo_filter({commit},[id=2,data,type]) {
    try {
        let dataFormate= {};

        dataFormate.main_model = data.main_model;

        if (data.limit){
            dataFormate.limit = data.limit;
        }


        if (data.created_at.start && data.created_at.end && type==="option1" ){
            dataFormate.created_at = {
                ">=":data.created_at.start,
                "<=":data.created_at.end
            };
        }

        if (data.created_at.start && type==="option2"){
            dataFormate.created_at = {
                ">=":data.created_at.start,
            };
        }
        let url = `admin/filter_exportable_data?account_id=${id}&can_receive_profit=false&with_deleted=true`;
        if (id==2) {
            commit('setParticularCanNotReceive_filter', [true,[]])
            setTimeout(() => {
                return axiosClient.post(url,dataFormate)
                    .then((response) => {
                        console.log(response);
                        commit('setParticularCanNotReceive_filter', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setParticularCanNotReceive_filter',[false,[]])
                        return error;
                    })
            }, 500);
        }else if(id==1){
            commit('setCompanyCanNotReceive_filter', [true,[]])
            setTimeout(() => {
                return axiosClient.post(url,dataFormate)
                    .then((response) => {
                        console.log(response);
                        commit('setCompanyCanNotReceive_filter', [false, response.data])
                    })
                    .catch((error) => {
                        commit('setCompanyCanNotReceive',[false,[]])
                        return error
                    })
            }, 500);
        }
    }catch (e) {
        return e;
    }
}

