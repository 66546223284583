import { createApp } from 'vue'
import App from './App.vue'
import { createI18n, useI18n } from 'vue-i18n'
import routes from './routes/index.js';
import store from "./store/index.js";
import vueSweetAlert from 'vue-sweetalert2';
import Toaster from '@meforma/vue-toaster';

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import { languages,defaultLocale } from "./language/i18n";
const messages = Object.assign(languages)
const i18n = createI18n({
    legacy:false,
    locale:defaultLocale,
    fallbackLocale:'fr',
    messages,
    silentFallbackWarn: true,
    // something vue-i18n options here ...
});


createApp(App,{setup() {
    const {t} = useI18n();
    return {t};
}}).use(i18n).use(vueSweetAlert).use(store).use(routes).use(LoadingPlugin).use(Toaster).mount("#app");

