import * as XLSX from 'xlsx'

// import exportFromJSON from "export-from-json";

import moment from "moment";

import 'moment/locale/fr';
export function   dateFormat(date){
    return moment(date);
}
export function formateNumber(number){
    let arr = [
        {
            'value': 1000000000,
            'letter':'MM',
        },
        {
            'value':1000000,
            'letter':'M',
        },
        {
            'value':1000,
            'letter':'K',
        }
    ];
    var format_number = number;
    arr.forEach(element => {
        if(number>=element.value){
            let res = number / element.value;
            if(res>=1){
                format_number = res.toFixed(3)+" "+element.letter;
                return false;
            }
        }
    });
    return format_number;
}

export function checkvalue(text){
    if (text ==="" || text===null || text==="null" || text ===undefined ){
        return '';
    }else {
        return text;
    }
}

/*
export const excelParser = () => {
  function exportDataFromJSON(data, newFileName, fileExportType) {
    if (!data) return;
    try {
      const fileName = newFileName || "exported-data";
      const exportType = exportFromJSON.types[fileExportType || "xls"];
      exportFromJSON({ data, fileName, exportType });
    } catch (e) {
      throw new Error("Parsing failed!");
    }
  }

  return {
    exportDataFromJSON
  };
};
*/


export const  exportexls_auth = (type,name,fn,dl,elt)=>{
    console.log(type,name,fn,dl,elt)
   var wb = XLSX.utils.table_to_book(elt,{sheet:"Sheet JS"})
    return dl ? XLSX.write({bookType:type,bookSST:true,type:"base64"}):
    XLSX.writeFile(wb,fn || (name+"."+(type || 'xlsx')))
}

export const sampleData = [
    { id: 1, name: "Night", email: "nightprogrammer95@gmail.com" },
    { id: 2, name: "Gautam", email: "mailgautam@test.com" },
    { id: 3, name: "Alex", email: "xalex@testmail.com" },
    { id: 4, name: "Lina", email: "lina@mail.com" },
    { id: 5, name: "Peter", email: "peter.me@test.com" }
  ];


export function calculateAge(birth_date) {
    if (birth_date!==null) {
        let today = new Date();
        let birthDate = new Date(birth_date);
        let age = today.getFullYear() - birthDate.getFullYear();
        // Adjust age if the birth month hasn't occurred yet this year
        if (today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
            return age - 1;
        } else {
            return age;
        }
    } else {
        return null;
    }
}