<style>

.table-responsive {
  overflow-y: hidden !important;
}
</style>
<script>
import {
  computed
} from 'vue';

import store from '../../store';
import {calculateAge, exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";
import Swal from 'sweetalert2';
import { Modal } from '@/store/helpers/bj';
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';




export default {
  components: {Modal,TableComponent,Loading},
  data() {
    return {
      v$: useValidate(),
      filterChose: 'option1',
      isVisible:false,
      account_id:2,
      form_loading:false,
      changePage:'particular',
      moment:moment,
      particular: [],
      company: [],
      invited: [],
      params:{
        activate:"selected",
        vip:"selected",
        blocked:"selected",
      },
      dataExport:{
        main_model:"user",
        created_at: {
          'start': "",
          'end':"",
        },
        limit: "",
      },
      particular_search:'',
      searchBy:'NAME',
      length_particular:0,
      company_search_by:'NAME',
      company_tiping:'',
      invited_search_by:'NUM',
      invited_tiping:'',
      length_company:0,
      stParticular:[],
      stCompany:[],
      elt:"",
      state_choice:'p',
      next_pagep:0,
      next_pagec:0,
      next_pagei:0,
      formData:{
        id:"",
        max_vip_point:"",
      }
    };
  },
  mounted() {

    window.onscroll = () => {
      let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
      if (scrollBottom < 1 || scrollBottom <= 0 ) {
        if ((this.changePage==="particular" && store.state.particular_co.next_page !== null)) {
          this.getUsers(2)
        }else  if ((this.changePage==="company" && store.state.company_co.next_page !== null)) {
          this.getUsers(1)
        }else  if ((this.changePage==="invited" && store.state.invited_co.next_page !== null)) {
          this.getUsers(3)
        }
      }
    }

  },
  beforeMount() {
    this.getUsers(2);
    this.getUserStatistics(2)
  },
  computed:{
    particulierFilter(){
      if (this.particular_search.trim().length>0){
        if (this.searchBy=="NAME"){
          return (this.particular.data.filter((item)=>item.last_name.toLowerCase().includes(this.particular_search.toLowerCase())));
        }else if(this.searchBy=="LAST_NAME"){
          return (this.particular.data.filter((item)=>item.first_name.toLowerCase().includes(this.particular_search.toLowerCase())));
        }
      }
      return this.particular.data;
    },
    companyFilter(){
      if (this.company_tiping.trim().length>0){
        if (this.company_search_by=="NAME"){
          return (this.company.data.filter((item)=>item.enterprise_name.toLowerCase().includes(this.company_tiping.toLowerCase())));
        }else if(this.company_search_by=="ACR"){
          return (this.company.data.filter((item)=>item.acronym.toLowerCase().includes(this.company_tiping.toLowerCase())));
        }
      }
      return this.company.data;
    },
    invitedFilter(){
      if (this.invited_tiping.trim().length>0){
        if (this.invited_search_by=="NUM"){
          return (this.invited.data.filter((item)=>item.phone_number.toLowerCase().includes(this.invited_tiping.toLowerCase())));
        }else if(this.invited_search_by=="CODE"){
          return (this.invited.data.filter((item)=>item.country_code.toLowerCase().includes(this.invited_tiping.toLowerCase())));
        }
      }
      return this.invited.data;
    },

    isDisabled(){
      if (this.params.activate==='selected' || this.params.activate==='false'){
        return true;
      }
      return false
    },isButton(){
      if (!this.dataExport.limit && !this.dataExport.created_at.start!=="" && !this.dataExport.created_at.end!=="" ){
        return true;
      }
      return false
    },isButtonop2(){
      if (!this.dataExport.limit && !this.dataExport.created_at.start ){
        return true;
      }
      return false
    },
  },
  methods: {
    calculateAge,
    formateNumber,
    dateFormat(date){
      return moment(date);
    },

    filterFieldsEmpty(){
      this.params.activate="selected";
      this.params.vip="selected";
      this.params.blocked="selected";
      this.dataExport.limit="";
      this.dataExport.created_at.start=""
      this.dataExport.created_at.end=""
    },
    getExportationData() {
      this.params.activate=true;
      store.dispatch("exportData_usercontroler", [this.account_id,this.dataExport,this.params,this.filterChose]);
      if(this.changePage==='particular'){
        this.account_id= 2;
        self.particular = computed(() => store.state.particular_co);
      }else if(this.changePage==="company"){
        this.account_id= 1;
        self.company = computed(() => store.state.company_co);
      }else if(this.changePage==="invited"){
        this.account_id= 3;
        self.invited = computed(() => store.state.invited_co);
      }
    },

    showAddFrom(data){
      this.formData.id = data.id;
      this.formData.max_vip_point = data.max_vip_point;
      this.isVisible =true;
    },

    getUsers(id,action=false) {
      let self = this;
      let page = 0;
      if (!action ) {
        if (id ===2 ) {
          page = store.state.particular_co.next_page;
        } else if (id === 1) {
          page = store.state.company_co.next_page;
        }else if (id === 3) {
          page = store.state.invited_co.next_page;
        }
      }else{
        if (id ===2 ) {
          page = store.state.particular_co.current_page;
        } else if (id === 1) {
          page = store.state.company_co.current_page;
        }else if(id===3){
          page = store.state.invited_co.current_page;
        }
      }
      store.dispatch("getUsersControllers", [id,page]);
      if(id===2){
        self.particular = computed(() => store.state.particular_co);
      }else if(id===1){
        self.company = computed(() => store.state.company_co);
      }else if(id===3){
        self.invited = computed(() => store.state.invited_co);
      }
    },
    getUserStatistics(account_id){
      store.dispatch("getUserStatistics",account_id);
      if (account_id===1){
        this.stCompany = computed(() => store.state.statistics_co);
      }else if(account_id===2){
        this.stParticular = computed(() => store.state.statistics_pa);
      }
    },

    switchBetween(id){
      this.account_id = id;
      if (id===1){
        this.changePage = 'company';
        this.getUserStatistics(id);
        this.getUsers(id);
      }else if (id===2){
        this.changePage = 'particular';
        this.getUserStatistics(id);
        this.getUsers(id);
      }else if(id===3){
        this.changePage ="invited";
        this.getUsers(id);
      }
    },

    checkvalue(text){
      if (text ==="" || text===null || text==="null" || text ===undefined ){
        return '';
      }else {
        return text;
      }
    },


    updateUserStateWithMaxVipPoint(){
      this.v$.$validate() // checks all inputs
      this.form_loading= true;
      if (!this.v$.$error) {
        store.dispatch("updateUserStateWithMaxVipPoint", this.formData)
            .then((response) => {

             /* Swal.fire({
                icon: "success",
                title: "Modification",
                text: "Modification effectué avec succès!",
                customClass: {
                  confirmButton: "btn btn-success"
                }

              });*/

              if (this.changePage === 'particular') {
                this.getUserStatistics(2)
                this.getUsers(2, true);
              } else {
                this.getUsers(1, true);
                this.getUserStatistics(1)
              }
              this.isVisible = false;
              /*setTimeout(() => {

                this.getExportationData();
              }, 2000);*/

              this.$toast.success("Modification effectué avec succès!");

              return response;
            })
            .catch((error) => {
             /* Swal.fire({
                title: "Erreur",
                text: "Une erreur est survenue",
                icon: "error",
                customClass: {confirmButton: "btn btn-success"}
              });*/
              return error;
            });
      } else {
        this.form_loading= false;
        this.$toast.error(this.$t('Le champ est obligatoir'));
      }
    },

    editState(data,op,id){
      setTimeout(()=>{
        if(data.length){
          const objectToEdit = data.find(obj => obj.id === id);
          if (objectToEdit) {
            objectToEdit.vip = op;
          }
        }
      },1000)
    },

    editUserVipState(id,action){
      store.dispatch("updateUserState", [parseInt(id),action])
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
              if (this.changePage === 'particular') {
                this.getUserStatistics(2)

                this.getUsers(2, true);

                this.editState(this.particular.data,action,id);

              } else {
                this.getUsers(1, true);
                this.getUserStatistics(1)
                this.editState(this.company.data,action,id);
              }
              /*this.isVisible = false;
              this.getExportationData();*/
          })
          .catch((error) => {
           /* Swal.fire({
              title: "Erreur",
              text: "Une erreur est survenue",
              icon: "error",
              customClass: {confirmButton: "btn btn-success"}
            });*/
            return error;
          });

    },
    confrimation(id,action){
      Swal.fire({
        title: 'Confirmation',
        text: `Voullez-vous vraiment effectué cette opération cet utilisateur ?`,
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText: `Oui, éffectuer`,
        customClass: { confirmButton: "btn btn-warning", cancelButton: "btn btn-outline-danger ms-1" },
        buttonsStyling: !1,
      }).then((result) => {
        if (result.value) {
          this.editUserVipState(id,action);
        }
      })
    },
    validations() {
      return {
        formData: {
          max_vip_point: { required },
        },
      }
    },
    exportexls_auth(type,name,fn,dl){
      if (name==="company_d") {
        this.elt = this.$refs.company_d;
      }else if (name==="particular_d"){
        this.elt = this.$refs.particular_d;
      }else if (name==="guess_d"){
        this.elt = this.$refs.guess_d;
      }
      return exportexls_auth(type,name,fn,dl,this.elt)
    }
  },

}

</script>

<template>
  <!-- BEGIN: Content-->
  <Modal v-model:visible="isVisible" :okButton="{text: 'Submit',modalClass:'btn btn-primary',onclick: () => {this.updateUserStateWithMaxVipPoint()}, loading:false,color:'red'}" :animation="true" title="Vip point">
    <div>
      <div class="mb-1">
        <label class="form-label" for="basic-default-email1">Max vip point</label>
        <input v-model="this.formData.max_vip_point" required class="form-control" type="number" id="fr_body"/>
      </div>
    </div>
  </Modal>
  <div class="app-content content ">
    <loading v-model:active="this.particular.loading"
             :can-cancel="false"
             :is-full-page="fullPage"/>
    <loading v-model:active="this.company.loading"
             :can-cancel="false"
             :is-full-page="fullPage"/>
    <loading v-model:active="this.invited.loading"
             :can-cancel="false"
             :is-full-page="fullPage"/>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{$t("dashboard.users.all_users")}}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">Activés
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" :class="{active:this.changePage==='particular'}" href="#" v-on:click="switchBetween(2)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg><span class="align-middle m-lg-1">{{$t("dashboard.particular")}}</span></a>
                <a class="dropdown-item" :class="{active:this.changePage==='company'}" href="#" v-on:click="switchBetween(1)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  <span class="align-middle  m-lg-1">{{$t("dashboard.company")}}</span></a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-lg-3 col-sm-6 col-12" v-if="this.changePage!=='invited'">
              <div class="card">
                <div class="card-header  align-items-start pb-0" style="margin-bottom: 10%">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{ (this.changePage==='particular')?formateNumber(this.stParticular.total_element):formateNumber(this.stCompany.total_element) }}</h2>
                  <p class="card-text">{{$t("dashboard.users.all_users")}}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Greetings Card ends -->




            <!-- Subscribers Chart Card starts -->
            <div class="col-lg-3 col-sm-6 col-12" v-if="this.changePage!=='invited'">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{(this.changePage==='particular')?formateNumber(this.stParticular.vip):formateNumber(this.stCompany.vip)}}</h2>
                  <p class="card-text">{{$t("dashboard.users.users_vip")}}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Subscribers Chart Card ends -->

            <!-- Orders Chart Card starts -->
            <div class="col-lg-3 col-sm-6 col-12" v-if="this.changePage!=='invited'">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-warning p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{(this.changePage==='particular')?formateNumber(this.stParticular.blocked):formateNumber(this.stCompany.blocked)}}</h2>
                  <p class="card-text">{{$t("dashboard.users.users_blocked")}}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12" v-if="this.changePage!=='invited'">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-warning p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{(this.changePage==='particular')?formateNumber(this.stParticular.activate):formateNumber(this.stCompany.activate)}}</h2>
                  <p class="card-text">{{$t("dashboard.users.users_active")}}</p>
                </div>
              </div>
            </div>

          </div>
        </section>
        <!-- Multilingual -->


        <div class="row d-flex">
          <div class="col-10 text-center float-none">
            <div class="demo-inline-spacing text-center mb-1" style="margin-top: -2%;"><br>
              <div class="form-check form-check-inline" v-on:click="filterFieldsEmpty">
                <input v-model="this.filterChose" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked="" value="option1">
                <label class="form-check-label" for="inlineRadio1">{{$t("search.infsup")}}</label>
              </div>
              <div  class="form-check form-check-inline " v-on:click="filterFieldsEmpty">
                <input v-model="this.filterChose" class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" value="option2">
                <label class="form-check-label" for="inlineRadio2">{{$t("search.supegale")}}</label>
              </div>
            </div>
          </div>
          <div class="col-2">
            <span style="cursor: pointer;" class="float-end" v-on:click="filterFieldsEmpty" >{{$t("search.clean")}}</span>
          </div>
        </div>
        <div class="col-md-12 col-xl-12">
          <div v-if="this.filterChose==='option1'" class="card shadow-none bg-transparent border-danger">
            <div class="card-body">
              <div class="d-flex row">
                <div class="col-2" v-if="this.changePage!=='invited'">
                  <select  v-model="params.activate" v-on:change="getExportationData()" class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.activate_title")}}</option>
                    <option value="true">{{$t("filter_zone.activate")}}</option>
                  </select>
                </div>
                <div class="col-1" v-if="this.changePage!=='invited'">
                  <select v-model="params.vip" v-on:change="getExportationData()"  class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.vip_title")}}</option>
                    <option value="true">{{$t("filter_zone.vip")}}</option>
                    <option value="false">{{$t("filter_zone.vip_no")}}</option>
                  </select>
                </div>

                <div class="col-2" v-if="this.changePage!=='invited'">
                  <select v-model="params.blocked" v-on:change="getExportationData()"  class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.blocked_title")}}</option>
                    <option value="true">{{$t("filter_zone.blocked")}}</option>
                    <option value="false">{{$t("filter_zone.blocked_no")}}</option>
                  </select>
                </div>
                <div class="col-3" v-if="this.changePage==='invited'">
                  <select v-model="params.blocked" v-on:change="getExportationData()"  class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.blocked_title")}}</option>
                    <option value="true">{{$t("filter_zone.blocked")}}</option>
                    <option value="false">{{$t("filter_zone.blocked_no")}}</option>
                  </select>
                </div>
                <div class="col-4 d-flex"  v-if="this.changePage==='invited'">
                  <span>Limit:</span>
                  <input type="number"   v-model="this.dataExport.limit"  class="form-control form-select-sm flatpickr-basic" >
                </div>
                <div class="col-2 d-flex"  v-if="this.changePage!=='invited'">
                  <span>Limit:</span>
                  <input type="number"   v-model="this.dataExport.limit"  class="form-control form-select-sm flatpickr-basic" >
                </div>
                <div class="col-2 d-flex">
                  <span>Debut:</span>
                  <input type="date" id="fp-default"  v-model="this.dataExport.created_at.start" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
                </div>
                <div class="col-2 d-flex">
                  <span>Fin:</span>
                  <input type="date" v-model="this.dataExport.created_at.end" :min="this.dataExport.created_at.start" id="fp-default" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
                </div>
                <div class="col-1 float-end">
                  <button class="btn-section-block btn-sm btn btn-warning" :class="{disabled:isButton}" v-on:click="getExportationData()">Searche</button>
                </div>

              </div>
            </div>
          </div>
          <div v-if="this.filterChose==='option2'" class="card shadow-none bg-transparent border-danger">
            <div class="card-body">
              <div class="d-flex row">
                <div class="col-2"  v-if="this.changePage!=='invited'">
                  <select v-model="params.activate" v-on:change="getExportationData()" class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.activate_title")}}</option>
                    <option value="true">{{$t("filter_zone.activate")}}</option>
                  </select>
                </div>
                <div class="col-2"  v-if="this.changePage!=='invited'">
                  <select v-model="params.vip" v-on:change="getExportationData()" class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.vip_title")}}</option>
                    <option value="true">{{$t("filter_zone.vip")}}</option>
                    <option value="false">{{$t("filter_zone.vip_no")}}</option>
                  </select>
                </div>
                <div class="col-2" v-if="this.changePage!=='invited'">
                  <select v-model="params.blocked" v-on:change="getExportationData()" class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.blocked_title")}}</option>
                    <option value="true">{{$t("filter_zone.blocked")}}</option>
                    <option value="false">{{$t("filter_zone.blocked_no")}}</option>
                  </select>
                </div>
                <div class="col-4" v-if="this.changePage==='invited'">
                  <select v-model="params.blocked" v-on:change="getExportationData()" class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.blocked_title")}}</option>
                    <option value="true">{{$t("filter_zone.blocked")}}</option>
                    <option value="false">{{$t("filter_zone.blocked_no")}}</option>
                  </select>
                </div>
                <div class="col-3 d-flex" v-if="this.changePage!=='invited'">
                  <span>{{$t("search.limit")}}:</span>
                  <input type="number" v-model="this.dataExport.limit"  class="form-control form-select-sm flatpickr-basic" >
                </div><div class="col-4 d-flex"  v-if="this.changePage==='invited'">
                <span>{{$t("search.limit")}}:</span>
                <input type="number" v-model="this.dataExport.limit"  class="form-control form-select-sm flatpickr-basic" >
              </div>


                <div class="col-2 d-flex"  v-if="this.changePage!=='invited'">
                  <span>{{$t("search.date")}}:</span>
                  <input type="date" format="yyyy-mm-dd" v-model="this.dataExport.created_at.start" id="fp-default" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
                </div> <div class="col-3 d-flex"  v-if="this.changePage==='invited'">
                <span>{{$t("search.date")}}:</span>
                <input type="date" format="yyyy-mm-dd" v-model="this.dataExport.created_at.start" id="fp-default" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
              </div>
                <div class="col-1 float-end">
                  <a href="#" class="btn-section-block btn-sm btn btn-warning" :class="{disabled:isButtonop2}" v-on:click="getExportationData()">{{$t("search.search")}}</a>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Justified Pills Start -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div role="tabpanel" v-if="this.changePage==='particular'" class="tab-pane active" id="particulier" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("dashboard.particular") }}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','particular_d')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="searchBy" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">{{$t("users.table_header.surname")}}</option>
                        <option value="LAST_NAME">{{$t("users.table_header.first_name")}}</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="particular_search" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="particular_d">
                          <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th scope="col">PHOTO</th>
                            <th scope="col">TERMINAL</th>
                            <th scope="col">AGE</th>
                            <th scope="col">GENDER</th>
                            <th scope="col">{{$t("users.table_header.surname")}}</th>
                            <th scope="col">{{$t("users.table_header.first_name")}}</th>
                            <th scope="col">PSEUDO</th>
                            <th scope="col">VIP POINT</th>
                            <th scope="col">{{$t("users.table_header.active")}}</th>
                            <th scope="col">{{$t("users.table_header.blocked")}}</th>
                            <th scope="col">{{$t("users.table_header.vip")}}</th>
                            <th scope="col">ACTION</th>
                          </tr>
                          </thead>
                          <tbody v-if="particular.totals!==0">
                          <tr v-for="pa in particulierFilter" :key="pa.id">
                            <td scope="row" >{{pa.id}}</td>
                            <td>  {{dateFormat(pa.created_at).format('LLLL')}} </td>
                            <td> {{dateFormat(pa.updated_at).format('LLLL')}} </td>
                            <td>
                              <img :src="pa.profile_image_link" width="32" class="rounded-circle" height="32">
                            </td>
                            <td> {{this.checkvalue(pa.terminal)}}</td>
                            <td> {{calculateAge((pa.birth_date)) +" "+ $t("home.year_old")}}</td>
                            <td> {{this.checkvalue(pa.gender)}}</td>
                            <td>{{this.checkvalue(pa.last_name)}}</td>
                            <td>{{this.checkvalue(pa.first_name)}}</td>
                            <td>{{this.checkvalue(pa.pseudo)}}</td>
                            <td>{{(pa.max_vip_point)}}</td>
                            <td>
                              <span v-if="!pa.activate" class="badge bg-danger" >{{$t("users.table_content.unverified")}}</span>
                              <span v-if="pa.activate" class="badge bg-success" >{{$t("users.table_content.verified")}}</span>
                            </td>
                            <td>
                              <span v-if="!pa.blocked" class="badge bg-success" >{{$t("users.table_content.no")}}</span>
                              <span v-if="pa.blocked" class="badge bg-danger" >{{$t("users.table_content.yes")}}</span>
                            </td>
                            <td>
                              <span v-if="!pa.vip" class="badge bg-danger" >{{$t("users.table_content.no")}}</span>
                              <span v-if="pa.vip" class="badge bg-success" >{{$t("users.table_content.yes")}}</span>
                            </td>
                            <td>

                              <button type="button" v-if="!pa.vip" class="btn btn-success btn-sm" v-on:click="showAddFrom({id:pa.id,max_vip_point: pa.max_vip_point,vip:true})" title="Activer vip pour ce utilisateur">vip</button>
                              <button type="button" v-if="pa.vip" class="btn btn-danger btn-sm" v-on:click="confrimation(pa.id,'VIP_FALSE')" title="Désactiver vip pour ce utilisateur">vip</button>
                            </td>
                          </tr>

                          </tbody >
                          <tbody v-else>
                          <tr >
                            <td colspan="15" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
<!--                          <tbody v-if="particular.loading">
                          <tr>
                            <td colspan="15" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>-->
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
                <div role="tabpanel" v-if="this.changePage==='company'" class="tab-pane active" id="company" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("dashboard.company") }}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','company_d')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="company_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">COMAPNY NAME</option>
                        <option value="ACR">ACRONYM</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="company_tiping" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="company_d">
                          <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th scope="col">PHOTO</th>
                            <th scope="col">TERMINAL</th>
                            <th scope="col">COMPANY NAME</th>
                            <th scope="col">ACRONYM</th>
                            <th scope="col">VIP POINT</th>
                            <th scope="col">{{$t("users.table_header.active")}}</th>
                            <th scope="col">{{$t("users.table_header.blocked")}}</th>
                            <th scope="col">{{$t("users.table_header.vip")}}</th>
                            <th scope="col">ACTION</th>
                          </tr>
                          </thead>
                          <tbody v-if="company.totals!==0">
                          <tr v-for="co in companyFilter" :key="co.id">
                            <td scope="row" >{{co.id}}</td>
                            <td>  {{dateFormat(co.created_at).format('LLLL')}} </td>
                            <td> {{dateFormat(co.updated_at).format('LLLL')}} </td>
                            <td>
                              <img :src="co.profile_image_link" width="32" class="rounded-circle" height="32">
                            </td>
                            <td> {{this.checkvalue(co.terminal)}}</td>
                            <td>{{this.checkvalue(co.enterprise_name)}}</td>
                            <td>{{this.checkvalue(co.acronym)}}</td>
                            <td>{{this.checkvalue(co.max_vip_point)}}</td>
                            <td>
                              <span v-if="!co.activate" class="badge bg-danger" >{{$t("users.table_content.unverified")}}</span>
                              <span v-if="co.activate" class="badge bg-success" >{{$t("users.table_content.verified")}}</span>
                            </td>
                            <td>
                              <span v-if="!co.blocked" class="badge bg-success" >{{$t("users.table_content.no")}}</span>
                              <span v-if="co.blocked" class="badge bg-danger" >{{$t("users.table_content.yes")}}</span>
                            </td>

                            <td>
                              <span v-if="!co.vip" class="badge bg-danger" >{{$t("users.table_content.no")}}</span>
                              <span v-if="co.vip" class="badge bg-success" >{{$t("users.table_content.yes")}}</span>
                            </td>
                            <td>
                              <button type="button" v-if="!co.vip" class="btn btn-success btn-sm" v-on:click="showAddFrom({id:co.id,max_vip_point: co.max_vip_point,vip:true})" title="Activer vip pour cet entreprise">vip</button>
                              <button type="button" v-if="co.vip" class="btn btn-danger btn-sm" v-on:click="confrimation(co.id,'VIP_FALSE')" title="Désactiver vip pour ce utilisateur">vip</button>
                            </td>
                          </tr>

                          </tbody >
                          <tbody v-else>
                          <tr >
                            <td colspan="13" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
<!--                          <tbody v-if="company.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>-->
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

