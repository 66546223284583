<script>
import Chart from 'chart.js/auto';

import store from '../../store';

export default{
  data() {

    return {

    }
  },
  mounted() {
    this.getChartDatas();
  },

  methods: {
    getChartDatas(){
      store.dispatch("getChartDatas",[1,false])
          .then((response) => {
            this.contructChart(Object.values(response.data.User),Object.keys(response.data.User))
          })
          .catch((error) => {
            return error
          });

    },

    contructChart(c_data,c_labels){
      const  ctx = document.getElementById("myChartnoactive");
      const data = {
        labels:c_labels,
        datasets:[{
          label:"Company: account not activated statistic",
          data:c_data,
          backgroundColor:[
            "rgb(255,99,132)",
            "rgb(54,162,235)",
            "rgb(255,205,86)",
            "rgb(222,104,86)",
            "rgb(255,255,46)",
            "rgb(250,50,36)",
            "rgb(255,215,56)",
            "rgb(255,245,26)",
            "rgb(252,202,36)",
            "rgb(253,276,76)",
            "rgb(232,85,16)",
            "rgb(249,219,35)",
          ],
          hoverOffset:5
        }]
      };
      const myChart = new Chart(ctx,{
        type:"line",
        data:data,
      });
      myChart
    }
  },
}


</script>
<template>
  <div>
    <canvas id="myChartnoactive"></canvas>
  </div>
</template>
