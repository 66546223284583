export default {
    user: {
        //   token: 234,
        exp: localStorage.getItem('EXP'),
        token: localStorage.getItem('TOKEN'),
        otp: localStorage.getItem('OTP'),
        //otp: true,
        data: localStorage.getItem('USER'),
        role: localStorage.getItem('ROLE')
    },
    histories: {
        loading: false,
        data: [],
        received: 0, totals: 0, number_page: 1, current_page: 1, next_page: null, prev_page: null,
    },
    statistics_pa:{
    loading: false,
    activate :0,
    status :0,
    visible_status :0,
    vip :0,
    blocked :0,
    blocked_instantly :0,
    banished :0,
    can_receive_profit:0,
    confirmed :0,
    total_element :0
    },
    statistics_co:{
        loading: false,
        activate :0,
        status :0,
        visible_status :0,
        vip :0,
        blocked :0,
        blocked_instantly :0,
        banished :0,
        can_receive_profit:0,
        confirmed :0,
        total_element :0
    },

    user_invited:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },

    particular:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    particularcnr:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    companycnr:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    particular_co:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    company:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    company_co:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    invited_co:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    profitsDetails:{
      loading:false,
      data:[],
    },
    profits: {
        loading: false,
        data:[],
        totals:0,number_page:1,current_page:1,next_page:null,prev_page:null,total_beneficiaries:'00',total_amount:"00",total_pending_beneficiaries:"00",total_treaty_beneficiaries:"00"
    },
    profits_ovl: {
        loading: false,
        data:[],
        totals:0,number_page:1,current_page:1,next_page:null,prev_page:null,total_beneficiaries:'00',total_amount:"00",total_pending_beneficiaries:"00",total_treaty_beneficiaries:"00"
    },
    walletsData: {
        loading: false,
        data:[],
        totals:0,number_page:1,current_page:1,next_page:null,prev_page:null,total_beneficiaries:'00',total_amount:"00",total_pending_beneficiaries:"00",total_treaty_beneficiaries:"00"
    },
    walletsData_ovl: {
        loading: false,
        data:[],
        totals:0,number_page:1,current_page:1,next_page:null,prev_page:null,total_beneficiaries:'00',total_amount:"00",total_pending_beneficiaries:"00",total_treaty_beneficiaries:"00"
    },

    denonced_usersData:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    logs:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },

    userDetail: {
        loading: false,
        data: [],
        point: [],
        country: [],
        user_nationalities:[],
        account:[],
        social_networks:[],
    },
    all_one_user_details:{
        loading: false,
        data:{
            adds:{
                pending:0,
                rejected:0,
                terminated:0,
                asset:0,
                created:0,
                canceled:0,
                totals:0
            },
            calls:{
                accepted:0,
                canceled:0,
                missed:0,
                totals:0,
            },
            PaymentMethod:{
                mobile:0,
                bank_transfer:0,
                card:0,
                cash:0,
                wallet:0,
                other:0,

                created:0,
                pending:0,
                verify:0,
                failed:0,
                disable:0,

                fast:0,
                king:5
            },
            Profit:{
                pending:0,
                treaty:0,
                totals:0
            },
            StarBuying:{
                pending:0,
                success:0,
                canceled:0,
                failed:0,
                totals:0
            },
            Transaction:{
                pending:0,
                treaty:0,
                success:0,
                canceled:0,
                failed:0,
                totals:0
            },
            TransferOrder:{
                sent:0,
                failed:0,
                pending:0,
                treatment:0,
                canceled:0,
                totals:0
            }
        }
    },
    setUserProfitDetails: {
        loading: false,
        data:[],
    },
    role: {
        loading: false,
        data:[],
    },

    admin: {
        loading: false,
        data:[],
        totals:0,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    suspectParticular:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    suspectCompany:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },

    publicity: {
        loading: false,
        data:[],
        totals:0,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    publicity_asset: {
        loading: false,
        data:[],
        totals:0,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    publicity_terminated: {
        loading: false,
        data:[],
        totals:0,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    publicity_rejected: {
        loading: false,
        data:[],
        totals:0,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    pending:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    traitement:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    sent:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },failed:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },canceled:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },created:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },permanently_cancel:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },raisons:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },created_mobile:{
        loading: false,
        data: [],totals: 0 ,number_page:1,current_page:1,next_page:null,prev_page:null,
    },
    amountByStatus: {
        pending:0,
        treatment:0,
        canceled:0,
        failed:0,
        sent:0,
        created:0,
        created_mobile:0,
    },
    deleteUser: {
        loading: false,
        data:[],
        totals:'0',number_page:1,current_page:1,next_page:null,prev_page:null,
    },


}
