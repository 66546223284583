<style>

.table-responsive {
  overflow-y: hidden !important;
}
</style>
<script>
import {
  computed
} from 'vue';

import store from '../../store';
import {checkvalue, exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";
import {formatNumber} from "chart.js/helpers";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'UserProfits',
  components: {TableComponent,Loading},
  data() {
    return {
      isLoading: false,
      fullPage: true,
      dataExport:{
        main_model:"profit",
        created_at: {
          'start': "",
          'end':"",
        },
        limit: "",
      },
      filterChose: 'option1',
      profits: [],
      profitsDetails: [],
      totalsProfit:0,
      profits_search_by:'NAME',
      profits_search_tiping:'',
      elt:"",
      next_page:0
    };
  },
  mounted() {
    this.getNextProfit();
  },
  beforeMount(){
    this.getProfitsDetails();
    this.getProfits();
  },
  computed:{
    profitsFilter(){
      if (this.profits_search_tiping.trim().length>0){
        if (this.profits_search_by=="NAME"){
          const liste = (this.profits.data.filter((item)=>
              item.user.first_name.toLowerCase().includes(this.profits_search_tiping.toLowerCase()) ||  item.user.last_name.toLowerCase().includes(this.profits_search_tiping.toLowerCase()) ));
          return liste;
        }
      }
      return this.profits.data;
    },
  },

  methods: {
    checkvalue,
    formateNumber,
    formatNumber,
    dateFormat(date){
      return moment(date);
    },
    getNextProfit() {
      window.onscroll = () => {
        // let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
        let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
        if (scrollBottom < 1 || scrollBottom <= 0) {
          if (store.state.profits.next_page !==null) {
            this.getProfits();
          }
        }
      }
    },
    getExportationData() {
      store.dispatch("exportDataProfit", [this.dataExport,this.filterChose]);
      this.profits = computed(() => store.state.profits);
    },

    filterFieldsEmpty(){
      this.dataExport.limit="";
      this.dataExport.created_at.start=""
      this.dataExport.created_at.end=""
    },
    getProfitsDetails() {
      store.dispatch("getProfitByStatus");
      this.profitsDetails = computed(() => store.state.profitsDetails.data);
    },
    getProfits() {
      let self = this;
      let page = store.state.profits.next_page
      store.dispatch("getProfits", page);
      self.profits = computed(() => store.state.profits);
    },
    exportexls_auth(type,name,fn,dl){
      this.elt = this.$refs.profit_;
      return exportexls_auth(type,name,fn,dl,this.elt)
    }
  },

}

</script>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <loading v-model:active="profits.loading"
             :can-cancel="false"
             :is-full-page="fullPage"/>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{$t("dashboard.profit.all_profit")}}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">Profits
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm disabled" type="button" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="card">
                <div class="card-header  align-items-start pb-0" style="margin-bottom: 10%">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(profitsDetails.pending)}} $</h2>
                  <p class="card-text">{{ $t("payout.card.pending_amount") }}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Greetings Card ends -->

            <!-- Subscribers Chart Card starts -->
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(profitsDetails.treaty)}} $</h2>
                  <p class="card-text">{{ $t("payout.card.treaty") }}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Subscribers Chart Card ends -->

            <!-- Orders Chart Card starts -->
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="card">
                <div class="card-header align-items-start pb-0" style="margin-bottom: 10%;">
                  <div class="avatar bg-light-warning p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{formateNumber(profits.total_beneficiaries)}} </h2>
                  <p class="card-text">{{ $t("users.profits.card_title") }}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Multilingual -->

        <div class="row d-flex">
          <div class="col-10 text-center float-none">
            <div class="demo-inline-spacing text-center mb-1" style="margin-top: -2%;"><br>
              <div class="form-check form-check-inline" v-on:click="filterFieldsEmpty">
                <input v-model="this.filterChose" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked="" value="option1">
                <label class="form-check-label" for="inlineRadio1">{{$t("search.infsup")}}</label>
              </div>
              <div  class="form-check form-check-inline " v-on:click="filterFieldsEmpty">
                <input v-model="this.filterChose" class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" value="option2">
                <label class="form-check-label" for="inlineRadio2">{{$t("search.supegale")}}</label>
              </div>
            </div>
          </div>
          <div class="col-2">
            <span style="cursor: pointer;" class="float-end" v-on:click="filterFieldsEmpty" >{{$t("search.clean")}}</span>
          </div>
        </div>
        <div class="col-md-12 col-xl-12">
          <div v-if="this.filterChose==='option1'" class="card shadow-none bg-transparent border-danger">
            <div class="card-body">
              <div class="d-flex row">
                <div class="col-2">
                  <select v-on:change="getExportationData()" class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.activate_title")}}</option>
                    <option value="true">Show all ptofits</option>
                  </select>
                </div>
                <div class="col-3 d-flex">
                  <span>Limit:</span>
                  <input type="number" :disabled="isDisabled"  v-model="this.dataExport.limit"  class="form-control form-select-sm flatpickr-basic" >
                </div>
                <div class="col-3 d-flex">
                  <span>Debut:</span>
                  <input type="date" id="fp-default"  v-model="this.dataExport.created_at.start" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
                </div>
                <div class="col-3 d-flex">
                  <span>Fin:</span>
                  <input type="date" v-model="this.dataExport.created_at.end" :min="this.dataExport.created_at.start" id="fp-default" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
                </div>
                <div class="col-1 float-end">
                  <button class="btn-section-block btn-sm btn btn-warning" :class="{disabled:isButton}" v-on:click="getExportationData(true)">Searche</button>
                </div>

              </div>
            </div>
          </div>
          <div v-if="this.filterChose==='option2'" class="card shadow-none bg-transparent border-danger">
            <div class="card-body">
              <div class="d-flex row">
                <div class="col-4">
                  <select v-on:change="getExportationData()" class="form-select form-select-sm" id="selectSmall">
                    <option value="selected">{{$t("filter_zone.activate_title")}}</option>
                    <option value="true">Afficher tous les profits</option>
                  </select>
                </div>

                <div class="col-3 d-flex">
                  <span>{{$t("search.limit")}}:</span>
                  <input type="number" v-model="this.dataExport.limit"  class="form-control form-select-sm flatpickr-basic" >
                </div>


                <div class="col-4 d-flex">
                  <span>{{$t("search.date")}}:</span>
                  <input type="date" format="yyyy-mm-dd" v-model="this.dataExport.created_at.start" id="fp-default" class="form-control form-select-sm flatpickr-basic" placeholder="YYYY-MM-DD">
                </div>
                <div class="col-1 float-end">
                  <a href="#" class="btn-section-block btn-sm btn btn-warning" :class="{disabled:isButtonop2}" v-on:click="getExportationData()">{{$t("search.search")}}</a>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Justified Pills Start -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="particulier" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{$t("dashboard.profit.all_profit")}}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','particular_d')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="profits_search_by" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="NAME">NAME</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="profits_search_tiping" type="search" class="form-control" placeholder="search" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="profit_">
                          <thead>
                          <tr>
                            <th class="coll">ID PROFITS</th>
                            <th class="coll">ID USERS</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">{{ $t("report_user.table_updated") }}</th>
                            <th class="coll">PHOTO</th>
                            <th class="coll">NAME</th>
                            <th class="coll">{{$t("users.profits.status")}}</th>
                            <th class="coll">{{$t("users.profits.total")}}</th>
                          </tr>
                          </thead>
                          <tbody v-if="profits.total_element!==0">
                          <tr v-for="prof in profitsFilter" v-bind:key="prof.id">
                            <th scope="row">{{prof.id}}</th>
                            <th scope="row">{{prof.user.id}}</th>
                            <th> {{dateFormat(prof.created_at).format('LLLL')}} </th>
                            <th> {{dateFormat(prof.updated_at).format('LLLL')}} </th>
                            <th>
                              <img :src="prof.user.profile_image_link"  class="rounded-circle" width="32" height="32">
                            </th>
                            <th scope="row">{{checkvalue(prof.user.gender)+" "+ checkvalue(prof.user.last_name) +" "+ checkvalue(prof.user.first_name)}}</th>
                            <th>
                              <span v-if="prof.status==`pending`" class="badge bg-warning">{{prof.status}}</span>
                              <span v-if="prof.status==`failed`" class="badge bg-danger">{{prof.status}}</span>
                              <span v-if="prof.status==`sent`" class="badge bg-info">{{prof.status}}</span>
                              <span v-if="prof.status==`treaty`" class="badge bg-success">{{prof.status}}</span>
                            </th>
                            <th scope="row">{{prof.amount}} $</th>

                          </tr>
                          </tbody >
                          <tbody v-else>
                          <tr >
                            <td colspan="9" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="profits.loading">
                          <tr>
                            <td colspan="9" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

