<script>
import Chart from 'chart.js/auto';

import store from '../../store';

export default{
  data() {
    return {

    }
  },
  mounted() {
    this.getChartDatas();
  },

  methods: {
    getChartDatas(){
      store.dispatch("getChartDatas",[2,true])
          .then((response) => {
            this.contructChart(Object.values(response.data.User),Object.keys(response.data.User))
          })
          .catch((error) => {
            return error
          });

    },

    contructChart(c_data,c_labels){
      const  ctx = document.getElementById("myChart2");
      const data = {
        labels:c_labels,
        datasets:[{
          label:"Particular account activated statistic ",
          data:c_data,
          fill: false,
          borderColor: 'rgb(255,85,0)',
          tension: 0.1,
          hoverOffset:4
        }]
      };
      const myChart = new Chart(ctx,{
        type:"line",
        data:data,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }}
      });
      myChart
    }
  },
}


</script>
<template>
  <div>
    <canvas id="myChart2"></canvas>
  </div>
</template>
