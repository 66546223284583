
<script>
import { computed } from 'vue';

import { useRoute } from 'vue-router';

import store from '../../store';
import Swal from 'sweetalert2';
import {calculateAge,dateFormat} from "../../store/helpers/helper";


export default {
  name: "UserControllerDetailsComponent",
  data() {
    return {
      user: [],
      profitAmountSum:[],
      transactions: [],
      id: '',
      account: '',
      user_point: '',
      document_type: '',
      country: '',
      nationality: '',
      langues: '',
      nationality_manager: '',
      social_status: '',
      social_networks: '',
      isLangue:false,
      pubs:[],
      calls:[],
      payement_methods:[],
      starb:[],
      profit:[],
    }
  },

  mounted() {
    const route = useRoute();
    this.id = route.params.id;
    this.getUserProfitDetails();
    this.getUser();
    this.getUserTransactionDetails();
    this.isLangue = this.getCurrentLangue();
  },
  methods: {
    calculateAge,
    dateFormat,
    exportToPDF() {
      print();
    },
    getCurrentLangue(){
      if(sessionStorage.getItem('langue')==="fr"){
        return true;
      }else{
        return false
      }
    },
    formatDecimal(number) {
      return parseFloat(number).toFixed(2)
    },
    getUser() {
      store.dispatch("getUser", this.id);
      this.user = computed(() => store.state.userDetail.data);
      this.account = computed(() => store.state.userDetail.account);
      this.user_point = computed(() => store.state.userDetail.point);
      this.document_type = computed(() => this.user.document_type);
      this.country = computed(() => store.state.userDetail.country);
      this.nationality = computed(() => this.user.user_nationalities);
      this.langues = computed(() => this.user.user_spoken_languages);
      this.nationality_manager = computed(() => this.user.nationality_manager);
      this.social_status = computed(() => this.user.social_status);
      this.social_networks = computed(() => store.state.userDetail.social_networks);
    },
    getUserTransactionDetails() {
      store.dispatch("getUserStatisticsOne", this.id);

      this.transactions = computed(() => store.state.all_one_user_details.data.Transaction);
      this.pubs = computed(() => store.state.all_one_user_details.data.adds);
      this.calls = computed(() => store.state.all_one_user_details.data.calls);
      this.payement_methods = computed(() => store.state.all_one_user_details.data.PaymentMethod);
      this.starb = computed(() => store.state.all_one_user_details.data.StarBuying);
      this.profit = computed(() => store.state.all_one_user_details.data.Profit);
      // this.transactions = computed(() => store.state.userTransactionDetails.data);

    },
    confrimation(id, action) {
      Swal.fire({
        title:  this.$t('alert.comfirm_title'),
        text: this.$t('alert.comfirm_text'),
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText:this.$t('alert.yes'),
        customClass: { confirmButton: "btn btn-warning", cancelButton: "btn btn-outline-danger ms-1" },
        buttonsStyling: !1,
      }).then((result) => {
        if (result.value) {
          this.editUserUserState(id, action);
        }
      })
    },

    getUserProfitDetails() {
      store.dispatch("getUserProfitDetails", this.id);


      this.profitAmountSum = computed(() => store.state.setUserProfitDetails.data);

    },

    copyURL(diff) {
      let link_co = document.getElementById(""+diff+"").innerHTML;
      const textArea = document.createElement('textarea');
      textArea.value = link_co;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.$toast.success(this.$t('copy'));
    }
  },

/*
    copyURL(diff) {
      console.log(diff)
      /!*try {
        let share_link = $("." + diff).text();
        console.log(share_link);
        if (navigator.clipboard.writeText(share_link)) {
          toastr['success'](this.$t('toast.copy'), this.$t('toast.link-copied'));
        }
      } catch (error) {
        toastr['error'](this.$t('toast.copy'),  this.$t('toast.link-error'));
      }*!/
    },
*/
    editUserUserState(id, action) {
      store.dispatch("updateUserState", [parseInt(id), action])
          .then((response) => {
            console.log(response);
            Swal.fire({
              icon: "success",
              title:  this.$t('alert.success_title'),
              text: this.$t('alert.success_text'),
              customClass: {
                confirmButton: "btn btn-success"
              }

            });
            this.getUser()
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            Swal.fire({
              title:  this.$t('alert.error_title'),
              text: this.$t('alert.error_text'),
              icon: "error",
              customClass: { confirmButton: "btn btn-success" }
            });
          });
    },
  }


</script>

<style>
@media print{
  head *, footer *,#header,#sidebar, #btn{
    display:none
  }
}
</style>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content " ref="pdf" id="printMe">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{$t("dashboard.details.title")}}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">{{$t("dashboard.details.sub_title")}}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm disabled" type="button" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>

            </div>
          </div>
        </div>
      </div>

      <div class="content-body" ref="content" id="content">
        <section class="app-user-view-connections">
          <div class="row">
            <!-- User Sidebar -->
            <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
              <div class="card">
                <div class="card-body">
                  <div class="user-avatar-section">
                    <div class="d-flex align-items-center flex-column">
                      <img class="rounded-circle" :src="user.profile_image_link" height="90"
                           width="90" alt="User avatar" />
                      <div class="user-info text-center">
                        <h4 v-if="user.first_name!=null">{{user.first_name + " " +
                        user.last_name}}
                          <span v-if="account.account_type === 'particular'">{{calculateAge((user.birth_date)) +" "+ $t("home.year_old")}}</span>

                        </h4>
                        <h4 v-if="user.enterprise_name !=null">{{user.enterprise_name + " " +
                        user.acronym}}</h4>
                        <span class="badge bg-light-warning">
                                                    {{this.account.account_type}}</span> |
                        <span v-if="account.account_type === 'particular'"
                              class="badge bg-light-success">
                                                    {{social_status.en_social_status}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-around my-2 pt-75">
                    <div class="d-flex align-items-start me-2">
                                            <span class="badge bg-light-primary p-75 rounded">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award font-medium-2"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                                            </span>
                      <div class="ms-75">

                        <h4 class="mb-0">{{formatDecimal(user_point.point_per_minite)}}</h4>
                        <small>{{ $t("users.details.point") }}</small>
                      </div>
                    </div>
                    <div class="d-flex align-items-start">
                                            <span class="badge bg-light-primary p-75 rounded">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-target font-medium-2"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>                                            </span>
                      <div class="ms-75">
                        <h4 class="mb-0">{{ $t("users.details.status") }}</h4>
                        <small v-if="user.status"><span class="badge bg-light-success">{{$t("users.table_content.on_line")}}</span></small>
                        <small v-if="!user.status"><span class="badge bg-light-danger">{{$t("users.table_content.un_on_line")}}</span></small>
                      </div>
                    </div>
                  </div>
                  <div class="card border-primary">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-start">
                        <span class="badge bg-light-primary">{{ $t("users.details.bio") }}</span>
                      </div>
                      <span>{{user.bio}}</span>
                    </div>
                  </div>

                  <div class="card border-primary">
                    <div class="card-header">
                      <h4>{{ $t("users.details.profit") }}</h4>
                    </div>
                    <div class="card-body">
                      <div class="d-flex">
                        <div class="col-10 mt-2">
                          <span class="fw-bolder me-25">{{ $t("users.details.pending") }}:</span>
                        </div>
                        <div class="col-2 mt-2">
                          <span class="badge bg-light-warning">{{profitAmountSum.pending}}</span>
                        </div>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="col-10">
                          <span class="fw-bolder me-25">{{ $t("users.details.treaty") }}:</span>
                        </div>
                        <div class="col-2">
                          <span class="badge bg-light-success">{{profitAmountSum.treaty}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!--/ User Sidebar -->

            <!-- User Content -->
            <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
              <!-- Accordion with border start -->
              <section id="accordion-with-border">
                <div class="row">
                  <div class="col-sm-12">
                    <div id="accordionWrapa50" role="tablist" aria-multiselectable="true">
                      <div class="card">
                        <div class="card-header">
                          <h4 class="card-title" v-if="user.first_name!=null">{{user.first_name + " " +
                          user.last_name}}
                            <span v-if="account.account_type === 'particular'">{{calculateAge((user.birth_date)) +" "+ $t("home.year_old")}}</span>

                          </h4>
                          <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1" style="height: 34px">
                            <router-link to="/users_in_controller" class="btn btn-primary btn-sm" title="preview page">
                              <svg xmlns="http://www.w3.org/2000/svg" style="height: 19px;" width="24" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-up-left"><polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path></svg>
                            </router-link>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="accordion accordion-border" id="accordionBorder">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingBorderOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBorderOne" aria-expanded="false" aria-controls="accordionBorderOne">
                                  {{ $t("users.details.suite_details") }}
                                </button>
                              </h2>
                              <div id="accordionBorderOne" class="accordion-collapse collapse" aria-labelledby="headingBorderOne" data-bs-parent="#accordionBorder">
                                <div class="accordion-body">
                                  <div class="card-body">
                                    <ul class="timeline">
                                      <li class="timeline-item">
                                  <span
                                      class="timeline-point timeline-point-indicator"></span>
                                        <div class="timeline-event"
                                             v-if="account.account_type === 'particular'">
                                          <div
                                              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                            <h6>{{ $t("users.details.user_nationality") }}</h6>
                                            <span
                                                class="timeline-event-time">{{ $t("users.details.nationalities") }}</span>
                                          </div>
                                          <div class="d-flex flex-row align-items-center">
                                            <img class="me-1"
                                                 src="/app-assets/images/icons/file-icons/pdf.png"
                                                 alt="invoice" height="23" />
                                            <span v-for="nat in nationality"
                                                  v-bind:key="nat.id">{{nat.nationality.fr_nationality
                                            +", "}}</span>
                                          </div>
                                        </div>
                                        <div class="timeline-event"
                                             v-if="account.account_type != 'particular'">
                                          <div
                                              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                            <h6>{{ $t("users.details.nationalities_m") }}</h6>
                                            <span
                                                class="timeline-event-time">{{ $t("users.details.nationalities") }}</span>
                                          </div>
                                          <div class="d-flex flex-row align-items-center">
                                            <img class="me-1"
                                                 src="/app-assets/images/icons/file-icons/pdf.png"
                                                 alt="invoice" height="23" />
                                            <span v-for="natm in nationality_manager"
                                                  v-bind:key="natm.id">{{natm.nationality.fr_nationality
                                            +", "}}</span>
                                          </div>
                                        </div>
                                      </li>

                                      <li class="timeline-item">
                                                                <span
                                                                    class="timeline-point timeline-point-indicator"></span>
                                        <div class="timeline-event">
                                          <div
                                              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                            <h6>{{ $t("users.details.language") }}</h6>
                                            <span class="timeline-event-time">{{ $t("users.details.language") }}</span>
                                          </div>
                                          <div class=" align-items-center">
                                            <img class="me-1"
                                                 src="/app-assets/images/icons/file-icons/pdf.png"
                                                 alt="invoice" height="23" />
                                            <span v-for="la in langues"
                                                  v-bind:key="la.id">{{la.spoken_language.fr_language
                                            +" , "}}</span>
                                          </div>
                                        </div>

                                      </li>
                                      <li class="timeline-item">
                                <span
                                    class="timeline-point timeline-point-indicator"></span>
                                        <div class="timeline-event">
                                          <div
                                              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                            <h6>{{ $t("users.details.can_recive_payed") }}</h6>
                                            <span class="timeline-event-time">
                                                                            <button v-if="user.can_receive_profit"
                                                                                    v-on:click="confrimation(user.id,'CAN_RECEIVE_PRO_FALSE')"
                                                                                    class="btn btn-danger col-12 btn-sm">{{ $t("users.details.disable") }}</button>
                                                                            <button v-if="!user.can_receive_profit"
                                                                                    v-on:click="confrimation(user.id,'CAN_RECEIVE_PRO_TRUE')"
                                                                                    class="btn btn-success col-12 btn-sm">{{ $t("users.details.enable") }}</button>
                                                                        </span>
                                          </div>
                                          <div class="d-flex flex-row align-items-center">
                                                                        <span v-if="user.can_receive_profit"
                                                                              class="badge bg-light-success">{{ $t("users.details.can_receive_yes") }}</span>
                                            <span v-if="!user.can_receive_profit"
                                                  class="badge bg-light-danger">{{ $t("users.details.can_receive_no") }}</span>
                                          </div>
                                        </div>

                                      </li>
                                      <li class="timeline-item">
                                                                <span
                                                                    class="timeline-point timeline-point-indicator"></span>
                                        <div class="timeline-event">
                                          <div
                                              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                            <h6>{{ $t("users.details.can_recive_pub") }}</h6>
                                            <span class="timeline-event-time">
                                                                            <!-- <button v-if="user.can_receive_ad"
                                                                                v-on:click="confrimation(user.id,'CAN_RECEIVE_PUB_FALSE')"
                                                                                class="btn btn-danger col-12 btn-sm">{{ $t("users.details.disable") }}
                                                                            </button> -->
                                              <!-- <button v-if="!user.can_receive_ad"
                                                  v-on:click="confrimation(user.id,'CAN_RECEIVE_PUB_TRUE')"
                                                  class="btn btn-success col-12 btn-sm">{{ $t("users.details.enable") }}
                                              </button> -->
                                                                        </span>
                                          </div>
                                          <div class="d-flex flex-row align-items-center">
                                                                        <span v-if="user.can_receive_ad"
                                                                              class="badge bg-light-success">{{ $t("users.details.can_receive_pub_no") }}</span>
                                            <span v-if="!user.can_receive_ad"
                                                  class="badge bg-light-danger">{{ $t("users.details.can_receive_pub_yes") }}</span>
                                          </div>
                                        </div>

                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingBorderhuit">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBorderhuit" aria-expanded="false" aria-controls="accordionBorderhuit">
                                  {{ $t("users.details.title_details") }}
                                </button>
                              </h2>
                              <div id="accordionBorderhuit" class="accordion-collapse collapse" aria-labelledby="headingBorderhuit" data-bs-parent="#accordionBorder">
                                <div class="accordion-body table-responsive" >
                                  <table class="table table-bordered">

                                    <tbody>
                                    <tr>
                                      <td>{{ $t("users.details.email") }}</td>
                                      <td>{{user.email}}</td>
                                      </tr>
                                      <tr>

                                      <td>{{ $t("users.blocked") }}</td>
                                      <td>
                                         <span v-if="user.blocked" class="badge bg-light-danger">{{$t("users.table_content.yes")}}</span>
                                        <span v-if="!user.blocked" class="badge bg-light-success">{{$t("users.table_content.no")}}</span>
                                      </td>
                                      </tr>
                                      <tr>
                                      <td>{{ $t("users.details.suspected") }}</td>
                                      <td>
                                        <span v-if="user.visible_status" class="badge bg-light-success">{{ $t("users.details.visible_status") }}</span>
                                        <span v-if="!user.visible_status" class="badge bg-light-danger">{{ $t("users.details.un_visible_status") }}</span>
                                      </td>
                                      </tr>
                                      <tr>
                                      <td>{{ $t("users.details.phone") }}</td>
                                      <td>
                                        {{user.country_code}} {{user.phone_number}}
                                      </td>
                                      </tr>
                                      <tr>
                                      <td>{{ $t("users.details.country") }}</td>
                                      <td>
                                        <span v-if="isLangue">{{country.fr_country}}</span>
                                        <span v-if="!isLangue">{{country.en_country}}</span>
                                      </td>
                                      </tr>
                                      <tr>
                                      <td>{{ $t("users.details.ref") }}</td>
                                      <td>
                                        <span v-if="user.visible_status" class="badge bg-light-success">{{ $t("users.details.visible_status") }}</span>
                                        <span v-if="!user.visible_status" class="badge bg-light-danger">{{ $t("users.details.un_visible_status") }}</span>
                                      </td>
                                      </tr>
                                      <tr>
                                      <td v-if="account.account_type === 'particular'">{{ $t("users.details.doc_type") }}</td>
                                      <td v-if="account.account_type === 'particular'">
                                        <span v-if="isLangue">{{document_type.fr_name}}</span>
                                        <span v-if="!isLangue">{{document_type.en_name}}</span>
                                      </td>
                                      </tr>
                                      <tr>
                                      <td  v-if="account.account_type === 'particular'">{{ $t("users.details.doc_number") }}</td>
                                      <td  v-if="account.account_type === 'particular'">
                                        <span>{{user.id_number}}</span>
                                      </td>
                                      </tr>
                                      <tr>
                                      <td  v-if="account.account_type !== 'particular'">{{ $t("users.details.doc_register") }}</td>
                                      <td  v-if="account.account_type !== 'particular'">
                                        <span>{{user.commercial_register_number}}</span>
                                      </td>
                                      </tr>
                                      <tr>
                                        <td  >{{ $t("users.details.exp_date") }}</td>
                                        <td >
                                          <span>{{user.id_expiry_date}}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td  >OPT CONFIRMED</td>
                                        <td >
                                          <span>{{(user.opt_confirmed_at!=='')?dateFormat(user.opt_confirmed_at).fromNow():""}}</span>
                                        </td>
                                      </tr>
                                    <tr>
                                      <td  >PIN SELECTED</td>
                                      <td >
                                        <span>{{(user.define_pin_at!=='')?dateFormat(user.define_pin_at).fromNow():""}}</span>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>

                                </div>

                              </div>
                            </div>


                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingBorderTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBorderTwo" aria-expanded="false" aria-controls="accordionBorderTwo">
                                  {{ $t("users.details.payement_method") }}
                                </button>
                              </h2>
                              <div id="accordionBorderTwo" class="accordion-collapse collapse" aria-labelledby="headingBorderTwo" data-bs-parent="#accordionBorder">
                                <div class="accordion-body">
                                  <div class="columns">
                                    <p>{{ $t("users.details.tm") }}</p>
                                    <table class="table table-bordered">
                                      <thead>
                                      <tr>
                                        <th>{{ $t("users.details.cash") }}</th>
                                        <th>{{ $t("users.details.card") }}</th>
                                        <th>{{ $t("users.details.wallet") }}</th>
                                        <th>{{ $t("users.details.mobile") }}</th>
                                        <th>{{ $t("users.details.bank_transfer") }}</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr>
                                        <td>{{ payement_methods.cash }}</td>
                                        <td>{{ payement_methods.card }}</td>
                                        <td>{{ payement_methods.wallet }}</td>
                                        <td>{{ payement_methods.mobile }}</td>
                                        <td>{{ payement_methods.bank_transfer }}</td>

                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <hr>
                                  <div class="columns">
                                    <p>{{ $t("users.details.op_verification") }}</p>
                                    <table class="table table-bordered">
                                      <thead>
                                      <tr>
                                        <th>{{ $t("users.details.king") }}</th>
                                        <th>{{ $t("users.details.fast") }}</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr>
                                        <td>{{payement_methods.king}}</td>
                                        <td>{{payement_methods.fast}}</td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingBorderThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBorderThree" aria-expanded="false" aria-controls="accordionBorderThree">
                                  {{ $t("users.details.transactions") }}
                                </button>
                              </h2>
                              <div id="accordionBorderThree" class="accordion-collapse collapse" aria-labelledby="headingBorderThree" data-bs-parent="#accordionBorder">
                                <div class="accordion-body">
                                  <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                      <th>{{ $t("users.details.treaty") }}</th>
                                      <th>{{ $t("users.details.failed") }}</th>
                                      <th>{{ $t("users.details.pending") }}</th>
                                      <th>{{ $t("users.details.success") }}</th>
                                      <th>{{ $t("users.details.canceled") }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                      <td>{{ transactions.treaty }}</td>
                                      <td>{{ transactions.failed }}</td>
                                      <td>{{ transactions.pending }}</td>
                                      <td>{{ transactions.canceled }}</td>
                                      <td>{{ transactions.canceled }}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingBorderFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBorderFour" aria-expanded="false" aria-controls="accordionBorderFour">
                                  {{ $t("users.details.calls_hist") }}
                                </button>
                              </h2>
                              <div id="accordionBorderFour" class="accordion-collapse collapse" aria-labelledby="headingBorderFour" data-bs-parent="#accordionBorder">
                                <div class="accordion-body">
                                  <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                      <th>{{ $t("users.details.accepted") }}</th>
                                      <th>{{ $t("users.details.canceled") }}</th>
                                      <th>{{ $t("users.details.missed") }}</th>
                                      <th>{{ $t("users.details.totals") }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                      <td>{{ calls.accepted }}</td>
                                      <td>{{ calls.canceled }}</td>
                                      <td>{{ calls.missed }}</td>
                                      <td>{{ calls.totals }}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingBorderFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBorderFive" aria-expanded="false" aria-controls="accordionBorderFive">
                                  {{ $t("users.details.social_media") }}
                                </button>
                              </h2>
                              <div id="accordionBorderFive" class="accordion-collapse collapse" aria-labelledby="headingBorderFive" data-bs-parent="#accordionBorder">
                                <div class="accordion-body" v-if="social_networks.facebook!=='' && social_networks.wikipedia!=='' && social_networks.behance!=='' && social_networks.website!=='' && social_networks.pinterest!=='' && social_networks.tiktok!=='' && social_networks.youtube!=='' && social_networks.instagram!=='' && social_networks.linkedin!=='' && social_networks.twitter!=='' && social_networks.twitter!=''">
                                  <div class="d-flex mt-2" v-if="social_networks.facebook!==''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectorface.png" alt="facebook"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Facebook</p>
                                        <span id="facebook">{{social_networks.facebook}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm"
                                                v-on:click="copyURL('facebook')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>

                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex align-items-start mt-2" v-if="social_networks.twitter!=''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectortwitter.png" alt="twitter"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Twitter</p>
                                        <span id="twitter">{{social_networks.twitter}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm"
                                                v-on:click="copyURL('twitter')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>

                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex mt-2" v-if="social_networks.linkedin!=''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectorlinkin.png" alt="instagram"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Linkedin</p>
                                        <span id="linkedin">{{social_networks.linkedin}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm"
                                                v-on:click="copyURL('linkedin')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>

                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex mt-2" v-if="social_networks.instagram!=''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectorI.png" alt="dribbble"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Instagramme</p>
                                        <span class="instagramme">{{social_networks.instagram}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm" title="copié"
                                                v-on:click="copyURL('instagramme')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>

                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex mt-2" v-if="social_networks.youtube!=''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectoryou.png" alt="behance"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Youtube</p>
                                        <span id="youtube">{{social_networks.youtube}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm" title="copié"
                                                v-on:click="copyURL('youtube')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex mt-2" v-if="social_networks.tiktok!=''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectortiktok.png" alt="behance"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Tiktok</p>
                                        <span id="tiktok">{{social_networks.tiktok}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm" title="copié"
                                                v-on:click="copyURL('tiktok')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex mt-2" v-if="social_networks.pinterest!=''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectorpint.png" alt="behance"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Pinterest</p>
                                        <span id="pinterest">{{social_networks.pinterest}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm" title="copié" v-on:click="copyURL('pinterest')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex mt-2" v-if="social_networks.website!=''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectorweb.png" alt="behance"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Page web</p>
                                        <span id="web">{{social_networks.website}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm" title="copié"
                                                v-on:click="copyURL('web')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex mt-2" v-if="social_networks.behance!=''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectorbe.png" alt="behance"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Behance</p>
                                        <span id="be">{{social_networks.behance}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm" title="copié"
                                                v-on:click="copyURL('be')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex mt-2" v-if="social_networks.wikipedia!=''">
                                    <div class="flex-shrink-0">
                                      <img src="/app-assets/images/socials/vectorwiki.png" alt="behance"
                                           class="me-1" height="38" width="38" />
                                    </div>
                                    <div class="d-flex justify-content-between flex-grow-1">
                                      <div class="me-1">
                                        <p class="fw-bolder mb-0">Wikipedia</p>
                                        <span id="wiki">{{social_networks.wikipedia}}</span>
                                      </div>
                                      <div class="mt-50 mt-sm-0">
                                        <button class="btn btn-icon btn-outline-secondary btn-sm" title="copié"
                                                v-on:click="copyURL('wiki')">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="14" height="14" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- /Social Accounts -->
                                </div>
                                <div class="text-center" v-else>
                                  <p>{{$t("social_media")}}</p>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingBorderSix">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBorderSix" aria-expanded="false" aria-controls="accordionBorderSix">
                                  {{ $t("users.details.starBuying") }}
                                </button>
                              </h2>
                              <div id="accordionBorderSix" class="accordion-collapse collapse" aria-labelledby="headingBorderSix" data-bs-parent="#accordionBorder">
                                <div class="accordion-body" >
                                  <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                      <th>{{ $t("users.details.pending") }}</th>
                                      <th>{{ $t("users.details.success") }}</th>
                                      <th>{{ $t("users.details.canceled") }}</th>
                                      <th>{{ $t("users.details.failed") }}</th>
                                      <th>{{ $t("users.details.totals") }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                      <td>{{starb.pending}}</td>
                                      <td>{{starb.success}}</td>
                                      <td>{{starb.canceled}}</td>
                                      <td>{{ calls.failed }}</td>
                                      <td>{{ calls.totals }}</td>
                                    </tr>
                                    </tbody>
                                  </table>

                                </div>

                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingBorderSept">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBorderSept" aria-expanded="false" aria-controls="accordionBorderSept">
                                  {{ $t("users.details.pubs") }}
                                </button>
                              </h2>
                              <div id="accordionBorderSept" class="accordion-collapse collapse" aria-labelledby="headingBorderSept" data-bs-parent="#accordionBorder">
                                <div class="accordion-body table-responsive" >
                                  <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                      <th>{{ $t("users.details.pending") }}</th>
                                      <th>{{ $t("users.details.rejected") }}</th>
                                      <th>{{ $t("users.details.created") }}</th>
                                      <th>{{ $t("users.details.canceled") }}</th>
                                      <th>{{ $t("users.details.asset") }}</th>
                                      <th>{{ $t("users.details.totals") }}</th>
                                      <th>{{ $t("users.details.terminated") }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                      <td>{{pubs.pending}}</td>
                                      <td>{{pubs.rejected}}</td>
                                      <td>{{pubs.created}}</td>
                                      <td>{{ pubs.canceled }}</td>
                                      <td>{{ pubs.asset }}</td>
                                      <td>{{ pubs.terminated }}</td>
                                    </tr>
                                    </tbody>
                                  </table>

                                </div>

                              </div>
                            </div>


                            <div class="card">

                              <div class="d-flex justify-content-center" id="btn">
                                <button v-if="user.blocked" style="height: 31px;"
                                        v-on:click="confrimation(user.id,'CAN_BLOCKED_FALSE')"
                                        class="btn btn-success suspend-user waves-effect btn-sm m-1">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-unlock font-medium-4"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg>
                                </button>
                                <button title="Bloquer le compte" v-if="!user.blocked" style="height: 31px;"
                                        v-on:click="confrimation(user.id,'CAN_BLOCKED_TRUE')"
                                        class="btn btn-danger suspend-user waves-effect btn-sm m-1">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock font-medium-4"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock font-medium-4"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg> -->
                                </button>


                                <button title="Bloquer le compte temporairement" style="height: 31px;"
                                        v-on:click="confrimation(user.id,'CAN_TEMPORARE_FALSE')"
                                        class="btn btn-primary suspend-user waves-effect m-1 btn-sm" :disabled="user.blocked_instantly">
                                  <svg fill="#ffffff" height="17px" width="17px" version="1.1"
                                       id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                       xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 328 328"
                                       xml:space="preserve">
                              <g id="XMLID_496_">
                                  <path id="XMLID_497_"
                                        d="M115,126.75c34.601,0,62.751-28.149,62.751-62.749C177.751,29.4,149.601,1.25,115,1.25
                                  c-34.601,0-62.751,28.15-62.751,62.751C52.249,98.601,80.399,126.75,115,126.75z M115,31.25c18.059,0,32.751,14.692,32.751,32.751
                                  c0,18.058-14.692,32.749-32.751,32.749c-18.059,0-32.751-14.691-32.751-32.749C82.249,45.942,96.941,31.25,115,31.25z" />
                                <path id="XMLID_501_" d="M238.606,181.143c-5.858-5.857-15.356-5.857-21.213,0.001l-30,30.002
                                  c-2.813,2.814-4.393,6.629-4.393,10.607c0,3.978,1.581,7.794,4.394,10.607l30,29.998c2.929,2.929,6.768,4.393,10.606,4.393
                                  c3.839,0,7.678-1.465,10.607-4.394c5.858-5.858,5.857-15.356-0.001-21.213l-19.393-19.392l19.393-19.395
                                  C244.465,196.498,244.464,187.001,238.606,181.143z" />
                                <path id="XMLID_502_" d="M223,116.75c-34.488,0-65.144,16.716-84.297,42.47c-7.763-1.628-15.694-2.47-23.703-2.47
                                  c-63.411,0-115,51.589-115,115c0,8.284,6.716,15,15,15h125.596c19.247,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105
                                  S280.897,116.75,223,116.75z M31.325,256.75c7.106-39.739,41.923-70,83.675-70c2.966,0,5.914,0.165,8.841,0.467
                                  c-3.779,10.82-5.841,22.44-5.841,34.533c0,12.268,2.122,24.047,6.006,35H31.325z M223,296.75c-41.355,0-75-33.645-75-75
                                  s33.645-75,75-75s75,33.645,75,75S264.355,296.75,223,296.75z" />
                              </g>
                          </svg>
                                </button>

                                <button title="Enable suspicious option for this user" style="height: 31px;"
                                        v-on:click="confrimation(user.id,'CAN_SUSPECTED_TRUE')"
                                        :disabled="user.suspected"
                                        class="btn btn-warning suspend-user waves-effect btn-sm m-1">
                                  <svg style="    height: 20px;" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-octagon"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                                </button>

                                <button title="dowload pdf" v-on:click="exportToPDF()" style="height: 31px;"
                                        class="btn btn-info suspend-user waves-effect btn-sm m-1">
                                  <svg style="    height: 20px;" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>                                            </button>
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- Accordion with border end -->
              <!-- connection -->
            </div>
            <!--/ User Content -->
          </div>
        </section>
      </div>
    </div>
  </div>
  <!-- END: Content-->

</template>
