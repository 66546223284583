<script>
import {
  computed,
} from 'vue';

import store from '../../store';
import {exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";

import Swal from 'sweetalert2';

export default {
  components: {TableComponent},
  data() {
    return {
      admin: [],
      roles:[],
      admin_search:'',
      searchBy:'E',
      action:'',
      elt:"",
    };
  },
  mounted() {
    this.getNextadmin();
  },
  beforeMount(){
    this.getadmin();
    this.getRole();
  },

  computed:{
    adminFilter(){
      if (this.admin_search.trim().length>0){
        if (this.searchBy==="E"){
          return (this.admin.data.filter((item)=>item.email.toLowerCase().includes(this.admin_search.toLowerCase())));
        }else if(this.searchBy==="PRENOMS"){
          return (this.admin.data.filter((item)=>item.first_name.toLowerCase().includes(this.admin_search.toLowerCase())));
        }else if(this.searchBy==="PHONE"){
          return  (this.admin.data.filter((item)=>item.phone_number.toLowerCase().includes(this.admin_search.toLowerCase())));
        }
      }
      return this.admin.data;
    },
  },

  methods: {
    formateNumber,
    dateFormat(date){
      return moment(date);
    },
    getNextadmin() {
      window.onscroll = () => {
        // let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
        let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
        if (scrollBottom < 1 || scrollBottom <= 0) {
          if (store.state.admin.next_page !==null) {
            this.getadmin();
          }
        }
      }
    },

    getRole(){
      store.dispatch("getRole");
      this.roles = computed(() => store.state.role);

    },
    checkkField(text){
      if (text.toString().replace(/\s/g, '') === '') {
        return true;
      }
    },
    confrimationDelete(id,rode_id){
      console.log(id);
      Swal.fire({
        title:  this.$t('alert.comfirm_title'),
        text: this.$t('alert.comfirm_text'),
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText:this.$t('alert.yes'),
        customClass: { confirmButton: "btn btn-warning", cancelButton: "btn btn-outline-danger ms-1" },
        buttonsStyling: !1,
      }).then((result) => {
        if (result.value) {
          this.changeRole(id,rode_id)
        }
      })
    },

    confrimation(id, action) {
      Swal.fire({
        title:  this.$t('alert.comfirm_title'),
        text: this.$t('alert.comfirm_text'),
        icon: 'warning',
        showCancelButton: !0,
        confirmButtonText:this.$t('alert.yes'),
        customClass: { confirmButton: "btn btn-warning", cancelButton: "btn btn-outline-danger ms-1" },
        buttonsStyling: !1,
      }).then((result) => {
        if (result.value) {
          this.editUserUserState(id, action);
        }
      })
    },

    editUserUserState(id, action) {
      store.dispatch("updateControllerState", [parseInt(id), action])
          .then((response) => {

            Swal.fire({
              icon: "success",
              title:  this.$t('alert.success_title'),
              text: this.$t('alert.success_text'),
              customClass: {
                confirmButton: "btn btn-success"
              }

            });
            this.getadmin(true);

            return response;
          })
          .catch((error) => {
            Swal.fire({
              title:  this.$t('alert.error_title'),
              text: this.$t('alert.error_text'),
              icon: "error",
              customClass: { confirmButton: "btn btn-success" }
            });

            return error
          });
    },

    changeRole(id,role_id){
      //toastr['warning']('', 'Traitement en cours...');
      //const id_continent = parseInt(id);
      store.dispatch("editRole", [id,role_id])
          .then((response) => {
            Swal.fire({
              icon: "success",
              title:  this.$t('alert.success_title'),
              text: this.$t('alert.success_text'),
              customClass: {
                confirmButton: "btn btn-success"
              }

            });
            this.getadmin(true);
            return response;
          })
          .catch((error) => {
            Swal.fire({
              title: this.$t('alert.error_title'),
              text: this.$t('alert.error_text'),
              icon: "error",
              customClass: {confirmButton: "btn btn-success"}
            });
            return error;
          });
    },
    getadmin(option=false) {
      let self = this;
      let page = 0;
      if (!option){
        page =store.state.admin.next_page
      }else{
        page= store.state.admin.current_page;
      }
      store.dispatch("getSys", [page]);
      self.admin = computed(() => store.state.admin);
    },
    exportexls_auth(type,name,fn,dl){
      this.elt = this.$refs.admin_doc;
      return exportexls_auth(type,name,fn,dl,this.elt)
    }
  },

}

</script>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{$t("dashboard.admin.title")}}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">{{$t("dashboard.admin.sub_title")}}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle disabled" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>

            </div>
          </div>
        </div>
      </div>

      <div class="content-body">

        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-lg-12 col-sm-6 col-12">
              <div class="card">
                <div class="card-header  align-items-start pb-0" style="margin-bottom: 2%">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{ formateNumber(admin.totals) }}</h2>
                  <p class="card-text">System administrators</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Greetings Card ends -->
          </div>
        </section>

        <!-- Justified Pills Start -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <!-- Row grouping -->
                <table-component>
                  <template v-slot:export>
                    <div class="btn-group">
                      <div class="btn-group">
                        <button v-on:click="exportexls_auth('xlsx','admin_doc')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                                                            <span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                                                {{ $t("global_texte.export") }}
                                                            </span>
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-slot:show>
                    <select name="DataTables_Table_0_length" v-model="searchBy" aria-controls="DataTables_Table_0" class="form-select">
                      <option value="E">EMAIL</option>
                      <!-- <option value="PRENOMS">ADRESSE IP</option> -->
                      <!-- <option value="PHONE">{{$t("users.table_header.phone")}}</option> -->
                    </select>
                  </template>
                  <template v-slot:search>
                    <div class="dt-buttons d-inline-flex">
                      <label><input v-model="admin_search" type="search" class="form-control" placeholder="Recherchez" aria-controls="DataTables_Table_0"></label>
                    </div>
                  </template>
                  <template v-slot:table>
                    <table class="table table-responsive mt-2 table table-striped table-bordered" ref="admin_doc">
                      <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">{{ $t("report_user.table_crested") }}</th>
                        <th scope="col">Email</th>
                        <th scope="col">{{$t("users.table_header.surname")}}</th>
                        <th scope="col">{{$t("users.table_header.first_name")}}</th>
                        <th scope="col">LOCAL</th>
                        <th scope="col">PHONE</th>
                        <th scope="col">ROLE</th>
                        <th scope="col">BLOCK USER</th>
                      </tr>
                      </thead>
                      <tbody v-if="this.admin.totals !==0">
                      <tr  v-for="part in this.adminFilter" v-bind:key="part.id">
                        <th scope="row">{{part.id}}</th>
                        <th> {{dateFormat(part.created_at).format('LLLL')}} </th>
                        <td>{{part.email}}</td>
                        <td>{{part.last_name}}</td>
                        <td>{{part.first_name}}</td>
                        <td>{{part.locale}}</td>
                        <td>{{part.phone_country_code}} {{part.phone_number}}</td>
                        <td>{{part.role.name}}</td>

                        <td>
                          <button v-if="part.disabled" v-on:click="confrimation(part.id,false)" class="btn btn-success suspend-user waves-effect btn-sm m-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-unlock font-medium-4"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg>
                          </button>
                          <span v-else>No action required</span>
                        </td>
                      </tr>
                      </tbody >
                      <tbody v-else>
                      <tr >
                        <td colspan="9" class="text-center">{{ $t("global_texte.empty_list") }}</td>
                      </tr>
                      </tbody>
                      <tbody>
                      <tr>
                        <td colspan="9" class="text-center" v-if="this.admin.loading">
                          <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </template>

                </table-component>
                <!--/ Row grouping -->
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

