<script setup>

</script>

<template>
<div>
  <div class="sidenav-overlay"></div>
  <div class="drag-target"></div>

  <!-- BEGIN: Footer-->
  <footer class="footer footer-static footer-light" style="position:fixed; bottom: 0">
    <p class="clearfix mb-0"><span class="float-md-start d-block d-md-inline-block mt-25">COPYRIGHT &copy; 2021<a class="ms-25" href="https://www.bii-group.com" target="_blank">BII-GROUP</a><span class="d-none d-sm-inline-block">, All rights Reserved</span></span></p>
  </footer>
</div>
</template>

<style scoped>

</style>