<template>
<div>
  <!-- BEGIN: Header-->
  <nav class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center" data-nav="brand-center">
    <div class="navbar-header d-xl-block d-none" style="margin: -45px">
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <a class="navbar-brand" href="#">
            <span class="brand-logo">
                <img style="width: 150px;margin-bottom: 5px; font-style: normal" src="../../../public/app-assets/images/logo/logo.png" alt="">
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="navbar-container d-flex content">
      <div class="bookmark-wrapper d-flex align-items-center">
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item"><a class="nav-link menu-toggle" href="#"><i class="ficon" data-feather="menu"></i></a></li>
        </ul>
        <ul class="nav navbar-nav align-items-center ms-auto">
          <li class="nav-item dropdown dropdown-language">
              <a v-if="this.flagController" v-on:click="changeLangue('en')" class="nav-link" id="dropdown-flag" href="#" aria-haspopup="true" aria-expanded="false"><i class="flag-icon flag-icon-us"></i><span class="selected-language">English</span></a>
              <a v-if="!this.flagController" v-on:click="changeLangue('fr')" class="nav-link" href="#" data-language="fr"><i class="flag-icon flag-icon-fr"></i> French</a>
          </li>
          <li class="nav-item d-none d-lg-block">
            <a v-if="this.stateLigthDart" v-on:click="changeState('dark')" class="nav-link nav-link-style">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-moon"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg></a>
            <a v-if="!this.stateLigthDart" v-on:click="changeState('ligth')" class="nav-link nav-link-style">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-sun"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
            </a></li>
        </ul>
      </div>
      <ul class="nav navbar-nav align-items-center ms-auto">
        <li class="nav-item dropdown dropdown-user"><a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" aria-haspopup="true" aria-expanded="false">
          <div class="user-nav d-sm-flex d-none"><span class="user-name fw-bolder">{{this.user}}</span><span class="user-status">{{this.role}}</span></div><span class="avatar"><img class="round" src="../../../public/app-assets/images/logo/logo_circle.png" alt="avatar" height="40" width="40"></span>
        </a>
        </li>
      </ul>
    </div>
  </nav>
  <!-- BEGIN: Main Menu-->
  <div class="horizontal-menu-wrapper">
    <div class="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border container-xxl" role="navigation" data-menu="menu-wrapper" data-menu-type="floating-nav">
      <div class="navbar-header">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item me-auto">
            <a class="navbar-brand" href="../../public/html/ltr/horizontal-menu-template/index.html">
          </a></li>
          <li class="nav-item nav-toggle"><a class="nav-link modern-nav-toggle pe-0" data-bs-toggle="collapse"><i class="d-block d-xl-none text-primary toggle-icon font-medium-4" data-feather="x"></i></a></li>
        </ul>
      </div>
      <div class="shadow-bottom"></div>
      <!-- Horizontal menu content-->
      <div class="navbar-container main-menu-content" data-menu="menu-container">
        <!-- include ../../public/includes/mixins-->
        <ul class="nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation">
          <li class="dropdown nav-item" :class="{active:currentRouteName==='app.home' || currentRouteName==='app.dash_user'}" data-menu="dropdown"><a class="dropdown-toggle nav-link d-flex align-items-center" href="#" data-bs-toggle="dropdown"><i data-feather="home"></i><span data-i18n="Dashboards">{{$t("sidebar.dashboard.title")}}</span></a>
            <ul class="dropdown-menu" data-bs-popper="none">
              <li :class="{active:currentRouteName==='app.home'}"  > <a href="/dashboard" class="dropdown-item d-flex align-items-center"><i data-feather="home"></i><span data-i18n="Analytics">{{$t("sidebar.dashboard.home")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.dash_user'}"  > <a href="/users" class="dropdown-item d-flex align-items-center"><i data-feather="home"></i><span data-i18n="Analytics">{{$t("sidebar.user")}}</span></a>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item" :class="{active:currentRouteName==='app.user_profits' || currentRouteName==='app.user_wallets' || currentRouteName==='app.user_profits_ovl' || currentRouteName==='app.user_wallets_ovl'}" data-menu="dropdown"><a class="dropdown-toggle nav-link d-flex align-items-center" href="#" data-bs-toggle="dropdown"><i data-feather="dollar-sign"></i><span data-i18n="Apps">{{$t("sidebar.audit_groups.title")}}</span></a>
            <ul class="dropdown-menu" data-bs-popper="none">

              <li :class="{active:currentRouteName==='app.user_profits'}"  > <a href="/user_profits" class="dropdown-item d-flex align-items-center"><i data-feather="dollar-sign"></i><span data-i18n="Analytics">{{$t("sidebar.juridique_groups.profits")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.user_wallets'}"  > <a href="/user_wallets" class="dropdown-item d-flex align-items-center"><i data-feather="pocket"></i><span data-i18n="Analytics">{{$t("sidebar.juridique_groups.wallet")}}</span></a>
              </li>
              <li > ---------------------------------------</li>

              <li :class="{active:currentRouteName==='app.user_profits_ovl'}"  > <a href="/user_profits_ovl" class="dropdown-item d-flex align-items-center"><i data-feather="dollar-sign"></i><span data-i18n="Analytics">{{$t("sidebar.juridique_groups.profits_c")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.user_wallets_ovl'}"  > <a href="/user_wallets_ovl" class="dropdown-item d-flex align-items-center"><i data-feather="pocket"></i><span data-i18n="Analytics">{{$t("sidebar.juridique_groups.wallet_c")}}</span></a>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item" :class="{active:currentRouteName==='app.user_denonced' || currentRouteName==='app.users_controller' ||currentRouteName==='app.details'}" data-menu="dropdown"><a class="dropdown-toggle nav-link d-flex align-items-center" href="#" data-bs-toggle="dropdown"><i data-feather="eye"></i><span data-i18n="User Interface">{{$t("sidebar.controller_groups.title")}}</span></a>
            <ul class="dropdown-menu" data-bs-popper="none">
              <li :class="{active:currentRouteName==='app.user_denonced'}"  > <a href="/user_denonced" class="dropdown-item d-flex align-items-center"><i data-feather="user-x"></i><span data-i18n="Analytics">{{$t("sidebar.controller_groups.user_a")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.users_controller' ||currentRouteName==='app.details'}"  > <a href="/users_in_controller" class="dropdown-item d-flex align-items-center"><i data-feather="user"></i><span data-i18n="Analytics">{{$t("sidebar.user")}}</span></a>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item" :class="{active:currentRouteName==='app.business_user' || currentRouteName==='app.business_user_blacklist'}" data-menu="dropdown"><a class="dropdown-toggle nav-link d-flex align-items-center" href="#" data-bs-toggle="dropdown"><i data-feather="package"></i><span data-i18n="Forms &amp; Tables">{{$t("sidebar.business_groups.title")}}</span></a>
            <ul class="dropdown-menu" data-bs-popper="none">
              <li :class="{active:currentRouteName==='app.business_user'}"> <a href="/business_user" class="dropdown-item d-flex align-items-center"><i data-feather="user"></i><span data-i18n="Analytics">{{$t("sidebar.user")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.business_user_blacklist'}"> <a href="/business_user_can_not_receive_paid" class="dropdown-item d-flex align-items-center"><i data-feather="user-x"></i><span data-i18n="Analytics">{{$t("sidebar.business_groups.black_list")}}</span></a>
              </li>
            </ul>
          </li>
         
          <li class="dropdown nav-item" :class="{active:currentRouteName==='app.juridique_sys' || currentRouteName==='app.juridique_user_traitement' || currentRouteName==='app.juridique_user_suspected' || currentRouteName==='app.logs'}" data-menu="dropdown"><a class="dropdown-toggle nav-link d-flex align-items-center" href="#" data-bs-toggle="dropdown"><i data-feather="bar-chart-2"></i><span data-i18n="Charts &amp; Maps">{{$t("sidebar.juridique_groups.title")}}</span></a>
            <ul class="dropdown-menu" data-bs-popper="none">
              <li :class="{active:currentRouteName==='app.juridique_sys'}"> <a href="/juridique_admin_sys" class="dropdown-item d-flex align-items-center"><i data-feather="user-x"></i><span data-i18n="Analytics">{{$t("dashboard.admin_system")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.juridique_user_traitement'}"> <a href="/juridique_user_traitement" class="dropdown-item d-flex align-items-center"><i data-feather="user"></i><span data-i18n="Analytics">{{$t("sidebar.user")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.juridique_user_suspected'}"> <a href="/juridique_user_suspected" class="dropdown-item d-flex align-items-center"><i data-feather="sunset"></i><span data-i18n="Analytics">{{$t("sidebar.juridique_groups.suspected")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.logs'}"> <a href="/logs" class="dropdown-item d-flex align-items-center"><i data-feather="file"></i><span data-i18n="Analytics">Logs</span></a>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item" :class="{active:currentRouteName==='app.pub_pending' || currentRouteName==='app.pub_asset' || currentRouteName==='app.pub_terminated' || currentRouteName==='app.pub_rejected'}" data-menu="dropdown"><a class="dropdown-toggle nav-link d-flex align-items-center" href="#" data-bs-toggle="dropdown"><i data-feather="bar-chart-2"></i><span data-i18n="Charts &amp; Maps">{{$t("sidebar.moderation_groups.title")}}</span></a>
            <ul class="dropdown-menu" data-bs-popper="none">
              <li :class="{active:currentRouteName==='app.pub_pending'}"> <a href="/pub_pending" class="dropdown-item d-flex align-items-center"><i data-feather="mic"></i><span data-i18n="Analytics">{{$t("sidebar.moderation_groups.pub")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.pub_asset'}"> <a href="/pub_asset" class="dropdown-item d-flex align-items-center"><i data-feather="volume-2"></i><span data-i18n="Analytics">{{$t("sidebar.moderation_groups.pub_asset")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.pub_terminated'}"> <a href="/pub_terminated" class="dropdown-item d-flex align-items-center"><i data-feather="volume-1"></i><span data-i18n="Analytics">{{$t("sidebar.moderation_groups.pub_terminated")}}</span></a>
              </li>
              <li :class="{active:currentRouteName==='app.pub_rejected'}"> <a href="/pub_rejected" class="dropdown-item d-flex align-items-center"><i data-feather="volume-x"></i><span data-i18n="Analytics">{{$t("sidebar.moderation_groups.pub_rejected")}}</span></a>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item" data-menu="dropdown"><a class="dropdown-toggle nav-link d-flex align-items-center" href="#" data-bs-toggle="dropdown"><i data-feather="box"></i><span data-i18n="Misc">{{$t("sidebar.treasurers_groups.title")}}</span></a>
            <ul class="dropdown-menu" data-bs-popper="none">
              <li :class="{active:currentRouteName==='app.tresorerie_bnk'}"> <a href="/tresorerie_bnk" class="dropdown-item d-flex align-items-center"><i data-feather="dollar-sign"></i><span data-i18n="Analytics">{{$t("sidebar.treasurers_groups.paiement")}}</span></a>
              </li>
              <li data-menu="" :class="{active:currentRouteName==='app.tresorerie_mno_manage'}"><a class="dropdown-item d-flex align-items-center" href="/tresorerie_mno_manage" data-bs-toggle="" data-i18n="dollar-sign" ><i data-feather="folder"></i><span data-i18n="Documentation">{{$t("sidebar.treasurers_groups.paiement_momo")}}</span></a>
              </li>
              <li data-menu="" :class="{active:currentRouteName==='app.tresorerie_mno_bnk_canceled'}"><a class="dropdown-item d-flex align-items-center" href="/tresorerie_mno_bnk_canceled" data-bs-toggle="" data-i18n="Raise Support"><i data-feather="life-buoy"></i><span data-i18n="Raise Support">{{$t("sidebar.treasurers_groups.black_list_non_execute")}}</span></a>
              </li>
              <li data-menu="" :class="{active:currentRouteName==='app.tresorerie_create_payout'}"><a class="dropdown-item d-flex align-items-center" href="/tresorerie_create_payout" data-bs-toggle="" data-i18n="Raise Support" ><i data-feather="life-buoy"></i><span data-i18n="Raise Support">{{$t("sidebar.controller_groups.payout_created")}}</span></a>
              </li>


              <li class="disabled" data-menu=""><a class="dropdown-item d-flex align-items-center" href="" data-bs-toggle="" data-i18n="Disabled Menu"><span data-i18n="Disabled Menu">------------------------</span></a>
              </li>

              <li class="" :class="{active:currentRouteName==='app.operator_tresorerie_bnk'}" data-menu=""><a class="dropdown-item d-flex align-items-center" href="/operator_tresorerie_bnk" data-bs-toggle="" data-i18n="Disabled Menu"><i data-feather="dollar-sign"></i><span data-i18n="Disabled Menu">{{$t("sidebar.treasurers_groups.paiement")}}</span></a>
              </li>
              <li data-menu="" :class="{active:currentRouteName==='app.operator_tresorerie_mno_manage'}"><a class="dropdown-item d-flex align-items-center" href="/operator_tresorerie_mno_manage" data-bs-toggle="" data-i18n="dollar-sign" ><i data-feather="folder"></i><span data-i18n="Documentation">{{$t("sidebar.treasurers_groups.paiement_momo")}}</span></a>
              </li>
              <li data-menu="" :class="{active:currentRouteName==='app.operator_tresorerie_mno_bnk_canceled'}"><a class="dropdown-item d-flex align-items-center" href="/operator_tresorerie_mno_bnk_canceled" data-bs-toggle="" data-i18n="Raise Support"><i data-feather="life-buoy"></i><span data-i18n="Raise Support">{{$t("sidebar.treasurers_groups.black_list_non_execute")}}</span></a>
              </li>
              <li data-menu="" :class="{active:currentRouteName==='app.operator_tresorerie_create_payout'}"><a class="dropdown-item d-flex align-items-center" href="/operator_tresorerie_create_payout" data-bs-toggle="" data-i18n="Raise Support"><i data-feather="life-buoy"></i><span data-i18n="Raise Support">{{$t("sidebar.controller_groups.payout_created")}}</span></a>
              </li>
            </ul>
          </li>

          <li class="dropdown nav-item" data-menu="dropdown"><a class="dropdown-toggle nav-link d-flex align-items-center" href="#" data-bs-toggle="dropdown"><i data-feather="box"></i><span data-i18n="Misc">{{$t("sidebar.securite_groups.title")}}</span></a>
            <ul class="dropdown-menu" data-bs-popper="none">
              <li data-menu="" :class="{active:currentRouteName==='app.security_del_user'}"><a class="dropdown-item d-flex align-items-center" href="/security_del_user" data-bs-toggle="" data-i18n="Raise Support"><i data-feather="trash-2"></i><span data-i18n="Raise Support">{{$t("sidebar.user")}}</span></a>
              </li>
              <li data-menu="" :class="{active:currentRouteName==='app.security_duplicated_user'}"><a class="dropdown-item d-flex align-items-center" href="/security_duplicated_user" data-bs-toggle="" data-i18n="Raise Support" ><i data-feather="maximize-2"></i><span data-i18n="Raise Support">{{$t("sidebar.duplicate")}}</span></a>
              </li>
            </ul>
          </li>

          <li class="me_font" style=" position: absolute;top: 48%;right: 8px;transform: translateY(-50%);">
              <a class="btn btn-outline-danger waves-effect btn-lg" href="#" v-on:click="setLogoutSession()">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power me-50"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg>
              </a>
          </li>

        </ul>
      </div>
    </div>
  </div>
  <!-- END: Main Menu-->

</div>
</template>

<script >
import store from "@/store";
import {setLogoutSession} from "@/store/helpers/utils";
export default {
  data() {
    return {
      state:true,
      user: '',
      role: '',
    };
  },
  mounted() {
    this.changeIcon();
    this.flag();
    this.user = store.state.user.data;
    this.role = store.state.user.role;
  },

  computed:{
    stateLigthDart(){
      if (localStorage.getItem('type')==="light"){
        return true;
      }else{
          return false;
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
    flagController(){
      if (localStorage.getItem('langue')==="fr"){
        return true;
      }else{
        return false;
      }
    },
  },

  methods: {
    setLogoutSession,
    changeState(option) {
      if (option === "ligth") {
        localStorage.setItem('option', "loading loaded light-layout light-layout")
        localStorage.setItem("type", "light");
      } else if (option === "dark") {
        localStorage.setItem('option', "loading loaded dark-layout dark-layout")
        localStorage.setItem("type", "dark");
      }
      location.reload();
    },
    changeIcon() {
      if (localStorage.getItem('type') === 'black') {
        this.state = !this.state

      } else if (localStorage.getItem('type') === "light") {
        this.state = !this.state;

      } else {
        localStorage.setItem("type", "light");
        this.state = !this.state;

      }
    },
    changeLangue(langue) {
      console.log(langue);
      if (langue==='fr') {
        localStorage.setItem('langue', "fr");
      }else if(langue==='en'){
        localStorage.setItem('langue', "en")
      }
      location.reload();
    },
    flag(){
      if(localStorage.getItem('langue')==="fr"){
       /* $("#fr").hide();
        $("#en").show();
        $("#flag-icon-change").removeClass("flag-icon-us");
        $("#flag-icon-change").addClass("flag-icon-fr");
        $(".selected-language").text("Frensh");*/
      }else if(localStorage.getItem('langue')==="en"){
      /*  $("#en").hide();
        $("#fr").show();
        $("#flag-icon-change").removeClass("flag-icon-fr");
        $("#flag-icon-change").addClass("flag-icon-us");
        $(".selected-language").text("English");*/
      }
    }

  }
}

</script>
<style scoped>

</style>