<style>

.table-responsive {
  overflow-y: hidden !important;
}
</style>
<script>
import {
  computed
} from 'vue';

import store from '../../store';
import {exportexls_auth, formateNumber} from '../../store/helpers/helper';
import TableComponent
  from '../../components/layouts/TableComponent.vue';
import moment from "moment";

export default {
  components: {TableComponent},
  data() {
    return {
      logs: [],
      logs_search:'',
      searchBy:'IP',
      action:'',
      elt:"",
    };
  },
  mounted() {
    this.getNextLogs();
  },
  beforeMount(){
    this.getLogs();
  },
  computed:{
    logsFilter(){
      if (this.logs_search.trim().length>0){
        if (this.searchBy=="IP"){
          return (this.logs.data.filter((item)=>item.ip_address.toString().toLowerCase().includes(this.logs_search.toLowerCase())));
        }
      }
      return this.logs.data;
    },
  },

  methods: {
    formateNumber,
    dateFormat(date){
      return moment(date);
    },
    getNextLogs() {
      window.onscroll = () => {
        // let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight;
        let scrollBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
        if (scrollBottom < 1 || scrollBottom <= 0) {
          if (store.state.logs.next_page !==null) {
            this.getLogs();
          }
        }
      }
    },
    getLogs() {
      let self = this;
      let page = store.state.logs.next_page;
      store.dispatch("getLogs", [page]);
      self.logs = computed(() => store.state.logs);
    },
    exportexls_auth(type,name,fn,dl){
      this.elt = this.$refs.logs_doc;
      return exportexls_auth(type,name,fn,dl,this.elt)
    }
  },

}

</script>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ $t("global_texte.logs") }}</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/dashboard">Home</router-link></li>
                  <li class="breadcrumb-item active">{{ $t("global_texte.logs") }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <div class="dropdown">
              <button class="btn-icon btn btn-primary btn-round btn-sm disabled" type="button" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>

            </div>
          </div>
        </div>
      </div>
      <div class="content-body">

        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-lg-12 col-sm-6 col-12">
              <div class="card">
                <div class="card-header  align-items-start pb-0" style="margin-bottom: 3%">
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">

                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book-open"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>                    </div>
                  </div>
                  <h2 class="fw-bolder mt-1">{{this.formateNumber(logs.totals)}}</h2>
                  <p class="card-text">{{ $t("global_texte.logs") }}</p>
                </div>
                <div id="gained-chart"></div>
              </div>
            </div>
            <!-- Greetings Card ends -->

          </div>
        </section>
        <!-- Multilingual -->



        <!-- Justified Pills Start -->
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="particulier" aria-labelledby="home-tab-justified" aria-expanded="true">
                  <!-- Row grouping -->
                  <table-component>
                    <template v-slot:left>
                      <button type="button" class="btn btn-outline-primary  waves-effect disabled"  aria-expanded="false">
                          <span>
                              {{ $t("global_texte.logs") }}
                          </span>
                      </button>
                    </template>
                    <template v-slot:export>
                      <div class="btn-group">
                        <div class="btn-group">
                          <button v-on:click="exportexls_auth('xlsx','logs_doc')" type="button" class="btn btn-outline-primary  waves-effect"  aria-expanded="false">
                              <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file font-small-4 me-50"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                  {{ $t("global_texte.export") }}
                              </span>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-slot:show>
                      <select name="DataTables_Table_0_length" v-model="searchBy" aria-controls="DataTables_Table_0" class="form-select">
                        <option value="IP">ADRESSE IP</option>
                      </select>
                    </template>
                    <template v-slot:search>
                      <div class="dt-buttons d-inline-flex">
                        <label><input v-model="logs_search" type="search" class="form-control" placeholder="Recherchez" aria-controls="DataTables_Table_0"></label>
                      </div>
                    </template>
                    <template v-slot:table>
                      <div class="mt-2">
                        <table class="scrollable-element  table table-striped table-bordered" ref="profit_">
                          <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">{{ $t("report_user.table_crested") }}</th>
                            <th scope="col">ADRESSE IP</th>
                            <th scope="col">NAVIGATEUR</th>
                            <th scope="col">{{$t("users.table_header.surname")}}</th>
                            <th scope="col">{{$t("users.table_header.first_name")}}</th>
                            <th scope="col">EMAIL</th>
                            <th scope="col">ACTION</th>
                            <th scope="col">URL</th>
                          </tr>
                          </thead>
                          <tbody v-if="logs.totals!==0">
                          <tr v-for="part in logsFilter" v-bind:key="part.id">
                            <th scope="row">{{part.id}}</th>
                            <th> {{dateFormat(part.created_at).format('LLLL')}} </th>
                            <td>{{part.ip_address}}</td>
                            <td>{{part.browser}}</td>
                            <td>{{part.controller.first_name}}</td>
                            <td>{{part.controller.last_name}}</td>
                            <td>{{part.controller.email}}</td>

                            <td>{{part.action}}</td>
                            <td>{{part.action_dir}}</td>
                          </tr>
                          </tbody >
                          <tbody v-else>
                          <tr >
                            <td colspan="9" class="text-center">{{ $t("global_texte.nothing_foung") }}</td>
                          </tr>
                          </tbody>
                          <tbody v-if="logs.loading">
                          <tr>
                            <td colspan="13" class="text-center" >
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>

                  </table-component>
                  <!--/ Row grouping -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Justified Pills End -->
      </div>
    </div>
  </div>
</template>

